import React from "react";
import logo1 from "../../../statics/logo-1.png";
import { Puff } from "react-loader-spinner";
const Questions = ({
  currentQuestion,
  data,
  onPressOption,
  selectedOptionIndex,
  onPressSubmit,
  questionsLoading,
  submisionLoading,
  brandPicture,
  prize,
  prizeImg,
}) => {
  return (
    <div
      className="col-lg-6 py-4 guess-win-quiz"
      style={{ background: "#F6F7FF" }}
    >
      {questionsLoading ? (
        <div className="d-flex justify-content-center p-2">
          <Puff color="#000" height={30} width={60} />
        </div>
      ) : (
        data?.length > 0 && (
          <>
            <div className="row">
              <div
                className="col-lg-4 col-md-4  proxima-font col-6  d-flex  justify-content-center align-items-center align-items-lg-start"
                style={{ fontSize: "20px" }}
              >
                Question {currentQuestion + 1} of {data?.length}
              </div>
              <div className="col-lg-3 col-md-4 col-6 d-lg-block d-md-block d-flex justify-content-lg-none justify-content-md-none justify-content-center">
                <img
                  src={brandPicture}
                  className=""
                  height="80px"
                  width="120px"
                />
              </div>
              {prize && prizeImg && (
                <div className="col-lg-5 col-md-5 d-flex flex-column justify-content-center align-items-center">
                  <h6 className=" raleway-font" style={{ color: "#f4a950" }}>
                    {`Prize: ${prize}`}
                  </h6>
                  <img src={prizeImg} alt="" height={80} width={80} />
                </div>
              )}
            </div>
            <div className="row mt-4">
              <div
                className="col-12 text-center proxima-font"
                style={{ fontSize: "26px" }}
              >
                <p>Q. {data[currentQuestion]?.question}</p>
              </div>
            </div>
            <div className="row mt-2 text-center d-flex flex-row justify-content-between mx-lg-5 mx-1">
              {data[currentQuestion]?.choices?.map((v, i) => {
                return (
                  <div className="col-6 ">
                    <p
                      onClick={() => onPressOption(v?.key, i)}
                      style={{
                        border:
                          selectedOptionIndex == i
                            ? "2px solid #f4a950"
                            : "none",
                        transition: "0.1s ease",
                        cursor: "pointer",
                        fontSize: "19px",
                      }}
                    >
                      <span
                        style={{
                          color: "#BE1E2D",
                          fontFamily: "mangaFont",
                          fontSize: "25px",
                        }}
                      >
                        {v?.key}.
                      </span>
                      <span className="raleway-font">{v?.value}</span>
                    </p>
                  </div>
                );
              })}
            </div>
            {submisionLoading ? (
              <div className="d-flex justify-content-center p-2">
                <Puff color="#000" height={30} width={60} />
              </div>
            ) : (
              selectedOptionIndex !== null && (
                <div className="row my-2">
                  <div className="col-12 text-center">
                    <button
                      className="home-brand-button"
                      onClick={() => onPressSubmit()}
                    >
                      {data?.length == currentQuestion + 1 ? "SUBMIT" : "NEXT"}
                    </button>
                  </div>
                </div>
              )
            )}
          </>
        )
      )}
    </div>
  );
};
export default Questions;
