import React, { useEffect, useState } from "react";
import "../modal/css/locateStore.css";
import { Modal } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-rating-stars-component";

export default function RateAndReview({
  show,
  onHide,
  setProductRating,
  setProductReview,
  setRatedProductUid,
  rateAndReviewProduct,
  // brandName,
  // brandEmail,
  // brandWebsite,
  // brandPhone,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            Rate And Review
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => {
              onHide();
              setProductReview(null);
              setProductRating(null);
              setRatedProductUid(null);
            }}
          >
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row mt-2 mx-1">
            <div className="col-lg-12 text-center">
              <label className="modal-label-buy">
                Give this product a rating
              </label>

              <div className="d-flex justify-content-center flex-column align-items-center">
                <ReactStars
                  count={5}
                  onChange={(count) => setProductRating(count)}
                  size={24}
                  activeColor="#ffd700"
                />
              </div>
            </div>
          </div>
          <div className="row mt-2 mx-1">
            <div className="col-lg-12 text-center">
              <label className="modal-label-buy">Write a Review</label>
              <br />
              <textarea
                // value={review}
                onChange={(e) => setProductReview(e.target.value)}
                placeholder="How much you like this product"
                className="w-100 p-3"
              />
            </div>
          </div>
          <div className="my-3">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {/* {loading ? ( */}
                {/* <div className="text-center">
                    <Puff color="#000" height={30} width={60} />
                  </div> */}
                {/* ) : ( */}
                <button
                  className="home-brand-button px-3 py-2"
                  onClick={rateAndReviewProduct}
                >
                  Submit
                </button>
                {/* )} */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
