import { get, post, put, patch } from "./HttpProvider";
import featureConstants from "./features-constants";

const SERVICE_URLS = {
  // service URL's (API End-Points)
  readTodos: "todos",
  registerBrandFreak: "customers/sign-up",
  imageUpload: "users/upload/image",
  registerBrand: "brands/sign-up",
  sponsorReq: "admin/create-sponsor",
  staticData: "static-data",
  readProductTypes: "admin/read-product-types",
  readAllOffers: "admin/read-offers",
  readBrands: "customers/brands",
  readFeaturedBrand: "admin/campaign/read-brands",
  readBrandOffers: "admin/campaign/read-offers",
  readTrendingProducts: "customers/read-trending-products",
  offersUnderBudgut: "customers/offers-under-budget",
  readPartners: "admin/read-sponsors",
  buyAndWin: "customers/buy-and-win",
  readRateBrands: "users/read-games/rate-and-win",
  readBuyBrands: "users/read-games/buy-and-win",
  readGuessBrands: "users/read-games/guess-and-win",
  getBrandQuestions: "users/read-questions",
  guessAndWin: "customers/guess-and-win",
  favouriteBrands: "customers/favorite-brands",
  opinionMatters: "customers/opinion-matters",
  getOfferUnderBudget: "customers/offers-under-budget",
  //Contact Module
  sendMessage: "customers/send-message",
  rateAndWin: "customers/rate-and-win",
  //Activity
  getAnalytics: "users/analytics",
  login: "users/login",
  updateClicks: "customers/update-clicks",
  readBrandCities: "users/cities",
  readOutlets: "users/outlets",
  googleLogin: "customers/social/login/google",
  facebookLogin: "customers/social/login/facebook",
  updateProfile: "customers/update",
  changePassword: "users/change-password",
  yourActivity: "customers/your-activity-details",
  readNotifications: "users/notification/read",
  viewedNotification: "users/notification/change/seen",
  rateProduct: "customers/rate",
  stayInTouch: "users/stay-in-touch",
  otpEmail: "users/forget-password",
  resetPassword: "users/reset-password",
  verifyBrandOtp: "users/verify-otp/email",
  verifyOtpPhone: "users/verify-otp/phone",
  resendBrandOtp: "users/send-otp/email",
  resendPhoneOtp: "users/send-otp/phone",
  removeDp: "customers/remove/display-picture",
  verifyPhoneNumber: "customers/change-phone-number",
  autoExpire: "admin/auto-expire",
};
const rateAndWin = (data) =>
  post(SERVICE_URLS.rateAndWin, data, { feature: featureConstants.static });
const otpEmail = (data) =>
  post(SERVICE_URLS.otpEmail, data, { feature: featureConstants.static });
const verifyBrandOtp = (data) =>
  post(SERVICE_URLS.verifyBrandOtp, data, { feature: featureConstants.static });
const verifyOtpPhone = (data) =>
  post(SERVICE_URLS.verifyOtpPhone, data, { feature: featureConstants.static });
const verifyPhoneNumber = (data) =>
  put(SERVICE_URLS.verifyPhoneNumber, data, {
    feature: featureConstants.static,
  });
const resendBrandOtp = (data) =>
  post(SERVICE_URLS.resendBrandOtp, data, { feature: featureConstants.static });
const resendPhoneOtp = (data) =>
  post(SERVICE_URLS.resendPhoneOtp, data, { feature: featureConstants.static });
const resetPassword = (data) =>
  post(SERVICE_URLS.resetPassword, data, { feature: featureConstants.static });
const login = (data) =>
  post(SERVICE_URLS.login, data, { feature: featureConstants.static });
const buyAndWin = (data) =>
  post(SERVICE_URLS.buyAndWin, data, { feature: featureConstants.static });
const stayInTouch = (data) =>
  post(SERVICE_URLS.stayInTouch, data, { feature: featureConstants.static });
const readPartners = (data) =>
  get(SERVICE_URLS.readPartners, {}, { feature: featureConstants.static });
const autoExpire = (data) =>
  get(SERVICE_URLS.autoExpire, {}, { feature: featureConstants.static });
const readOfferUnderBudgut = (data) =>
  get(
    SERVICE_URLS.offersUnderBudgut + `?min=${data?.min}&max=${data?.max}`,
    {},
    { feature: featureConstants.static }
  );
const removeDp = (data) =>
  patch(SERVICE_URLS.removeDp, data, {
    feature: featureConstants.static,
  });

const readTrendingProducts = () =>
  get(
    SERVICE_URLS.readTrendingProducts,
    {},
    { feature: featureConstants.static }
  );

const readBrands = (params) =>
  get(SERVICE_URLS.readBrands, params, { feature: featureConstants.static });

const readFeaturedBrands = () =>
  get(SERVICE_URLS.readFeaturedBrand, {}, { feature: featureConstants.static });
const readBrandOffers = () =>
  get(SERVICE_URLS.readBrandOffers, {}, { feature: featureConstants.static });

const readStaticData = () =>
  get(SERVICE_URLS.staticData, {}, { feature: featureConstants.static });

const readProductTypes = () =>
  get(SERVICE_URLS.readProductTypes, {}, { feature: featureConstants.static });
const readAllOffers = () =>
  get(SERVICE_URLS.readAllOffers, {}, { feature: featureConstants.static });
const RegisterBrandFreak = (data) =>
  post(SERVICE_URLS.registerBrandFreak, data, {
    feature: featureConstants.login,
  });
const RegisterBrand = (data) =>
  post(SERVICE_URLS.registerBrand, data, {
    feature: featureConstants.login,
  });
const uploadImage = (data) =>
  post(SERVICE_URLS.imageUpload, data, {
    feature: featureConstants.login,
  });
const sponsorReqistration = (data) =>
  post(SERVICE_URLS.sponsorReq, data, {
    feature: featureConstants.login,
  });
const getRateBrands = () =>
  get(SERVICE_URLS.readRateBrands, {}, { feature: featureConstants.static });
const getBuyBrands = () =>
  get(SERVICE_URLS.readBuyBrands, {}, { feature: featureConstants.static });
const getGuessBrands = () =>
  get(SERVICE_URLS.readGuessBrands, {}, { feature: featureConstants.static });
const getBrandQuestions = (data) =>
  post(SERVICE_URLS.getBrandQuestions, data, {
    feature: featureConstants.static,
  });
const favouriteBrands = (data) =>
  put(SERVICE_URLS.favouriteBrands, data, { feature: featureConstants.static });
const rateProduct = (data) =>
  put(SERVICE_URLS.rateProduct, data, { feature: featureConstants.static });
const viewedNotification = (data) =>
  put(SERVICE_URLS.viewedNotification, data, {
    feature: featureConstants.static,
  });
const guessAndWin = (data) =>
  post(SERVICE_URLS.guessAndWin, data, { feature: featureConstants.static });

const getOfferUnderBudget = (params) =>
  get(SERVICE_URLS.getOfferUnderBudget, params, {
    feature: featureConstants.static,
  });
//contact module
const sendMessage = (data) =>
  post(SERVICE_URLS.sendMessage, data, { feature: featureConstants.static });
const getAnalytics = () =>
  get(SERVICE_URLS.getAnalytics, { feature: featureConstants.static });

const updateClicks = (data) =>
  put(SERVICE_URLS.updateClicks, data, { feature: featureConstants.static });
const updateProfile = (data) =>
  put(SERVICE_URLS.updateProfile, data, { feature: featureConstants.static });
const opinionMatters = (data) =>
  get(SERVICE_URLS.opinionMatters, data, { feature: featureConstants.static });
const readBrandCities = (data) =>
  get(SERVICE_URLS.readBrandCities, data, { feature: featureConstants.static });
const readNotifications = (data) =>
  get(SERVICE_URLS.readNotifications, data, {
    feature: featureConstants.static,
  });
const yourActivity = (data) =>
  get(SERVICE_URLS.yourActivity, data, { feature: featureConstants.static });
const readOutlets = (params) =>
  get(SERVICE_URLS.readOutlets, params, {
    feature: featureConstants.static,
  });
const googleLogin = (data) =>
  post(SERVICE_URLS.googleLogin, data, { feature: featureConstants.static });
const facebookLogin = (data) =>
  post(SERVICE_URLS.facebookLogin, data, { feature: featureConstants.static });
const changePassword = (data) =>
  post(SERVICE_URLS.changePassword, data, { feature: featureConstants.static });

const apiServices = {
  login,
  RegisterBrandFreak,
  uploadImage,
  RegisterBrand,
  sponsorReqistration,
  readStaticData,
  readProductTypes,
  readAllOffers,
  readBrands,
  readFeaturedBrands,
  readBrandOffers,
  readTrendingProducts,
  readOfferUnderBudgut,
  readPartners,
  buyAndWin,
  stayInTouch,
  getAnalytics,
  getRateBrands,
  getBuyBrands,
  getGuessBrands,
  getBrandQuestions,
  guessAndWin,
  favouriteBrands,
  opinionMatters,
  getOfferUnderBudget,

  //contact
  sendMessage,
  rateAndWin,
  otpEmail,
  resetPassword,
  updateClicks,
  readBrandCities,
  readOutlets,
  googleLogin,
  facebookLogin,
  updateProfile,
  changePassword,
  yourActivity,
  readNotifications,
  viewedNotification,
  rateProduct,
  verifyBrandOtp,
  resendBrandOtp,
  removeDp,
  verifyPhoneNumber,
  resendPhoneOtp,
  verifyOtpPhone,
  autoExpire,
};
export default apiServices;
