import React from "react";
import men from "../../../statics/2-men-section.jpg";
import women from "../../../statics/women.png";
const MenWomen = ({ onPressMen, onPressWomen, isMen, isWomen }) => {
  return (
    <div className="row no-gutters ">
      <div
        onClick={onPressMen}
        className="col-lg-6 col-md-6 col-sm-12 col-6 alpha"
      >
        <img src={men} alt="Snow" width="100%" height="100%" />
        <div className="centered raleway-font font-weight-bold  ">MEN</div>
      </div>
      <div
        onClick={onPressWomen}
        className="col-lg-6 col-md-6 col-sm-12 col-6 alpha"
      >
        <img className="" src={women} alt="Snow" width="100%" height="100%" />
        <div className="centered raleway-font font-weight-bold">WOMEN</div>
      </div>
    </div>
  );
};
export default MenWomen;
