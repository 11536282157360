import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import About from "../pages/About";
import BrandOffers from "../pages/BrandOffers";
import Contact from "../pages/Contact";
import Home from "../pages/Home";
import Login from "../pages/auth/Login";
import ComingSoon from "../pages/ComingSoon";
import UserType from "../pages/UserType";
import BrandFreakRegistration from "../pages/BrandFreakRegistration";
import BrandRegistration from "../pages/BrandRegistration";
import SponsorRegistration from "../pages/SponsorRegistration";

import GuessAndWin from "../pages/Guess & win";
import BrandOfferDetail from "../pages/BrandOfferDetail";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import YourActivity from "../components/layouts/modal/YourActivity";
import YourActivityPage from "../pages/YourActivityPage";
const MainRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/" element={<Home />} />
        <Route path="/user-types" element={<UserType />} />
        <Route path="/brand-register" element={<BrandFreakRegistration />} />
        <Route path="/brand-freak-register" element={<BrandRegistration />} />
        <Route path="/sponor-register" element={<SponsorRegistration />} />

        <Route path="/contact-us" element={<Contact />} />

        <Route path="/about" element={<About />} />

        <Route path="/brand-offer" element={<BrandOffers />} />
        <Route path="/brand-details" element={<BrandOfferDetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/guess" element={<GuessAndWin />} />
        <Route path="/your-activity" element={<YourActivityPage />} />

        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default MainRoutes;
