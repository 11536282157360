import React from "react";
import "./fonts/athelas/Athelas-Regular.ttf";
import "./fonts/athelas/Athelas-Italic.ttf";
import "./fonts/avantgarde-bk-bt/AvantGardeBookBT.ttf";
import "./fonts/avantgarde-bk-bt/AvantGardeDemiBT.ttf";
import "./fonts/avantgarde-md-bt/AVGARDM.TTF";
import "./fonts/playfair-display/PlayfairDisplay-Bold.ttf";
import "./fonts/proxima-nova/FontsFree-Net-FontsFree-Net-Proxima-Nova-Bold.ttf";
import "./fonts/proxima-nova/FontsFree-Net-ProximaNova-Regular.ttf";
import "./fonts/proxima-nova/Proxima Nova Semibold.ttf";
import "./fonts/proxima-nova/proxima-nova-black.ttf";
import "./fonts/quicksand/Quicksand-Bold.ttf";
import "./fonts/quicksand/Quicksand-Light.ttf";
import "./fonts/quicksand/Quicksand-Medium.ttf";
import "./fonts/quicksand/Quicksand-Regular.ttf";
import "./fonts/quicksand/Quicksand-SemiBold.ttf";
import "./fonts/raleway/Raleway-Bold.ttf";
import "./fonts/tw-cen-mt/TCB_____.TTF";
import "./fonts/tw-cen-mt/TCBI____.TTF";
import "./fonts/tw-cen-mt/TCCM____.TTF";
import "./fonts/tw-cen-mt/TCMI____.TTF";

import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./api/store";
import { HashRouter } from "react-router-dom";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";

import common_de from "./translations/ban/common.json";
import common_en from "./translations/en/common.json";
import common_sa from "./translations/sa/common.json";

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  // lng: lang ? lang : "en",                              // language to use
  lng: "en", // language to use
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ban: {
      common: common_de,
    },
    sa: {
      common: common_sa,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <HashRouter>
      <Provider store={store}>
        <I18nextProvider i18n={i18next}>
          <App />
        </I18nextProvider>
      </Provider>
    </HashRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
