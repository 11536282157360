import React from "react";
import Slider from "react-slick";
import trending from "../../../statics/trending.png";
import logo from "../../../statics/logo-3.png";
import { capitalize } from "../../../Utils/functions";

const TrendingSlider = ({ list, goToDetail }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "25px",
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          centerPadding: "0px",
          infinite: false,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          slidesPerRow: 2,
        },
      },
    ],
  };
  return (
    <Slider {...settings}>
      {list?.slice(0, 8)?.map((item) => (
        <div className="col-lg-4 col-md-6 col-sm-4">
          <div
            className="trending-products-hover-div bg-light my-4"
            onClick={() => goToDetail(item)}
          >
            <img
              className="prod-image m-0 "
              src={item?.image[0]}
              // height="70%"
              // width="90%"
              // style={{ height: "auto" }}
            />
            <div
              className="text-center mx-3 mt-3 quicksand-font truncate-text-1"
              style={{ fontSize: "12px" }}
            >
              {item?.name}
            </div>
            <span
              className="font-weight-bold quicksand-font"
              style={{ fontSize: "12px" }}
            >
              {item.brand_name}
            </span>
            <span
              className="font-weight-bold quicksand-font"
              style={{ fontSize: "12px" }}
            >{`Price: ${
              item?.discounted_price ? item?.discounted_price : item?.price
            } AED`}</span>
          </div>
          <div className="hover-prod-image"></div>
        </div>
      ))}
    </Slider>
  );
};
export default TrendingSlider;
