import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { buyAndWin, getBuyBrands } from "../../../api/slices/webSlice";
import xmark from "../../../statics/xmark-solid.svg";
import { Loader } from "../../common";
import ImagePickerModal from "./ImagePickerModal";
import PreviewImageModal from "./PreviewImageModal";

export default function BuyAndWin({ show, onHide, uid = null }) {
  const [imageModal, setImageModal] = useState(false);
  const [displayimageModal, setDisplayImageModal] = useState(false);

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [recieptNumber, setRecieptNumber] = useState(null);
  const [error, setError] = useState(null);
  const [brand, setBrand] = useState(null);

  const dispatch = useDispatch();
  const { loading, buy_brands_loading, buy_brands, brands } = useSelector(
    (state) => state.WebSlice
  );
  let tagName = null;
  useEffect(() => {
    if (show && !uid) {
      dispatch(getBuyBrands());
    } else if (show && uid) {
      setBrand(uid);
    }
  }, [show]);
  const onSubmit = async () => {
    if (!brand) {
      setError({
        brand: {
          message: "please select brand",
        },
      });
      return;
    }
    if (!recieptNumber) {
      setError({
        recieptNumber: {
          message: "please enter reciept number",
        },
      });
      return;
    }
    if (brand && recieptNumber) {
      let data = {
        apiData: {
          brand: uid ? uid : brand?.value,
          reciept_number: recieptNumber,
          reciept_picture: file,
        },
        closeModal: () => {
          onHide();
          setRecieptNumber(null);
          setBrand(null);
          setFile(null);
          setFileName(null);
        },
      };
      dispatch(buyAndWin(data));
    }
  };

  const getPrizeObject = () => {
    if (uid) {
      const selectedBrand = brands?.filter((item) => item?.uid == uid);
      return {
        label: selectedBrand[0]?.name,
        value: selectedBrand[0]?.uid,
        gameName: selectedBrand[0]?.buy_prize?.name,
        gameImg: selectedBrand[0]?.buy_prize?.display_picture,
      };
    }
    return;
  };
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Enroll yourself for Lucky Draw
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => {
              setRecieptNumber(null);
              setBrand(null);
              setFile(null);
              setFileName(null);
              onHide();
            }}
          >
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>

        {buy_brands_loading ? (
          <Loader />
        ) : (
          <Modal.Body>
            {!uid && (
              <div className="row mx-1">
                <div className="col-lg-12">
                  <label className="modal-label-buy">Choose Brand</label>
                  <Select
                    required={true}
                    onChange={(val) => setBrand(val)}
                    options={buy_brands?.map((item, idx) => {
                      return {
                        label: item?.name,
                        value: item?.uid,
                        gameName: item?.buy_prize?.name,
                        gameImg: item?.buy_prize?.display_picture,
                        key: idx,
                      };
                    })}
                  />
                  {brand && brand?.gameName && brand?.gameImg && (
                    <div className="d-flex flex-row">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <h6
                          className="mt-3 raleway-font"
                          style={{ color: "#f4a950" }}
                        >
                          {`Prize: ${brand?.gameName}`}
                        </h6>
                        <img
                          src={brand?.gameImg}
                          alt=""
                          height={100}
                          width={100}
                        />
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  )}
                  {error?.brand ? (
                    <p className="text-danger">{error?.brand?.message}</p>
                  ) : null}
                </div>
              </div>
            )}
            <div className="row mt-2 mx-1">
              <div className="col-lg-12">
                <label className="modal-label-buy">
                  Your Payment Receipt Number
                </label>
                <input
                  className="w-100 buy-win-input"
                  type="text"
                  required
                  onChange={(e) => setRecieptNumber(e.target.value)}
                />
                {error?.recieptNumber ? (
                  <p className="text-danger">{error?.recieptNumber?.message}</p>
                ) : null}
              </div>
            </div>
            <div className="row mt-2 mx-1">
              <div className="col-lg-12">
                {Object.keys(getPrizeObject()?.length > 0) &&
                  uid &&
                  getPrizeObject()?.gameName &&
                  getPrizeObject()?.gameImg && (
                    <div className="d-flex flex-row">
                      <div className="col-md-3"></div>
                      <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <h6
                          className="mt-3 raleway-font"
                          style={{ color: "#f4a950" }}
                        >{`Prize: ${getPrizeObject()?.gameName}`}</h6>
                        <img
                          src={getPrizeObject()?.gameImg}
                          alt=""
                          height={100}
                          width={100}
                        />
                      </div>
                      <div className="col-md-3"></div>
                    </div>
                  )}
                <label className="modal-label-buy">
                  Your Payment Receipt Picture
                </label>
                <br />
                <button
                  className="upload-logo-button"
                  type="button"
                  style={{ background: "#fff" }}
                  onClick={() => {
                    if (!tagName && !file) {
                      setImageModal(true);
                    }
                    if (file && !tagName) {
                      setDisplayImageModal(true);
                    }
                  }}
                >
                  <span>
                    <i className="fas fa-upload mr-2" />{" "}
                    {fileName && file ? fileName?.name : "Upload"}
                    {file ? (
                      <img
                        className="ml-2"
                        src={xmark}
                        onClick={(e) => {
                          setFile(null);
                          setFileName(null);
                        }}
                        height="20px"
                        width="20px"
                      />
                    ) : null}
                  </span>
                </button>
              </div>
            </div>
            <div className="my-3">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  {loading ? (
                    <Loader />
                  ) : (
                    <button
                      className="home-brand-button px-3 py-2"
                      onClick={onSubmit}
                    >
                      PARTICIPATE
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
      <ImagePickerModal
        show={imageModal}
        onHide={() => setImageModal(false)}
        file={file}
        setFile={setFile}
        setFileName={setFileName}
      />
      <PreviewImageModal
        show={displayimageModal}
        onHide={() => setDisplayImageModal(!displayimageModal)}
        image={file}
      />
    </>
  );
}
