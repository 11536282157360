import React from "react";
import { forwardRef } from "react";
import ReactStars from "react-rating-stars-component";
import loc from "../../../statics/location-pin-solid.svg";
import moment from "moment";
import amazon from "../../../statics/Amazon logo.png";
import { Loader } from "../../../components/common";

const ProductDetail = forwardRef(
  (
    {
      selectedProduct,
      brandName,
      onPresslogo,
      selectedImage,
      changeSelectedImage,
      selectedProductSize,
      changeProductSize,
      showStoreModal,
      clicksLoading,
      onRateProduct,
      openRateReviewModal,
      setRatedProductUid,
      setProductRating,
    },
    ref
  ) => {
    return (
      <div ref={ref} className="container ">
        <div className="row px-lg-5 pt-4">
          <div className="col-lg-6 d-flex align-items-center justify-content-center">
            <div className="row">
              <div className="col-lg-3 col-12">
                {selectedProduct?.image?.map((item, idx) => (
                  <img
                    className={
                      selectedImage?.idx == idx
                        ? "gallery-prod-selected p-lg-4 my-1 mx-lg-0 mx-1"
                        : "gallery-prod p-lg-4 my-1 mx-lg-0 mx-1"
                    }
                    src={item}
                    // height="100px"
                    // width="120px"
                    onClick={() =>
                      changeSelectedImage({ image: item, idx: idx })
                    }
                  />
                ))}
              </div>
              <div className="col-lg-9 d-flex align-items-center justify-content-center">
                <img
                  className="selected-product-detail-image"
                  src={selectedImage?.image}
                  // height="400px"
                  // width="100%"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row">
              <div className="col-lg-6 col-6">
                <h1 className="my-0 py-0" style={{ fontWeight: "700" }}>
                  {brandName}
                </h1>
                <p style={{ fontSize: "20px", color: "grey" }}>
                  {selectedProduct?.name}
                </p>
              </div>
              <div className="col-lg-6 col-6 d-flex justify-content-end">
                <img
                  src={selectedProduct?.offer?.display_picture}
                  height="50px"
                  width="60px"
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 ">
                {selectedProduct?.discounted_price ? (
                  <span style={{ fontSize: "25px" }}>
                    AED {selectedProduct?.discounted_price}
                  </span>
                ) : (
                  <span style={{ fontSize: "25px" }}>
                    AED {selectedProduct?.price}
                  </span>
                )}
              </div>
              <div className="col-lg-6 " style={{ fontSize: "14px" }}>
                <div
                  className="brand-discounted-price"
                  style={{ position: "relative" }}
                >
                  {selectedProduct?.discounted_price ? (
                    <span>AED {selectedProduct?.price}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-6 truncate-text-6 ">
                {selectedProduct?.description}
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12">
                Color:{" "}
                {selectedProduct?.color[0] &&
                  selectedProduct?.color.map((color) => <span> {color} </span>)}
              </div>
            </div>
            <div className="row my-4">
              <div className="col-lg-12">
                Size{" "}
                <span>
                  {selectedProduct?.size.map((size) => {
                    return (
                      <span
                        className="size-prod p-3 mx-2"
                        style={{
                          cursor: "pointer",
                          border:
                            size == selectedProductSize
                              ? "2px solid grey"
                              : "1px solid grey",
                          fontWeight:
                            size == selectedProductSize ? "bolder" : "normal",
                        }}
                        onClick={() => changeProductSize(size)}
                      >
                        {size}
                      </span>
                    );
                  })}
                </span>
              </div>
            </div>

            <div className="row no-gutters d-flex align-items-center">
              <div className="col-lg-1 col-2 mx-0 mt-1">
                <span className="">Rating</span>
              </div>
              <div
                className="col-lg-3 col-8 mx-0 my-0 ml-2"
                key={selectedProduct?.average_rating}
              >
                <ReactStars
                  count={5}
                  value={selectedProduct?.average_rating}
                  size={24}
                  activeColor="#ffd700"
                  color="gray"
                  edit={false}
                  // onChange={(count) => {
                  //   onRateProduct(count, selectedProduct?.uid);
                  // }}
                  // onChange={(count) => setProductRating(count)}
                />
              </div>
              <div className="col-lg-7 d-flex justify-content-end">
                <span
                  className="rate-review-product"
                  onClick={() => {
                    openRateReviewModal();
                    setRatedProductUid(selectedProduct?.uid);
                  }}
                >
                  Rate And Review
                </span>
              </div>
            </div>
            <div className="row mt-2 mx-0 d-flex align-items-center">
              <div className="col-lg-4 col-3">
                <div className="row">
                  <div className="col-lg-2 col-2 mx-0 px-0 mt-3">
                    <img src={loc} height="20px" width="40px" />
                  </div>
                  <div
                    className="col-6 mt-2"
                    style={{ cursor: "pointer" }}
                    onClick={showStoreModal}
                  >
                    <span style={{ fontSize: "14px" }}>
                      Locate
                      <div className="my-0 py-0">Store</div>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-4 d-flex justify-content-center mt-2 ">
                {clicksLoading ? (
                  <Loader />
                ) : (
                  <img
                    onClick={onPresslogo}
                    src={amazon}
                    height="50px"
                    width="150px"
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="col-lg-4 col-5 mt-2">
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <span style={{ fontSize: "14px" }}>Remaining Time</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 d-flex justify-content-end">
                    <span style={{ color: "#F4A950", fontSize: "14px" }}>
                      {moment
                        .unix(selectedProduct?.offer?.ending_time)
                        .fromNow()}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
export default ProductDetail;
