import React from "react";
import amazon from "../../../statics/Amazon logo.png";
import loc from "../../../statics/location-pin-solid.svg";
import moment from "moment";
import ReactStars from "react-rating-stars-component";
import { Loader } from "../../../components/common";
const SingleProductCard = ({
  products,
  brandName,
  onPressProduct,
  selectedProductName,
  onRateProduct,
  onPresslogo,
  clicksLoading,
  showStoreModal,
  onPressLocateStore,
}) => {
  return (
    <div className="container">
      {products?.length ? (
        <div className="row py-5">
          {products?.map((item) => {
            if (Object.keys(item?.offer).length > 0)
              return (
                <div
                  className="col-lg-4 mb-3"
                  //  data-aos="flip-left"
                >
                  <div
                    className={
                      selectedProductName == item?.uid
                        ? "p-3 card-prod-when-clicked"
                        : "p-3 card-prod"
                    }
                    style={{ backgroundColor: "#fff" }}
                  >
                    <div className="row">
                      <div className="col-8 ">
                        <h1 className="my-0 py-0" style={{ fontWeight: "700" }}>
                          {brandName}
                        </h1>
                      </div>
                      <div className="col-4 d-flex justify-content-end">
                        <img
                          src={item?.offer?.display_picture}
                          height="50px"
                          width="80px"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12  py-1">
                        <p
                          style={{
                            fontSize: "20px",
                            color: "grey",
                            height: "40px",
                          }}
                          className=""
                        >
                          {item?.name}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className="col-12 text-center d-flex align-items-center justify-content-center"
                        // style={{ height: "200px" }}
                        onClick={() => {
                          onPressProduct(item);
                        }}
                      >
                        <img
                          src={item?.image[0]}
                          className="single-product-card-image"
                          // height="200px"
                          // height="auto"
                          // width="250px"
                          // style={{
                          //   objectFit: "cover",
                          // }}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div
                        className="col-8 truncate-text-1"
                        // style={{ minHeight: "50px" }}
                      >
                        Color:{" "}
                        {item?.color &&
                          item?.color.map((color) => (
                            <span className=""> {color}</span>
                          ))}
                      </div>

                      <div className="col-4 d-flex justify-content-end price-mobile-view ">
                        {item?.discounted_price !== 0 ? (
                          <span>AED {Math.round(item?.discounted_price)}</span>
                        ) : (
                          <span>AED {Math.round(item?.price)}</span>
                        )}
                      </div>
                    </div>
                    <div className="row" style={{ height: "40px" }}>
                      <div className="col-2 d-flex align-items-center">
                        <span>Rate</span>
                      </div>
                      <div
                        className="col-7 d-flex justify-content-center"
                        key={item?.uid}
                      >
                        <ReactStars
                          color="gray"
                          count={5}
                          size={24}
                          value={item?.average_rating}
                          activeColor="#ffd700"
                          onChange={(count) => {
                            onRateProduct(count, item?.uid);
                          }}
                        />
                      </div>
                      <div
                        className="col-3 d-flex justify-content-end align-items-center"
                        style={{ fontSize: "14px" }}
                      >
                        <div
                          className={
                            item?.discounted_price
                              ? "brand-discounted-price"
                              : "null"
                          }
                          style={{ position: "relative" }}
                        >
                          {item?.discounted_price ? (
                            <span>AED:{item?.price}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div
                      className="row mt-2 d-flex align-items-center"
                      style={{ borderTop: "2px solid lightgrey" }}
                    >
                      <div className="col-3 remaining-time-font">
                        <div className="row">
                          <div className="col-2 mx-0 px-0 mt-3">
                            <img src={loc} height="20px" width="40px" />
                          </div>
                          <div
                            className="col-10 mt-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              onPressLocateStore(item);
                            }}
                          >
                            <span>
                              Locate
                              <div className="my-0 py-0">Store</div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-4 d-flex justify-content-center mt-2">
                        {clicksLoading ? (
                          <Loader />
                        ) : (
                          <img
                            src={amazon}
                            height="50px"
                            width="150px"
                            onClick={() => onPresslogo(item?.uid)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </div>
                      <div className="col-5 mt-2 remaining-time-font">
                        <div className="row">
                          <div className="col-12 d-flex justify-content-end">
                            <span>Remaining Time</span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 d-flex justify-content-end">
                            <span
                              style={{
                                color: "#F4A950",
                              }}
                            >
                              {moment.unix(item?.offer?.ending_time).fromNow()}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
          })}
        </div>
      ) : (
        <div className="row">
          <div className="col d-flex justify-content-center align-items-center my-5">
            <h1>No Items Found!</h1>
          </div>
        </div>
      )}
    </div>
  );
};
export default SingleProductCard;
