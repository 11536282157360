import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeNavbarColor } from "../../api/slices/globalSlice";
import banner from "../../statics/cover-photo.png";
import logo1 from "../../statics/logo-1.png";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import StoreInformationModal from "../../components/layouts/modal/StoreInformationModal";
import { toast } from "react-toastify";
import { capitalize } from "../../Utils/functions";
import BuyAndWin from "../../components/layouts/modal/BuyAndWin";
import RateAndWin from "../../components/layouts/modal/RateAndWin";
import LocateStore from "../../components/layouts/modal/LocateStore";
import {
  favouriteBrand,
  rateProduct,
  updateProductsClick,
} from "../../api/slices/webSlice";
import { Entertainment, ProductDetail, SingleProductCard } from "./components";
import {
  gameData,
  genderArray,
  productArray,
  sizeArray,
  sortElements,
} from "../../config";
import { Loader } from "../../components/common";
import ContactInfo from "../../components/layouts/modal/ContactInfo";
import GoogleMapReact from "google-map-react";
import LocationPin from "../../components/layouts/modal/helper/LocationPin";
import FilterBrandSlider from "./components/FilterBrandSlider";
import RateAndReview from "../../components/layouts/modal/RateAndReview";
export default function BrandOfferDetailPage() {
  const { state } = useLocation();
  const setProd = () => {
    if (state?.product) {
      return state?.product?.map((v) => {
        return {
          ...v,
          gender: state?.gender?.name,
        };
      });
    }
    return [];
  };
  const product = useRef(null);
  const [tab, setTab] = useState("offers");
  const [data, setData] = useState(null);
  const [displayStoreInfoModal, setDisplayStoreInfoModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(
    state?.selectedProduct ? state?.selectedProduct : null
  );

  const [products, setProducts] = useState(setProd);
  const [selectedImage, setSelectedImage] = useState(null);
  const [show, setShow] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const [locateStore, setLocateStore] = useState(false);
  const [productSize, setProductSize] = useState("S");
  const [showFilter, setShowFilter] = useState(false);
  const [showSort, setShowSort] = useState(false);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showNestedDiv, setShowNestedDiv] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [isValeuSelected, setIsValueSelcetd] = useState({
    Gender: false,
    Size: false,
    ProductType: false,
    offerType: false,
  });
  const [filterObj, setFilterObj] = useState({});
  const [isFilterDone, setIsfilterDone] = useState(false);
  const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
  const [productRating, setProductRating] = useState(null);
  const [ratedProductUid, setRatedProductUid] = useState(null);
  const [productReview, setProductReview] = useState(null);
  const [locateStoreSingleItem, setLocateStoreSingleItem] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [showRateReviewModal, setShowRateReviewModal] = useState(false);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    if (state?.selectedProduct) {
      TakeMeToProduct();
    }
    dispatch(changeNavbarColor("#fff"));
  }, []);
  useEffect(() => {
    setSelectedImage({ image: selectedProduct?.image[0], idx: 0 });
  }, [selectedProduct]);
  const getGames = () => {
    let check_if_games = [
      {
        name: "Guess",
        value: state?.is_guess,
      },
      {
        name: "Enroll",
        value: state?.is_buy,
      },
      {
        name: "Rate",
        value: state?.is_rate,
      },
    ].filter((v) => v?.value);
    let games = [];
    check_if_games?.map((v, i) => {
      gameData?.map((val, ind) => {
        if (v?.name == val?.button) {
          games.push(val);
        }
      });
    });
    return games;
  };

  const TakeMeToProduct = () => {
    product?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openLink = (data) => {
    window.open(data, "_blank")?.focus();
  };
  const { user } = useSelector((state) => state.AuthSlice);
  const { favourite_loading, clicks_loading, all_offers, product_types } =
    useSelector((state) => state.WebSlice);

  const checkFavourite = () => {
    if (user?.favorite_brands?.length > 0) {
      return user?.favorite_brands?.includes(state?.uid);
    }
    return false;
  };
  const getProducts = () => {
    if (products?.length > 0) {
      if (selectedProduct) {
        let index = products?.findIndex(
          (value) => value?.uid == selectedProduct?.uid
        );
        let temp = products[0];
        products[0] = selectedProduct;
        products[index] = temp;
        return products;
      }
      return products;
    }
    return [];
  };
  const sortProduct = (value) => {
    if (value == "ALPHABETICALLY, A-Z") {
      products?.sort((current, next) => current.name.localeCompare(next.name));
    } else if (value == "ALPHABETICALLY, Z-A") {
      products?.sort((current, next) => next.name.localeCompare(current.name));
    } else if (value == "PRICE, HIGHEST TO LOWEST") {
      products?.sort(
        (current, next) => parseFloat(next.price) - parseFloat(current.price)
      );
    } else if (value == "PRICE, LOWEST TO HIGHEST") {
      products?.sort(
        (current, next) => parseFloat(current.price) - parseFloat(next.price)
      );
    } else {
      products?.sort(
        (current, next) =>
          new Date(current.created_at) - new Date(next.created_at)
      );
    }
  };
  const returnCurrentFilter = () => {
    if (selectedFilter === "Gender") {
      return {
        name: "Gender",
        value: genderArray,
      };
    } else if (selectedFilter == "Size") {
      return {
        name: "Size",
        value: sizeArray,
      };
    } else if (selectedFilter == "ProductType") {
      return {
        name: "Product Type",
        // value: product_types,
        value: state?.offered_product_types,
      };
    }
  };
  const filterProducts = (value, type) => {
    setShowNestedDiv(false);
    if (type === "Gender") {
      setIsValueSelcetd({ ...isValeuSelected, Gender: true });
      setFilterObj({ ...filterObj, gender: value });
    } else if (type === "Size") {
      setIsValueSelcetd({ ...isValeuSelected, Size: true });
      setFilterObj({ ...filterObj, size: value });
    } else if (type == "Product Type") {
      setIsValueSelcetd({ ...isValeuSelected, ProductType: true });
      setFilterObj({ ...filterObj, type: value });
    } else if (type === "Offers") {
      setIsValueSelcetd({ ...isValeuSelected, offerType: value?.uid });
      setFilterObj({ ...filterObj, offer: value });
    }
  };

  const onClickDone = () => {
    let filtrationObject = {};
    for (let keys in filterObj) {
      if (filterObj[keys] !== "") {
        filtrationObject[keys] = filterObj[keys];
      }
    }
    let data = [...products];
    const filteredArray = data?.filter((v) =>
      Object.keys(filtrationObject).every((k) =>
        typeof v[k] == "string"
          ? filtrationObject[k].toLowerCase() == v[k]
          : k == "offer"
          ? v[k].name == filtrationObject[k].name
          : v[k]?.includes(filtrationObject[k])
      )
    );
    setProducts(filteredArray);
  };
  const clearAllFilters = () => {
    setProducts(products);
    setIsValueSelcetd({});
    setFilterObj({});
  };

  const showDone = () => {
    let show = true;
    let keys = Object.keys(filterObj);
    if (keys?.length > 0) {
      show = keys?.every((v) => filterObj[v] == null);
      return show;
    }
    return show;
  };

  const rateAndReviewProduct = async () => {
    if (user) {
      if (productRating) {
        let apiData = {
          rating: productRating,
          uid: ratedProductUid,
          review: productReview,
        };
        let res = await dispatch(rateProduct(apiData));
        if (res) {
          setProductReview(null);
          setProductRating(null);
          setRatedProductUid(null);
          setShowRateReviewModal(false);
        }
      } else {
        toast.error("Please give this product a rating to rate this product");
      }
    } else {
      toast.error("Please Login to rate or review a Product");
    }
  };

  return (
    <>
      <div
        className="container-fluid p-lg-5 main-container"
        style={{ backgroundColor: "#F2F3FA" }}
      >
        <img
          src={state ? state?.cover_photo : banner}
          className="brand-detail-banner"
          // height="250px"
          // width="100%"
        />
        <div className="brand-detail-banner-logo">
          <img
            src={state ? state?.display_picture : logo1}
            className="brand-detail-banner-logo-mobile"
            height="100px"
            width="150px"
          />
        </div>
        <div style={{ border: "1px solid lightgrey", borderRadius: "8px" }}>
          <div className="container banner-cont">
            <div className="row pt-5">
              <div className="col-lg-6 d-flex justify-content-start">
                <div className="">
                  <h4>{state ? state.name : "Nike"}</h4>
                  <p>
                    {state
                      ? state.description
                      : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."}
                  </p>
                  <span
                    className="detail-contact"
                    style={{
                      color: "#F4A950",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowContactInfo(true)}
                  >
                    Contact Info
                  </span>
                </div>
              </div>
              <div className="col-lg-6 d-flex align-items-end flex-column ">
                {user && (
                  <div className="row">
                    {favourite_loading ? (
                      <Loader height={20} width={20} />
                    ) : (
                      <div
                        onClick={() =>
                          dispatch(favouriteBrand({ uid: state?.uid }))
                        }
                        className="col-12"
                      >
                        {checkFavourite() ? (
                          <AiFillHeart color="red" size={30} />
                        ) : (
                          <AiOutlineHeart size={30} />
                        )}
                      </div>
                    )}
                  </div>
                )}
                {Object.keys(state?.social_links).length > 0 && (
                  <div className="row mt-auto">
                    <div className="col-12">
                      {state?.social_links?.facebook && (
                        <i
                          onClick={() =>
                            openLink(state?.social_links?.facebook)
                          }
                          style={{ fontSize: "23px" }}
                          className="mx-2 fa fa-facebook-f"
                        />
                      )}
                      {state?.social_links?.instagram && (
                        <i
                          onClick={() =>
                            openLink(state?.social_links?.instagram)
                          }
                          style={{ fontSize: "23px" }}
                          className="mx-2 fa fa-instagram"
                        />
                      )}
                      {state?.social_links?.pinterest && (
                        <i
                          onClick={() =>
                            openLink(state?.social_links?.pinterest)
                          }
                          style={{ fontSize: "23px" }}
                          className="mx-2 fa fa-pinterest"
                        />
                      )}
                      {state?.social_links?.linkedin && (
                        <i
                          onClick={() =>
                            openLink(state?.social_links?.linkedin)
                          }
                          style={{ fontSize: "23px" }}
                          className="mx-2 fa fa-linkedin-in"
                        />
                      )}
                      {state?.social_links?.youtube && (
                        <i
                          onClick={() => openLink(state?.social_links?.youtube)}
                          style={{ fontSize: "23px" }}
                          className="mx-2 fa fa-youtube"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr style={{ border: "1px solid lightgrey" }} />
          <div className="container mt-2">
            <div className="row p-lg-3">
              <div className="col-lg-6">
                <div className="row">
                  <button
                    autoFocus={true}
                    className={` ${
                      tab === "offers"
                        ? "brand-detail-button-focus mx-2"
                        : "brand-detail-button mx-2"
                    }  `}
                    onClick={() => {
                      setSelectedProduct(null);
                      setTab("offers");
                    }}
                  >
                    Offers
                  </button>
                  {getGames().length > 0 && (
                    <button
                      // className="brand-detail-button mx-2"
                      className={` ${
                        tab === "win"
                          ? "brand-detail-button-focus mx-2"
                          : "brand-detail-button mx-2"
                      }  `}
                      onClick={() => {
                        setSelectedProduct(null);

                        setTab("win");
                      }}
                    >
                      Chance to win
                    </button>
                  )}
                  <button
                    // className="brand-detail-button"
                    className={` ${
                      tab === "stores"
                        ? "brand-detail-button-focus mx-2"
                        : "brand-detail-button mx-2"
                    }  `}
                    onClick={() => {
                      setSelectedProduct(null);
                      setTab("stores");
                    }}
                  >
                    Stores
                  </button>
                </div>
              </div>
              <div className="col-lg-6 ">
                {tab == "offers" && (
                  <div className="row d-flex justify-content-lg-end justify-content-start">
                    <div className="col-lg-4 col-md-6 col-sm-12 col-6 d-flex justify-content-end flex-column mt-lg-0 mt-2">
                      <button
                        // className="brand-detail-button mx-0"
                        className={` ${
                          showFilter
                            ? "brand-detail-button-focus mx-0"
                            : "brand-detail-button mx-0"
                        }`}
                        onClick={() => {
                          if (isFilterDone) {
                            setProducts(setProd());
                            setIsfilterDone(false);

                            return;
                          }
                          setShowFilter(!showFilter);
                          setSelectedProduct(null);
                          setShowSort(false);
                          clearAllFilters();
                        }}
                      >
                        <i className="fa fa-filter px-1" />
                        {isFilterDone ? "Clear Filter" : "Filter"}
                      </button>

                      <div
                        className={`${
                          showFilter
                            ? "filter-content-div"
                            : "filter-content-div-when-clicked"
                        }`}
                      >
                        <ul className="filter-content-ul">
                          <li className="d-flex flex-column">
                            <div className="d-flex flex-row justify-content-between">
                              <p>Offers</p>
                              {showDone() == false && (
                                <>
                                  <p
                                    onClick={() => {
                                      onClickDone();
                                      setShowFilter(false);
                                      setIsfilterDone(true);
                                    }}
                                    className="text-success cursor-pointer"
                                  >
                                    <i
                                      className="fa fa-check"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Done
                                  </p>
                                </>
                              )}
                            </div>
                            <div>
                              <FilterBrandSlider
                                getSelectedOffer={(data, index) => {
                                  if (
                                    isValeuSelected?.offerType === data?.uid
                                  ) {
                                    setIsValueSelcetd({
                                      ...isValeuSelected,
                                      offerType: null,
                                    });
                                    setFilterObj({
                                      ...filterObj,
                                      offer: null,
                                    });
                                  } else {
                                    filterProducts(data, "Offers");
                                  }
                                }}
                                offers={state?.offer}
                                selectedOffer={isValeuSelected.offerType}
                                isOfferSelected={isFilterDone}
                                selectedIndex={selectedOfferIndex}
                              />
                            </div>
                          </li>
                          {["Gender", "ProductType"]?.map((v, i) => (
                            <li
                              onClick={() => {
                                if (isValeuSelected[v]) {
                                  let newObj = { ...isValeuSelected };
                                  newObj[v] = false;
                                  setIsValueSelcetd(newObj);
                                  if (v === "Gender") {
                                    setFilterObj({
                                      ...filterObj,
                                      gender: null,
                                    });
                                  } else if (v == "ProductType") {
                                    setFilterObj({
                                      ...filterObj,
                                      type: null,
                                    });
                                  } else if (v == "Size") {
                                    setFilterObj({
                                      ...filterObj,
                                      size: null,
                                    });
                                  }
                                }
                                setSelectedFilter(v);
                                setShowNestedDiv(!showNestedDiv);
                                setShowSort(false);
                              }}
                              className="d-flex justify-content-between cursor-pointer"
                            >
                              {v}
                              {isValeuSelected[v] == !!v && (
                                <i
                                  className="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              )}
                            </li>
                          ))}
                          {showNestedDiv &&
                            returnCurrentFilter()?.value?.length > 0 && (
                              <div className="gender-filter-div">
                                <ul className="gender-filter-ul">
                                  {returnCurrentFilter()?.value?.map((v, i) => (
                                    <li
                                      onClick={() =>
                                        filterProducts(
                                          v?.name,
                                          returnCurrentFilter()?.name
                                        )
                                      }
                                      key={i}
                                    >
                                      {v?.name}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                        </ul>
                      </div>
                    </div>

                    <div className="col-lg-5 col-md-6 col-sm-12 col-8 mt-lg-0 mt-2">
                      {
                        <button
                          // className="dropdown-toggle  brand-detail-button "
                          className={` ${
                            showSort
                              ? "dropdown-toggle brand-detail-button-focus mx-0"
                              : "dropdown-toggle brand-detail-button mx-0"
                          }`}
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={() => {
                            setShowSort(!showSort);
                            setShowFilter(false);
                          }}
                        >
                          <i className="fa fa-sort px-1" />
                          SORT BY{" "}
                          {/* <span style={{ fontWeight: "400", fontSize: "12px" }}>
                            whats new <i className="fa fa-caret" />
                          </span> */}
                          <div
                            className={`${
                              showSort
                                ? "sort-content-div"
                                : "sort-content-div-when-clicked"
                            }`}
                          >
                            <ul className="sort-content-ul">
                              {sortElements?.map((v, i) => (
                                <li
                                  onClick={() => sortProduct(v?.value)}
                                  key={i}
                                >
                                  {v?.value}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </button>
                      }
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {!selectedProduct ? null : (
            <ProductDetail
              clicksLoading={clicks_loading}
              ref={product}
              selectedProductSize={productSize}
              selectedImage={selectedImage}
              brandName={state?.name}
              selectedProduct={selectedProduct}
              setRatedProductUid={(id) => setRatedProductUid(id)}
              changeSelectedImage={(data) => setSelectedImage(data)}
              changeProductSize={(data) => setProductSize(data)}
              showStoreModal={() => {
                setLocateStore(true);
                setLocateStoreSingleItem(null);
              }}
              onPresslogo={() => {
                let apiData = {
                  uid: selectedProduct?.uid,
                };
                dispatch(updateProductsClick(apiData));
              }}
              // onRateProduct={async (count, uid) => {
              //   if (user) {
              //     setProductRating(count);
              //     setRatedProductUid(uid);
              //     let apiData = {
              //       rating: count,
              //       uid: uid,
              //     };
              //     await dispatch(rateProduct(apiData));
              //   } else {
              //     toast.error("Please Login to rate or review a Product");
              //   }
              // }}
              openRateReviewModal={() => setShowRateReviewModal(true)}
            />
          )}

          {tab == "offers" ? (
            <SingleProductCard
              selectedProductName={selectedProduct?.uid}
              products={getProducts()}
              brandName={state?.name}
              clicksLoading={clicks_loading}
              onPressProduct={(product) => {
                setSelectedProduct(product);
                TakeMeToProduct();
              }}
              onRateProduct={async (count, uid) => {
                if (user) {
                  setProductRating(count);
                  setRatedProductUid(uid);
                  let apiData = {
                    rating: count,
                    uid: uid,
                  };
                  await dispatch(rateProduct(apiData));
                } else {
                  toast.error("Please Login to Continue");
                }
              }}
              onPresslogo={(uid) => {
                let apiData = {
                  uid: uid,
                };
                dispatch(updateProductsClick(apiData));
              }}
              onPressLocateStore={(item) => {
                setLocateStore(true);
                setLocateStoreSingleItem(item);
              }}
            />
          ) : tab === "win" ? (
            <Entertainment
              games={getGames()}
              onPressBuy={() => {
                if (user) {
                  setShow(true);
                } else {
                  toast.error("Please Login to Continue");
                }
              }}
              onPressGuess={() => {
                if (user) {
                  navigate("/guess", {
                    state: {
                      brand_uid: state?.uid,
                      display_picture: state?.display_picture,
                      prize: state?.buy_prize?.name,
                      prizeImg: state?.buy_prize?.display_picture,
                    },
                  });
                } else {
                  toast.error("Please Login to Continue");
                }
              }}
              onPressRate={() => {
                if (user) {
                  setShowRate(true);
                } else {
                  toast.error("Please Login to Contine");
                }
              }}
            />
          ) : tab == "stores" ? (
            <div className="container ">
              <div className="row py-5">
                {state?.store?.map((item, idx) => {
                  return (
                    <div key={idx} className="col-lg-4  mb-4">
                      <div className="card-prod-map">
                        <div className="brand-offers-store-map">
                          <GoogleMapReact
                            bootstrapURLKeys={{
                              key: "AIzaSyBV5cGupCqBSMPln3x_m34s5MrM7erpeSU",
                            }}
                            defaultCenter={{
                              lat: item?.location?.latitude,
                              lng: item?.location?.longitude,
                            }}
                            defaultZoom={12}
                          >
                            <LocationPin
                              lat={item?.location.latitude}
                              lng={item?.location.longitude}
                            />
                          </GoogleMapReact>
                        </div>

                        <div
                          className="opact-map"
                          onClick={() => {
                            setData({
                              ...item,
                              offered_product_types:
                                state?.offered_product_types,
                            });
                            setDisplayStoreInfoModal(true);
                          }}
                        >
                          {/* <span className="expand">
                            <i className="fas fa-expand" />{" "}
                          </span> */}
                        </div>

                        <h4 className="pt-3 px-3 proxima-font font-weight-bold">
                          {capitalize(item?.name)}
                        </h4>
                        <div className="row mx-0 mb-4 quicksand-font font-weight-bold">
                          <div className="col-lg-6">
                            <div style={{ color: "#F4A950" }}>Website</div>
                            <span>{state?.website}</span>
                            <div className="mb-1" style={{ color: "#F4A950" }}>
                              Product Type
                            </div>
                            <div className="row">
                              {item?.product_types?.map((it, index) => (
                                <div className=" col-5 col-lg-4 mx-1 prod-type-tabs mb-1">
                                  <div className="">{it}</div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div style={{ color: "#F4A950" }}>Phone Number</div>
                            <span>{item?.phone_number}</span>
                            <div style={{ color: "#F4A950" }}>Address</div>
                            <div
                              className="brand-offers-store-map-address truncate-text-6"
                              // style={{ height: "120px" }}
                            >
                              {`${item?.address} 
                              ${item?.city} 
                              ${item?.country}`}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <StoreInformationModal
        show={displayStoreInfoModal}
        onHide={() => setDisplayStoreInfoModal(false)}
        data={data}
        website={state?.website}
      />
      <BuyAndWin uid={state?.uid} show={show} onHide={() => setShow(false)} />
      <RateAndWin
        uid={state?.uid}
        show={showRate}
        onHide={() => setShowRate(false)}
      />
      <LocateStore
        storeModalInfo={selectedProduct}
        singleStoreModalInfo={locateStoreSingleItem}
        show={locateStore}
        onHide={() => setLocateStore(false)}
        setFocusOnLocation={(data) => setSelectedMarker(data)}
        selectedMarker={selectedMarker}
      />
      <ContactInfo
        brandName={state?.name}
        brandEmail={state?.email_address}
        brandWebsite={state?.website}
        brandPhone={state?.phone_number}
        show={showContactInfo}
        onHide={() => setShowContactInfo(false)}
      />
      <RateAndReview
        show={showRateReviewModal}
        onHide={() => setShowRateReviewModal(false)}
        setProductRating={(rating) => setProductRating(rating)}
        setProductReview={(review) => setProductReview(review)}
        setRatedProductUid={(uid) => setRatedProductUid(uid)}
        rateAndReviewProduct={() => rateAndReviewProduct()}
      />
    </>
  );
}
