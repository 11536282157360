import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-12 d-flex flex-column justify-content-center align-items-center privacy-policy p-5 raleway-font">
          <h1 className="font-weight-bold">
            Privacy Policy for Brand Emirates
          </h1>
          <div className="text-start mx-5 px-5">
            <p>
              At Brand Emirates, one of our main priorities is the privacy of
              our visitors. This Privacy Policy document contains types of
              information that is collected and recorded by Brand Emirates and
              how we use it.
            </p>
            <p>
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
            <p>
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in Brand Emirates . This policy is
              not applicable to any information collected offline or via
              channels other than this website.
            </p>
            {/* <div className="text-start"> */}
            <h1>Consent</h1>
            <p>
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </p>
            <h1>Information we Collect</h1>
            <p>
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </p>

            <p>
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </p>
            <p>
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </p>
            <h1>How we use your information</h1>
            <p>
              We use the information we collect in various ways, including to:
            </p>
            <div className="pl-4">
              <ul>
                <li>Provide, operate, and maintain our website</li>
                <li>Improve, personalize, and expand our website</li>
                <li>Understand and analyze how you use our website</li>
                <li>
                  Develop new products, services, features, and functionality
                </li>
                <li>
                  Communicate with you, either directly or through one of our
                  partners, including for customer service, to provide you with
                  updates and other information relating to the website, and for
                  marketing and promotional purposes
                </li>
                <li>Send you emails</li>
                <li>Find and prevent fraud</li>
              </ul>
            </div>
            <h1>Log Files</h1>
            <p>
              Brand Emirates follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and a part of hosting services’ analytics. The
              information collected by log files include internet protocol (IP)
              addresses, browser type, Internet Service Provider (ISP), date and
              time stamp, referring/exit pages, and possibly the number of
              clicks. These are not linked to any information that is personally
              identifiable. The purpose of the information is for analyzing
              trends, administering the site, tracking users’ movement on the
              website, and gathering demographic information.
            </p>
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
