import "../modal/css/locateStore.css";
import { Modal } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import { resendBrandOtp, verifyBrandOtp } from "../../../api/slices/authSlice";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function Otp({
  otp,
  handleOtp,
  show,
  onHide,
  seconds,
  setSeconds,
  unverifiedUser,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title id="contained-modal-title-vcenter text-white">
            OTP
          </Modal.Title>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row w-100 my-4 mx-auto">
            <div className="col-12">
              <p>
                We Have Sent you an OTP on your Email Address. Please Enter OTP
                to Verify your Email Address
              </p>
              <input
                type="text"
                name="name"
                style={{ border: "2px solid #7E8084" }}
                onChange={(e) => handleOtp(e?.target?.value)}
                className="budgut-input mt-2 w-100"
              />
            </div>

            <div className="col-2 d-flex justify-content-center"></div>

            <div className="col-4 d-flex justify-content-center mt-3">
              <button
                style={{
                  fontFamily: "Avantgrade Demi",
                  opacity: seconds > 0 ? "0.5" : "1",
                }}
                className="home-brand-button d-flex flex-row"
                disabled={seconds > 0}
                onClick={async () => {
                  let apiData = {
                    uid: unverifiedUser?.uid,
                  };
                  let res = await dispatch(resendBrandOtp(apiData));
                  if (res?.payload) {
                    setSeconds(59);
                  }
                }}
              >
                Resend
                {seconds > 0 && (
                  <span className="pl-2">
                    {`00:${seconds < 10 ? "0" : ""}${seconds}`}
                  </span>
                )}
              </button>
            </div>

            <div className="col-3 d-flex justify-content-center mt-3 ml-4">
              <button
                style={{ fontFamily: "Avantgrade Demi" }}
                className="home-brand-button"
                onClick={async () => {
                  if (!otp) {
                    toast.error("Enter OTP");
                  } else {
                    let apiData = {
                      uid: unverifiedUser?.uid,
                      token: otp,
                    };

                    let res = await dispatch(verifyBrandOtp(apiData));
                    if (res?.payload) {
                      navigate("/");
                      onHide();
                    }
                  }
                }}
              >
                Verify
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
