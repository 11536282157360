import React from "react";

const SingleCountView = ({ name, count, url }) => {
  return (
    <div className="col-lg-3 col-md-6 col-sm-6 col-6 text-center">
      <div className="d-flex justify-content-center">
        <div className="circle-grey ">
          <img src={url} height="50px" width="50px" className="count1 p-2" />
        </div>
      </div>

      <h2 className="mt-2">{count}</h2>
      <p>{name}</p>
    </div>
  );
};
export default SingleCountView;
