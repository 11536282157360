import React, { useEffect, useState } from "react";
import "../modal/css/locateStore.css";
import { Modal } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";

export default function ContactInfo({
  show,
  onHide,
  brandName,
  brandEmail,
  brandWebsite,
  brandPhone,
}) {
  const dispatch = useDispatch();
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            {brandName}
          </Modal.Title>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="contact-info-modal">
            <div className="quicksand-font">
              <div>
                <h5>Brand Name</h5>
                <p>{brandName}</p>
              </div>
              <div>
                <h5>Email</h5>
                <p>{brandEmail}</p>
              </div>
              <div>
                <h5>Website</h5>
                <p>{brandWebsite}</p>
              </div>
              <div>
                <h5>Phone Number</h5>
                <p>{brandPhone ? brandPhone : "+92 311 3423521"}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
