import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import aboutus from "../statics/9-about-us-1.jpg";
import about1 from "../statics/9-about-us-2.jpg";
import about2 from "../statics/9-about-us-3.jpg";
import client1 from "../statics/Customer-testimonial-page.png";
import client2 from "../statics/Customer-testimonial-page.png";
import { useDispatch, useSelector } from "react-redux";
import { opinionMatters } from "../api/slices/webSlice";
import { Loader } from "../components/common";
import userPlaceholder from "../statics/userPlaceholder.png";

const About = () => {
  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(opinionMatters());
  }, [dispatch]);
  const { opinions, opinions_loading } = useSelector((state) => state.WebSlice);

  return (
    <>
      <Helmet>
        <title>{t("about.maintitle")}</title>
      </Helmet>
      <div
        className="container-fluid p-4"
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container">
          <div className="row mt-5">
            <div className="col-12 text-center">
              <span
                className="head-featured "
                style={{ fontSize: "72px", fontWeight: 500 }}
              >
                ABOUT BRAND
                <span
                  className="head-brand-green sm-screen"
                  style={{ fontSize: "72px", fontWeight: 500 }}
                >
                  {" "}
                  EMIRATES
                </span>
              </span>
              <p style={{ fontSize: "21px", fontWeight: 300 }}>
                Based In United Arab Emirates with client all over the globe{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="container" data-aos="slide-left">
          <div className="row mt-4">
            <div className="col-lg-5 d-flex justify-content-end sm-screen-image">
              <img src={aboutus} height="400px" width="80%" />
            </div>
            <div className="col-lg-7">
              <div className="p-5 sm-screen-desc" style={{ maxWidth: "80%" }}>
                <span className="head-featured ">
                  WHO
                  <span className="head-brand-green"> WE</span>
                  <span className="head-featured "> ARE</span>
                </span>
                <p className="quicksand-font mt-2">
                  If you know what you want, but don’t know where or how to find
                  it. Brand Emirates is the perfect platform for you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid p-4" data-aos="slide-right">
        <div className="container">
          <div className="row mt-4">
            <div className="col-lg-7 d-flex justify-content-center sm-screen-dis">
              <div className="p-5 sm-screen-desc" style={{ maxWidth: "80%" }}>
                <span className="head-featured ">
                  WHAT
                  <span className="head-brand-green"> WE</span>
                  <span className="head-featured "> DO</span>
                </span>
                <p className="quicksand-font mt-2">
                  We bring lots of offer on products like clothes, shoes,
                  purses, jewellery and more of well-known brands. Users can
                  shop from large number of stores all at once via Brand
                  Emirates, including high-end stores of well known brands, as
                  well as budget-friendly products.
                </p>
              </div>
            </div>
            <div className="col-lg-5 d-flex justify-content-start">
              <img src={about1} height="400px" width="80%" />
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid p-4"
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container" data-aos="slide-left">
          <div className="row mt-4">
            <div className="col-lg-5 d-flex justify-content-end sm-screen-dis">
              <img src={about2} height="400px" width="80%" />
            </div>
            <div className="col-lg-7 d-flex justify-content-start">
              <div className="p-5 sm-screen-desc" style={{ maxWidth: "80%" }}>
                <span className="head-featured ">
                  OUR
                  <span className="head-brand-green"> MISSION</span>
                </span>
                <p className="quicksand-font mt-2">
                  Our Mission is to make it easier selecting the perfect gift
                  for the person in your life and a large number of
                  opportunities to win amazing prizes. What’s even better? There
                  are exclusive deals on regular basis to save even more cash.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid p-4">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="p-5">
                <span className="head-featured ">
                  WHAT BRAND
                  <span className="head-brand-green"> FREAKS</span>
                  <span className="head-featured "> SAY ABOUT</span>
                  <span className="head-brand-green"> US</span>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5">
              {opinions_loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-3">
                    <img
                      className="rounded-circle"
                      height="90px"
                      width="100px"
                      // src={
                      //   opinions[1]?.customer?.display_picture &&
                      //   opinions[1]?.customer?.display_picture !== " "
                      //     ? opinions[1]?.customer?.display_picture
                      //     : userPlaceholder
                      // }
                      src={userPlaceholder}
                    />
                  </div>
                  <div className="col-lg-9 col-md-12 py-1">
                    {/* <p className="">
                      {opinions[1]?.rating?.review
                        ? opinions[1]?.rating?.review
                        : "Adidas Vortex Jacket is nice product"}
                    </p> */}
                    <p className="">
                      I like being branded and Brand Emirates has got everything
                      covered. Loved being here.
                    </p>
                    {/* <h6>
                      {opinions[1]?.customer?.name
                        ? opinions[1]?.customer?.name
                        : "Jon Doe"}
                    </h6> */}
                    <h6>Daniyal Ahmed</h6>
                  </div>
                </div>
              )}
            </div>
            <div className="col-lg-1 d-lg-flex d-md-none d-sm-none d-xs-none d-flex justify-content-center">
              <div className="vl"></div>
            </div>
            <div className="col-lg-5">
              {opinions_loading ? (
                <Loader />
              ) : (
                <div className="row">
                  <div className="col-lg-3">
                    <img
                      className="rounded-circle"
                      // src={
                      //   opinions[0]?.customer?.display_picture &&
                      //   opinions[0]?.customer?.display_picture !== " "
                      //     ? opinions[0]?.customer?.display_picture
                      //     : userPlaceholder
                      // }
                      src={userPlaceholder}
                      height="90px"
                      width="100px"
                    />
                  </div>
                  <div className="col-lg-9 col-md-12 py-1">
                    {/* <p>
                      {opinions[0]?.rating?.review
                        ? opinions[0]?.rating?.review
                        : "Adidas Vortex Jacket is nice product"}
                    </p> */}
                    <p>
                      Brand Emirates is the perfect platform to shop products.
                    </p>
                    {/* <h6>
                      {opinions[0]?.customer?.name
                        ? opinions[0]?.customer?.name
                        : "Jon Doe"}
                    </h6> */}
                    <h6>Jon Doe</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
