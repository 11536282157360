import React from "react";
import belogo from "../statics/comingsoon/logo.png";
import facebook from "../statics/comingsoon/facebook.png";
import instagram from "../statics/comingsoon/instagram.png";
import linkedin from "../statics/comingsoon/linkedin.png";
import logo from "../statics/comingsoon/ripple-logo.png";

import { useNavigate } from "react-router-dom";

export default function ComingSoon(props) {
  const navigate = useNavigate();
  return (
    <div className="container-fluid backgeound-coming">
      <div className="row pt-5">
        <div className="col-12 text-center mt-5">
          <img src={belogo} alt="ewvm-logo" width={"150px"} height={"100%"} />
        </div>
      </div>
      <div className="row mt-5 pt-5">
        <div className="col-12 text-center">
          <h1
            className="text-white"
            style={{ fontSize: "72px", fontFamily: "Quick sand reg" }}
          >
            We Are
          </h1>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12 text-center">
          <h1
            className="text-white"
            style={{ fontSize: "72px", fontFamily: "Proxima Nova black" }}
          >
            COMING SOON
          </h1>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 text-center">
          <button
            className="px-5 text-white  py-3 coming-soon-button "
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate("/user-types");
            }}
          >
            <span>
              Register Now{" "}
              <i
                className="fas fa-greater-than ml-2"
                style={{ fontSize: "16px" }}
              ></i>
            </span>
          </button>
        </div>
      </div>
      <div className="row mt-5 pt-5 px-5 text-center">
        <div className="col-lg-4">
          <p className="text-white" style={{ fontFamily: "Quick sand reg" }}>
            Location
          </p>
          <span
            className="text-white"
            style={{ fontFamily: "Quick sand bold" }}
          >
            Suit 203 Citadel Tower,
            <br />
            Business Bay,
            <br />
            Dubai - United Arab Emirates
          </span>
        </div>
        <div className="col-lg-4">
          <p className="text-white" style={{ fontFamily: "Quick sand reg" }}>
            Contact
          </p>
          <span
            className="text-white"
            style={{ fontFamily: "Quick sand bold" }}
          >
            info@brandemirates.com
            <br />
            +971 50 207 6024
          </span>
        </div>
        <div className="col-lg-4">
          <p className="text-white" style={{ fontFamily: "Quick sand reg" }}>
            Follow Us
          </p>
          <span className="text-white">
            {/* <img src={facebook} className="mx-2" height="20px" width="20px" /> */}
            <div className="social-items">
              <a
                rel="noreferrer"
                href={"https://www.facebook.com/profile.php?id=100086795983221"}
                target="_blank"
              >
                <div className="icon-fa">
                  <i
                    className="fa fa-facebook"
                    style={{ color: "#3b5998" }}
                    aria-hidden="true"
                  ></i>
                </div>
              </a>
            </div>
            {/* <img src={instagram} className="mx-2" height="20px" width="20px" /> */}
            <div className="social-items">
              <a
                rel="noreferrer"
                href={"https://www.instagram.com/brandemiratesofficial/"}
                target="_blank"
              >
                <div className="icon-fa">
                  <i
                    className="fa fa-instagram"
                    aria-hidden="true"
                    style={{ color: "#8A3AB9" }}
                  ></i>
                </div>
              </a>
            </div>
            <div className="social-items">
              <a
                rel="noreferrer"
                href={
                  "https://www.youtube.com/channel/UC2c6J8T8b2b6jQN68k_HcNQ"
                }
                target="_blank"
              >
                <div className="icon-fa">
                  <i
                    className="fa fa-youtube-play"
                    aria-hidden="true"
                    style={{
                      color: "#c00",
                    }}
                  ></i>
                </div>
              </a>
            </div>
            {/* <img src={linkedin} className="mx-2" height="20px" width="20px" /> */}
            <div className="social-items">
              <a
                rel="noreferrer"
                href={"https://www.linkedin.com/in/brand-emirates-59aaaa253"}
                target="_blank"
              >
                <div className="icon-fa">
                  <i
                    className="fa fa-linkedin"
                    aria-hidden="true"
                    style={{ color: "#0A66C2" }}
                  ></i>
                </div>
              </a>
            </div>
            <div className="social-items">
              <a rel="noreferrer" href={"https://twitter.com/"} target="_blank">
                <div className="icon-fa">
                  <i
                    className="fa fa-twitter"
                    aria-hidden="true"
                    style={{ color: "#00acee" }}
                  ></i>
                </div>
              </a>
            </div>
          </span>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 d-flex justify-content-center ">
          <p
            style={{
              // position: "fixed",
              // bottom: 0,
              textAlign: "center",
              fontFamily: "Quick sand medium",
            }}
            className="text-white "
          >
            Developed & Maintained by{" "}
            <img src={logo} alt="ripple-logo" width={"120px"} height={"100%"} />
          </p>
        </div>
      </div>
    </div>
  );
}
