import "./brandOfferSlider.css";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";

export default function BrandOfferSlider({ data, onPressView }) {
  var settings = {
    speed: 1000,
    autoplaySpeed: 2000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    infinite: false,
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          centerPadding: "35px",

          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <Slider {...settings}>
        {data?.map((item, index) => (
          <>
            <div className="row brand-offer-slider">
              <div className="col-lg-1 col-0"></div>
              <div className="col-lg-4 col-12 d-flex justify-content-center pt-3">
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={item?.display_picture}
                    height="200px"
                    width="200px"
                    className="brand-offer-slider-image"
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      height: "55vh",
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12 brand-offer-slider-details">
                <div className="row pt-lg-3">
                  <div className="col-lg-9  hot-deal-deatails">
                    <h2 className="raleway-font gradient-font">
                      {item?.product?.name}
                    </h2>
                    <div className="d-flex flex-row justify-content-between">
                      <img
                        src={item?.brand?.display_picture}
                        height="70px"
                        width="160px"
                        className="brand-offer-slider-logo"
                      />
                      <div className="pr-lg-5 mr-lg-4">
                        <h5>{`AED: ${item?.product?.discounted_price}`}</h5>
                        <h5 className="hot-offer-discount-price text-danger">
                          {`AED: ${item?.product?.price}`}
                        </h5>
                      </div>
                    </div>
                    <p className="description pt-2 truncate-text-5">
                      {item?.product?.description}
                    </p>
                    <div
                      className="d-flex flex-row align-items-center"
                      // key={selectedProduct?.average_rating}
                    >
                      <span className="mr-2">Rating: </span>
                      <ReactStars
                        count={5}
                        value={item?.product?.average_rating}
                        size={24}
                        activeColor="#ffd700"
                        color="gray"
                        edit={false}
                        // onChange={(count) => {
                        //   onRateProduct(count, selectedProduct?.uid);
                        // }}
                      />
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-center justify-content-lg-start">
                    <button
                      onClick={() => onPressView(item)}
                      className="home-brand-button-brand m-lg-4"
                    >
                      VIEW OFFER
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-1 col-0"></div>
            </div>
          </>
        ))}
      </Slider>
    </div>
  );
}
