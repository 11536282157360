import React, { useEffect, useState } from "react";

import belogo from "../../../statics/logo-4.2.4.png";
import profileLogo from "../../../statics/logo-4.2.4.png";
import ligual from "../../../statics/Icon material-translate.png";
import "../../../styles/Navbar.css";

import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleNavbar } from "../../../api/slices/globalSlice";
import {
  setUnverifiedUser,
  setUser,
  setPhoneChanged,
} from "../../../api/slices/authSlice";
import placeholder from "../../../statics/userPlaceholder.png";
import UserProfile from "../modal/UserProfile";
import YourActivity from "../modal/YourActivity";
import { BiLogOut, BiLock } from "react-icons/bi";
import { MdOutlineLibraryBooks } from "react-icons/md";
import { AiFillEdit } from "react-icons/ai";
import ChangePassword from "../modal/ChangePassword";
import {
  readActivities,
  viewedNotifications,
} from "../../../api/slices/webSlice";
import brandLogo from "../../../statics/brand-emirates-logo-1.png";
import moment from "moment";
import { Loader } from "../../common";
import EditPhoneOtp from "../modal/EditPhoneOtp";

const Navbar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const { navbarColor } = useSelector((state) => state.globalSlice);
  const { user, change_pass_loading, phoneChanged } = useSelector(
    (state) => state.AuthSlice
  );
  const {
    read_activities,
    activity_loading,
    read_notifications,
    notification_loading,
    viewed_notifications,
  } = useSelector((state) => state.WebSlice);

  const [visible, setVisible] = useState(true);
  const [userClick, setUserClick] = useState(false);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [showYourActivity, setShowYourActivity] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showChangeNumberModal, setShowChangeNumberModal] =
    useState(phoneChanged);
  const [showNotifications, setShownotifications] = useState(false);
  const [oldPwType, setOldPwType] = useState(false);
  const [newPwType, setNewPwType] = useState(false);
  const [confirmPwType, setConfirmPwType] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number);
  const [phoneOtp, setPhoneOtp] = useState(null);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    showYourActivity && dispatch(readActivities());
  }, [showYourActivity]);
  useEffect(() => {
    if (read_notifications?.notification?.length > 0) {
      setTimeout(() => {
        const filterNotification = read_notifications?.notification?.filter(
          (v) => !v.is_seen
        );
        if (filterNotification?.length > 0) {
          const ids = [];
          filterNotification?.map((v) => ids.push(v?.uid));
          const apiData = {
            notification_ids: ids,
          };
          dispatch(viewedNotifications(apiData));
        }
      }, 4000);
    }
  }, [showNotifications]);

  const unSeenNotification = read_notifications?.notification?.filter(
    (v) => !v.is_seen
  );

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 10) {
      setVisible(false);
    } else if (scrolled <= 10) {
      setVisible(true);
    }
  };
  const logoutUser = () => {
    localStorage.clear();
    dispatch(setUser(null));
    dispatch(setUnverifiedUser(null));
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
  }, [toggleVisible, visible]);

  const checkTime = (date) => {
    const formatToDateObj = moment.unix(date).format("YYYY-MM-DD");
    const now = moment(new Date()).format("YYYY-MM-DD");
    if (moment(formatToDateObj).isSame(now, "day")) {
      return moment.unix(date).format("hh:mm A");
    } else if (moment(formatToDateObj).isSame(now, "week")) {
      return moment.unix(date).format("ddd");
    } else {
      return moment.unix(date).format("DD/MM");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return (
    <>
      <nav
        className={`navbar navbar-expand-xl navbar-light  custom-nav ${
          visible ? "sticky-top" : "st-sticky-header"
        }`}
        style={{ backgroundColor: navbarColor }}
        // style={{ zIndex: visible ? "1" : "" }}
      >
        <div className="container customenavcontainer">
          <Link to="/">
            <img src={belogo} alt="ewvm-logo" width={"150px"} height={"100%"} />
          </Link>

          <button
            className="navbar-toggler toggle-iconcustome ms-auto mt-3 "
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon "></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="ml-auto">
              <div className="row">
                <ul className="navbar-nav mb-auto mt-1 mb-lg-0 ms-auto pt-1 text-capitalize ">
                  <li className="nav-item">
                    <Link to="/" className={`nav-link secnav `}>
                      {t("navbar.navtxt1")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/about" className={`nav-link secnav `}>
                      {t("navbar.navtxt2")}
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link to="/brand-offer" className={`nav-link secnav `}>
                      {t("navbar.navtxt3")}
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/contact-us" className={`nav-link secnav `}>
                      {t("navbar.navtxt4")}
                    </Link>
                  </li>

                  {user ? null : (
                    <li className="nav-item">
                      <Link to="/login" className={`nav-link secnav `}>
                        {t("navbar.navtxt5")}
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="ml-auto d-flex flex-row align-items-center">
              {user && (
                <div className="" style={{ position: "relative" }}>
                  {unSeenNotification?.length > 0 && (
                    <span className="alert-count">
                      {unSeenNotification?.length}
                    </span>
                  )}
                  <i
                    className="fas fa-bell mx-3 noti-icon"
                    aria-hidden="true"
                    onClick={() => {
                      setShownotifications(!showNotifications);
                      setUserClick(false);
                    }}
                  ></i>
                  {/* {unSeenNotification?.length > 0 && (
                    <span className="badge">{unSeenNotification?.length}</span>
                  )} */}
                </div>
              )}

              {
                <div
                  className={
                    showNotifications ? "row my-notifications" : "d-none"
                  }
                >
                  <div className="col-lg-12 d-flex flex-row align-items-center justify-content-between raleway-font pt-3 pb-1">
                    <h5 className="font-weight-bold">Notifications</h5>
                    {/* <div className="font-weight-bold noti-mark-text">
                      Mark all as read
                    </div> */}
                  </div>
                  <hr />
                  <div className="notifications-div">
                    {notification_loading ? (
                      <Loader />
                    ) : (
                      read_notifications?.notification?.map((v) => (
                        <div className="col-lg-12 notification-item d-flex flex-row py-2">
                          <div className="col-lg-2">
                            <img
                              src={placeholder}
                              alt=""
                              width={50}
                              height={50}
                            />
                          </div>
                          <div className="col-lg-7 noti-text d-flex flex-column justify-content-center proxima-font">
                            <span>{v?.body}</span>
                            {/* <span>Avail It.</span> */}
                          </div>
                          <div className="col-lg-3 flex-column d-flex align-items-end quicksand-font">
                            {checkTime(v?.created_at)}
                          </div>
                        </div>
                      ))
                    )}
                  </div>
                </div>
              }

              {user && (
                <>
                  <img
                    src={
                      user?.display_picture && user?.display_picture !== " "
                        ? user?.display_picture
                        : placeholder
                      // placeholder
                    }
                    key={user?.uid}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = user?.display_picture;
                    }}
                    width={25}
                    height={25}
                    alt=""
                    className="user-dp"
                    onClick={() => {
                      setUserClick(!userClick);
                      setShownotifications(false);
                    }}
                  />
                  <div
                    className={
                      userClick ? "user-logout raleway-font" : "d-none"
                    }
                  >
                    <ul>
                      <li
                        // onClick={() => {
                        //   setUserClick(false);
                        //   setShowUserProfile(true);
                        // }}
                        className="d-flex flex-row align-items-center pt-2"
                      >
                        <img
                          src={
                            user?.display_picture &&
                            user?.display_picture !==
                              ("" || " " || undefined || null)
                              ? user?.display_picture
                              : placeholder
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = user?.display_picture;
                          }}
                          height={30}
                          width={30}
                          alt=""
                          className="user-profile-dp"
                        />
                        <div className="d-flex flex-column pl-1">
                          <span>{user?.name}</span>
                          <span
                            onClick={() => {
                              setUserClick(false);
                              setShowUserProfile(true);
                              setPhoneNumber(user?.phone_number);
                            }}
                            className="d-flex align-items-center user-edit-profile"
                          >
                            <AiFillEdit size={15} className="mr-2" />
                            Edit Profile
                          </span>
                        </div>
                      </li>
                      <li
                        onClick={() => {
                          setUserClick(false);
                          setShowChangePassword(true);
                        }}
                      >
                        <span className="d-flex align-items-center">
                          <BiLock size={25} className="mr-2" />
                          Change Password
                        </span>
                      </li>
                      <li
                        onClick={() => {
                          setUserClick(false);
                          setShowYourActivity(true);
                        }}
                      >
                        <span className="d-flex align-items-center">
                          <MdOutlineLibraryBooks size={25} className="mr-2" />
                          Your Activity
                        </span>
                      </li>

                      <li
                        onClick={() => {
                          logoutUser();
                          setUserClick(false);
                        }}
                      >
                        <span className="d-flex align-items-center">
                          <BiLogOut size={25} className="mr-2" />
                          Logout
                        </span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </nav>
      <UserProfile
        show={showUserProfile}
        onHide={() => setShowUserProfile(false)}
        user={user}
        phoneNumber={phoneNumber}
        setPhoneNumber={(number) => setPhoneNumber(number)}
        setShowChangeNumberModal={(flag) => setShowChangeNumberModal(flag)}
      />
      <YourActivity
        show={showYourActivity}
        onHide={() => setShowYourActivity(false)}
        activities={read_activities}
        loading={activity_loading}
      />
      <ChangePassword
        loading={change_pass_loading}
        show={showChangePassword}
        onHide={() => {
          setShowChangePassword(false);
          setOldPwType(false);
          setNewPwType(false);
          setConfirmPwType(false);
        }}
        user={user}
        oldPwType={oldPwType}
        newPwType={newPwType}
        confirmPwType={confirmPwType}
        setOldPwType={(flag) => setOldPwType(flag)}
        setNewPwType={(flag) => setNewPwType(flag)}
        setConfirmPwType={(flag) => setConfirmPwType(flag)}
      />
      <EditPhoneOtp
        show={phoneChanged}
        // show={showChangeNumberModal}
        onHide={() => {
          setShowChangeNumberModal(false);
          dispatch(setPhoneChanged(false));
        }}
        handleOtp={(num) => setPhoneOtp(num)}
        otp={phoneOtp}
        seconds={seconds}
        setSeconds={(second) => setSeconds(second)}
        user={user}
      />
    </>
  );
};

export default Navbar;
