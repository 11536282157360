// import React, { useEffect } from 'react';
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./Icons.css";
import "./pace.css";
import Layouts from "./components/layouts/Layouts";
import MainRoutes from "./routes/MainRoutes";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { changeNavbarColor, readStaticData } from "./api/slices/globalSlice";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import BrandOffers from "./pages/BrandOffers";
import "react-phone-input-2/lib/style.css";
import { getUser } from "./Utils/auth.util";
import { autoExpire, setUser } from "./api/slices/authSlice";
import { getFcmToken } from "./services/notification-service";
import { viewedNotifications } from "./api/slices/webSlice";
import "aos/dist/aos.css";
import Aos from "aos";

// import "./dashboard/assets/plugins/simplebar/css/simplebar.css"
// import "./dashboard/assets/plugins/perfect-scrollbar/css/perfect-scrollbar.css"
// import "./dashboard/assets/plugins/simplebar/css/simplebar.css"
// import "./dashboard/assets/plugins/metismenu/css/metisMenu.min.css"
// import "./dashboard/assets/plugins/datatable/css/dataTables.bootstrap5.min.css"

// import { changeEmail, fetchTodos } from './api/slices/profile'
// import { useDispatch, useSelector } from 'react-redux';
// import { TStore } from './api/store';
function App() {
  const dispatch = useDispatch();
  // const { email } = useSelector((state: TStore) => state.profileReducer);
  // const { list, loading } = useSelector((state: TStore) => state.profileReducer);
  dispatch(readStaticData());
  // const handleEmailChange = () => {
  //   let email: string = 'ahsan.ak943@gmail.com'
  //   dispatch(changeEmail({ email: email }))
  //   dispatch(fetchTodos())
  // };
  // useEffect(() => {
  //   let result = localStorage.getItem("userBE");
  //   console.log("RESULT", typeof  JSON.parse(result)
  //   dispatch(changeNavbarColor("#ECF5FF"))
  // }, [dispatch]);
  useEffect(() => {
    let result = localStorage.getItem("userBE");
    if (result) {
      let user = JSON.parse(result);
      dispatch(setUser(user));
      dispatch(autoExpire());
    }
    getFcmToken();
  }, [dispatch]);

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        // position="top-center"
        autoClose={8000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        // theme="dark"
      />

      <Layouts>
        <MainRoutes />
      </Layouts>

      {/* Email is here : {email}
      <button onClick={handleEmailChange}>Click Me</button> */}
    </div>
  );
}

export default App;
