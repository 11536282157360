import women from "../../../../statics/slider.png";
import "./mainSlider.css";
export default function MainSlider() {
  return (
    <>
      <div className="container-fluid" style={{ backgroundColor: "#ECF5FF" }}>
        <div className="row main-slider-row">
          <div className="col-lg-6 col-md-6 col-6 col-xs-6 m-auto branded-head">
            <span className="be-branded">BE</span> <br />
            <span className="be-branded-brand">BRANDED</span>
            <p className="be-branded-para proxima-font">
              If you know what you want, but don’t know where or how to find it.
              Brand Emirates is the perfect platform for you.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-6 col-xs-6 d-flex justify-content-start p-0 m-0">
            <img src={women} width="100%" height="100%" />
          </div>
        </div>
      </div>
    </>
  );
}
