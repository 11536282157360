import Slider from "react-slick";
import { Puff } from "react-loader-spinner";

const SelectBrand = ({ brands, loading, onPressBrand }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "10px",
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          className: "center",
          centerMode: true,
          infinite: false,
          centerPadding: "50px",
          slidesToShow: 1,
          speed: 500,
          rows: 1,
          slidesPerRow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          centerPadding: "0",
          infinite: false,
          autoplay: false,
          dots: false,
          slidesToShow: 1,
          initialSlide: 1,
          slidesPerRow: 3,
        },
      },
    ],
  };
  return (
    <div
      className="mx-1 brand-offer-select-brand"
      // style={{ width: "80%", height: "350px" }}
    >
      {loading ? (
        <div className="d-flex justify-content-center p-2">
          <Puff color="#000" height={30} width={60} />
        </div>
      ) : brands?.length > 0 ? (
        <>
          <h4
            className="text-center pb-lg-5 pb-2"
            style={{ color: "#F4A950", textDecoration: "underline" }}
          >
            Select Your Favourite Brand
          </h4>
          <div className="guess-slider-div" style={{ width: "100%" }}>
            <Slider {...settings} className="w-100">
              {brands?.map((v) => (
                <div
                  onClick={() =>
                    onPressBrand({
                      brand_uid: v?.uid,
                      display_picture: v?.display_picture,
                      prize: v?.buy_prize?.name,
                      prizeImg: v?.buy_prize?.display_picture,
                    })
                  }
                  className="guess-slider"
                >
                  <img
                    src={v?.display_picture}
                    alt=""
                    // style={{ width: "100%", height: "120px" }}
                    className="cursor-pointer guess-slider-img "
                  />
                </div>
              ))}
              {/* <div
                // onClick={() =>
                //   onPressBrand({
                //     brand_uid: v?.uid,
                //     display_picture: v?.display_picture,
                //     prize: v?.buy_prize?.name,
                //     prizeImg: v?.buy_prize?.display_picture,
                //   })
                // }
                className="guess-slider"
              >
                <img
                  src={logo}
                  alt=""
                  // style={{ width: "100%", height: "120px" }}
                  className="cursor-pointer guess-slider-img "
                />
              </div>
              <div
                // onClick={() =>
                //   onPressBrand({
                //     brand_uid: v?.uid,
                //     display_picture: v?.display_picture,
                //     prize: v?.buy_prize?.name,
                //     prizeImg: v?.buy_prize?.display_picture,
                //   })
                // }
                className="guess-slider"
              >
                <img
                  src={logo}
                  alt=""
                  // style={{ width: "100%", height: "120px" }}
                  className="cursor-pointer guess-slider-img "
                />
              </div>
              <div
                // onClick={() =>
                //   onPressBrand({
                //     brand_uid: v?.uid,
                //     display_picture: v?.display_picture,
                //     prize: v?.buy_prize?.name,
                //     prizeImg: v?.buy_prize?.display_picture,
                //   })
                // }
                className="guess-slider"
              >
                <img
                  src={logo}
                  alt=""
                  // style={{ width: "100%", height: "120px" }}
                  className="cursor-pointer guess-slider-img "
                />
              </div> */}
            </Slider>
          </div>
        </>
      ) : (
        <p
          className="text-center"
          style={{ color: "#F4A950", textDecoration: "underline" }}
        >
          Oops No Brand Availale for this game!
        </p>
      )}
    </div>
  );
};
export default SelectBrand;
