/*eslint-disable*/
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";

const initialState = {
  brand: {},
  brandFreak: {},
  sponsor: {},
  loading: false,
  brandFreakInfoOne: {},
  brandStageOne: {},
  brandStageTwo: {},
  brandStageThree: {},
  brandStageFour: {},
  productType: [],
  registeringBrand: null,
};

export const registerBrandFreak = createAsyncThunk(
  "registerBrandFreak",
  async (data, thunkAPI) => {
    const res = await apiServices.RegisterBrandFreak(data);
    if (res?.data?.response_code === 201) {
      toast.success(res?.data?.response_message);
      thunkAPI.dispatch(setRegisteringBrand(res?.data?.response_data?.user));
      return true;
    } else {
      toast.info(res?.data?.response_message);
    }
  }
);

export const registerBrand = createAsyncThunk(
  "registerBrand",
  async (data, thunkAPI) => {
    const res = await apiServices.RegisterBrand(data);
    if (res?.data?.response_code === 201) {
      thunkAPI.dispatch(setRegisteringBrand(res?.data?.response_data?.user));
      toast.success(res?.data?.response_message);
      return true;
    } else {
      toast.info(res?.data?.response_message);
    }
  }
);

export const uploadBrandImage = createAsyncThunk(
  "uploadBrandImage",
  async (data) => {
    const res = await apiServices.uploadImage(data);
    if (res?.data?.response_code === 201) {
      toast.success(res?.data?.response_message);
    } else {
      toast.info(res?.data?.response_message);
    }
  }
);
export const readProductType = createAsyncThunk("getProductType", async () => {
  const res = await apiServices.readProductTypes();
  return res?.data?.response_data;
});
export const sponsorRegistration = createAsyncThunk(
  "registerSponsor",
  async (data) => {
    const res = await apiServices.sponsorReqistration(data);
    if (res?.data?.response_code === 201) {
      toast.success(res?.data?.response_message);
    } else {
      toast.info(res?.data?.response_message);
    }
  }
);

const registrationSLice = createSlice({
  name: "Profile Slice",
  initialState: initialState,
  reducers: {
    setRegisteringBrand: (state, action) => {
      state.registeringBrand = action.payload;
    },
    changeEmail: (state, payload) => {
      state.email = payload.email;
    },
    saveBrandFreakRegistrationInfo: (state, { payload }) => {
      state.brandFreakInfoOne = payload;
    },
    saveBrandRegistrationStage1: (state, { payload }) => {
      state.brandStageOne = payload;
    },
    saveBrandRegistrationStage2: (state, { payload }) => {
      state.brandStageTwo = payload;
    },
    saveBrandRegistrationStage3: (state, { payload }) => {
      state.brandStageThree = payload;
    },
    saveBrandRegistrationStage4: (state, { payload }) => {
      state.brandStageFour = payload;
    },
  },
  extraReducers: {
    [registerBrandFreak.pending]: (state, action) => {
      state.loading = true;
    },
    [registerBrandFreak.fulfilled]: (state, action) => {
      state.brandFreak = action.payload;
      state.loading = false;
    },
    [registerBrandFreak.rejected]: (state, action) => {
      state.loading = false;
    },
    [registerBrand.pending]: (state, action) => {
      state.loading = true;
    },
    [registerBrand.fulfilled]: (state, action) => {
      state.brand = action.payload;
      state.loading = false;
    },
    [registerBrand.rejected]: (state, action) => {
      state.loading = false;
    },
    [sponsorRegistration.pending]: (state, action) => {
      state.loading = true;
    },
    [sponsorRegistration.fulfilled]: (state, action) => {
      state.sponsor = action.payload;
      state.loading = false;
    },
    [sponsorRegistration.rejected]: (state, action) => {
      state.loading = false;
    },
    [readProductType.pending]: (state, action) => {},
    [readProductType.fulfilled]: (state, action) => {
      state.productType = action.payload;
    },
    [readProductType.pending]: (state, action) => {},
  },
});

export const registrationReducer = registrationSLice.reducer;
export const {
  changeEmail,
  saveBrandFreakRegistrationInfo,
  saveBrandRegistrationStage1,
  saveBrandRegistrationStage2,
  saveBrandRegistrationStage3,
  saveBrandRegistrationStage4,
  setRegisteringBrand,
} = registrationSLice.actions;
