import React from "react";
import Slider from "react-slick";
import trending from "../../../statics/trending.png";
import logo from "../../../statics/off.png";
import { capitalize } from "../../../Utils/functions";

const FilterBrandSlider = ({ offers, getSelectedOffer, selectedOffer }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "15px",
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 3,
    arrows: false,
  };

  return (
    <Slider {...settings}>
      {offers?.map((v) => (
        <img
          style={{ backgroundColor: "red" }}
          onClick={() => getSelectedOffer(v)}
          src={v?.display_picture}
          alt=""
          className={
            v?.uid === selectedOffer
              ? "filter-selected-logo cursor-pointer"
              : "filter-logo-slider cursor-pointer"
          }
        />
      ))}
    </Slider>
  );
};
export default FilterBrandSlider;
