import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import location from "../statics/location.png";
import phone from "../statics/phone.png";
import email from "../statics/email.png";
import { changeNavbarColor } from "../api/slices/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { sendMessage } from "../api/slices/webSlice";
import { Puff } from "react-loader-spinner";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";
import { inquiryTypeArray } from "../../src/config";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const [inquiryType, setInquiryType] = useState(null);
  const [error, setErrors] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation("common");
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeNavbarColor("#fff"));
  }, [dispatch]);

  const onSubmit = async (data) => {
    if (!inquiryType) {
      setErrors({
        // ...errors,
        inquiry: { message: "Please Select Inquiry Type" },
        // phNumber: { message: "Please Enter Your Phone Number" },
      });
    }

    if (!phoneNumber) {
      setErrors({
        // ...errors,
        // inquiry: { message: "Please Select Inquiry Type" },
        phNumber: { message: "Please Enter Your Phone Number" },
      });
    }

    if (inquiryType && phoneNumber) {
      let apiData = {
        ...data,
        name: data?.first_name + " " + data?.last_name,
        inquiry_type: inquiryType,
        phone_number: phoneNumber,
      };
      let response = await dispatch(sendMessage(apiData));
      if (response?.payload) {
        reset();
        setErrors(null);
        setInquiryType(null);
        setPhoneNumber(null);
      }
    }
  };
  const { contact_loading } = useSelector((state) => state.WebSlice);

  const inquiryTypeOptions = inquiryTypeArray?.map((v) => ({
    label: v?.Label,
    value: v?.value,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 59,
      minHeight: 55,
      border: "2px solid gray",
      borderRadius: "none",
      boxShadow: "none",
      "&:hover": {
        border: "2px solid black",
      },
    }),
  };
  return (
    <>
      <Helmet>
        <title>{t("contact.maintitle")}</title>
      </Helmet>
      <div
        className="container-fluid mt-0 px-5 py-3"
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <span
                className="head-featured "
                style={{ fontSize: "40px", fontWeight: 500 }}
              >
                CONTACT
                <span
                  className="head-brand-green"
                  style={{ fontSize: "40px", fontWeight: 500 }}
                >
                  {" "}
                  US
                </span>
              </span>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12 text-center">
              <span className="head-featured " style={{ fontSize: "25px" }}>
                GET IN
                <span className="head-brand-green" style={{ fontSize: "25px" }}>
                  {" "}
                  TOUCH
                </span>
                <span className="head-featured " style={{ fontSize: "25px" }}>
                  {" "}
                  WITH US
                </span>
              </span>
              <p
                style={{ fontWeight: 300, color: "#6F7073", fontSize: "15px" }}
              >
                Home Some Questions? We're all ears
              </p>
            </div>
          </div>
          <div className="row mt-4 proxima-font">
            <div className="col-lg-4">
              <div className="d-flex justify-content-center">
                <img src={location} height="70px" width="70px" />
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <span>Suit 203 Citadel Tower, Business Bay</span>
              </div>
              <div className="d-flex justify-content-center">
                <span>Dubai - United Arab Emirates</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex justify-content-center">
                <img src={phone} height="70px" width="70px" />
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <span>+971-589896418 (Landline)</span>
              </div>
              <div className="d-flex justify-content-center">
                <span>+971502076024 (Mobile Phone)</span>
              </div>
              <div className="d-flex justify-content-center">
                <span>4504545440 (FAX)</span>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex justify-content-center">
                <img src={email} height="70px" width="70px" />
              </div>
              <br />
              <div className="d-flex justify-content-center">
                <span>www.brandemirates.com</span>
              </div>

              <div className="d-flex justify-content-center">
                <span>info@brandemirates.com</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid  my-0 p-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14442.276020816706!2d55.2608884!3d25.1840268!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9ad7e353822dc797!2sThe%20Citadel%20Tower!5e0!3m2!1sen!2s!4v1669708027634!5m2!1sen!2s"
          width="100%"
          height="350"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div
        className="container-fluid py-4"
        //  style={{ background: "#ECF5FF" }}
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container mb-5">
          <div className="row">
            <div className="col-12 text-center">
              <span className="head-featured ">
                SEND
                <span className="head-brand-green"> MESSAGE</span>
              </span>
            </div>
            <div className="col-lg-3"></div>
            <div className="col-lg-6 mt-3 ">
              <div className="contact-form-touch">
                <div className="contact-form-getin mt-35">
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    id="contact_us_form"
                    autoComplete="off"
                  >
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="fullname" id="fullname-label">
                            First Name
                          </label>
                          <input
                            {...register("first_name", { required: true })}
                            type="text"
                            name="first_name"
                            id="first_name"
                            placeholder="First Name"
                            required="required"
                          />
                          {errors.first_name && (
                            <span style={{ color: "red" }}>
                              First Name is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name" id="email-label">
                            Last Name
                          </label>
                          <input
                            {...register("last_name", { required: true })}
                            type="text"
                            name="last_name"
                            id="last_name"
                            placeholder="Last Name"
                            required="required"
                          />
                          {errors.last_name && (
                            <span style={{ color: "red" }}>
                              Last Name is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="name" id="email-label">
                            Phone Number
                          </label>
                          <PhoneInput
                            // className="ml-1 "
                            name="phNumber"
                            country={"ae"}
                            onChange={(number) => setPhoneNumber(number)}
                            // value={phoneNumber}
                            // defaultValue={phoneNumber}
                            className="text-dark"
                            defaultErrorMessage="Eoerasd here"
                          />
                          {/* <input
                            {...register("phone_number", { required: true })}
                            type="number"
                            name="phone_number"
                            id="phone_number"
                            placeholder="Phone Number"
                            required="required"
                          /> */}
                          {error?.phNumber && (
                            <p className="error-message">
                              {error?.phNumber?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmtlFor="name" id="email-label">
                            Inquiry Type
                          </label>
                          <Select
                            name="inquiry"
                            className="mt-2 text-secondary"
                            onChange={(v) => setInquiryType(v?.value)}
                            // value={{ id: inquiryType, label: inquiryType }}
                            options={inquiryTypeOptions}
                            styles={customStyles}
                            // placeholder={
                            //   inquiryType === null && "Select Inquiry Type"
                            // }
                            placeholder={"Select Inquiry Type"}
                            theme={(theme) => ({
                              ...theme,
                              colors: {
                                ...theme.colors,
                                neutral50: "grey", // Placeholder color
                              },
                            })}
                          />
                          {error?.inquiry && (
                            <p className="error-message">
                              {error?.inquiry?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="fullname" id="fullname-label">
                            Email Address
                          </label>
                          <input
                            {...register("email_address", { required: true })}
                            type="email"
                            name="email_address"
                            id="email_address"
                            placeholder="Your Email Address"
                            required
                          />
                          {errors.email_address && (
                            <span style={{ color: "red" }}>
                              Email is Required
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="form-group">
                          <label htmlFor="messages" id="messages-label">
                            Message
                          </label>
                          <textarea
                            {...register("message", { required: true })}
                            name="message"
                            id="message"
                            placeholder="Write Your Message"
                            required
                            defaultValue={""}
                          />
                          {errors.message && (
                            <span style={{ color: "red" }}>
                              Message is Required
                            </span>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-lg-12 contact_us_alert"
                        style={{ display: "none" }}
                      ></div>
                      <div className="col-lg-12 text-center">
                        {contact_loading ? (
                          <div className="d-flex justify-content-center p-2">
                            <Puff color="#000" height={30} width={60} />
                          </div>
                        ) : (
                          <button
                            type="submit"
                            className="contact-button "
                            style={{ marginRight: "5px", cursor: "pointer" }}
                          >
                            Send
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-3"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
