import React from "react";
import styles from "./testamonialSLider.css";
import Slider from "react-slick";
import userPlaceholder from "../../../statics/userPlaceholder.png";
const ClientTestimonials = ({ data, onPressView }) => {
  function Arrow(props) {
    let className = props.type === "next" ? "nextArrow" : "prevArrow";
    className += " arrow";
    const char =
      props.type === "next" ? (
        <i className="fa fa-chevron-right"></i>
      ) : (
        <i className="fa fa-chevron-left"></i>
      );
    return (
      <spanOur
        className={`${className}`}
        style={{ ...styles }}
        onClick={props.onClick}
      >
        {char}
      </spanOur>
    );
  }

  var settings = {
    speed: 1000,
    autoplaySpeed: 2000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    infinite: true,
    autoplay: false,
    dots: true,
    slidesToShow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 1000,
          autoplaySpeed: 2000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: false,
          dots: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 1000,
          autoplaySpeed: 2000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: false,
          dots: true,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 2000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: false,
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="container ">
      <div className={`mb-3 py-1 st_testimonial st_style1 `}>
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div key={index} className="row d-flex justify-content-center mx-2">
              <div className="col-lg-6 col-md-12 col-sm-12 py-4">
                <div className="st_testimonial st_style1 st_testimonial top-box-testamonial">
                  <div className={"st_quote p-5"}>
                    <i className="fas fa-quote-left"></i>
                  </div>
                  <div className={"st_testimonial_info"}>
                    <div className={"st_testimonial_img"}>
                      <img
                        className="rounded-circle"
                        src={
                          item?.customer?.display_picture &&
                          item?.customer?.display_picture !== " "
                            ? item?.customer?.display_picture
                            : userPlaceholder
                        }
                        alt={item?.customer?.name}
                      />
                    </div>
                  </div>
                  <div className={styles.st_testimonial_meta}>
                    <h4 className={"st_testimonial_name m-2"}>
                      {item?.customer?.name}
                    </h4>
                  </div>
                  <div className={"st_testimonial_text"}>
                    {item?.rating?.review}
                  </div>
                  <div className={styles.st_testimonial_meta}>
                    <h4
                      className={"st_testimonial_name m-2 cursor-pointer"}
                      onClick={() => onPressView(item)}
                    >
                      {item?.product?.name}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientTestimonials;
