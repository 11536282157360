/*eslint-disable*/
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiServices from "../../services/requestHandler";

const initialState = {
  navbarColor: null,
  staticData: {},
  displayNavbar:true,
  displaySidebar: false,

};
export const readStaticData = createAsyncThunk("getTodos", async () => {
  const res = await apiServices.readStaticData();
  return res?.data?.response_data;
});

const slice = createSlice({
  name: "Profile Slice",
  initialState: initialState,
  reducers: {
    changeNavbarColor: (state, action) => {
      state.navbarColor = action.payload;
    },
    toggleNavbar: (state, action) => {
      state.displayNavbar = action.payload;
    },
    toggleSidebar: (state, action) => { 
      state.displaySidebar = action.payload;
    },
  },
  extraReducers: {
    [readStaticData.pending]: (state, action) => {},
    [readStaticData.fulfilled]: (state, action) => {
      state.staticData = action.payload;
    },
    [readStaticData.pending]: (state, action) => {},
  },
});

export const globalSlice = slice.reducer;
export const { changeNavbarColor ,toggleNavbar, toggleSidebar} = slice.actions;
