import React from "react";
import "./clientSlider.css";
import Slider from "react-slick";
const ClientSlider = ({ data }) => {
  var settings = {
    speed: 3000,
    autoplaySpeed: 5000,
    lazyLoad: "progressive",
    transitionDuration: 3000,
    infinite: true,
    autoplay: true,
    dots: false,
    slidesToShow: data.length,
    slidesToScroll: 1,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div className="container" style={{ overflowX: "hidden" }}>
      <div className="py-4 clientSliderParent col-12">
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div
              className="client-card center-childrens mx-2 p-4"
              key={index + 1}
            >
              <img
                src={item?.display_picture}
                alt="Client Slider"
                style={{ width: "150px", height: "80px", cursor: "pointer" }}
                onClick={() => window.open(item?.url, "_blank")}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default ClientSlider;
