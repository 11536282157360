import React, { useState, useEffect } from "react";
import brand from "../statics/comingsoon/brand.jpg";
import brandfreak from "../statics/comingsoon/brand-freak.jpg";
import sponsors from "../statics/comingsoon/sponsors.jpg";
import logo from "../statics/comingsoon/logo.png";
import { useNavigate } from "react-router-dom";
import { toggleNavbar } from "../api/slices/globalSlice";
import { useDispatch } from "react-redux";

export default function UserType() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleNavbar(false));

    return () => {
      dispatch(toggleNavbar(true));
    };
  }, []);
  let tagname = null;
  let tagName = null;

  const navigate = useNavigate();
  return (
    <div className="container-fluid user-type-back">
      <div className="row text-center no-gutters mx-3 py-3">
        <div className="col-lg-4">
          <div
            className="brand-parent"
            onClick={() => {
              if (!tagName) {
                navigate("/brand-register");
              }
            }}
          >
            <img className="" src={brand} height="100%" width="100%" />
            <div className="brand-opacity-child"></div>

            <img
              src={logo}
              onClick={(e) => {
                tagName = e.target.tagName;
                navigate("/");
              }}
              className="brand-child"
              style={{ cursor: "pointer" }}
              width={"148px"}
              height={"46px"}
            />
            <span
              className="brand-child-text text-white  "
              style={{ fontFamily: "Athelas bold", fontSize: "85px" }}
            >
              Brand
            </span>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="brand-parent"
            onClick={(e) => {
              navigate("/brand-freak-register");
            }}
          >
            <img src={brandfreak} height="100%" width="100%" />
            <div className="brand-opacity-child"></div>

            <span
              className="brand-child-text text-white  "
              style={{ fontFamily: "Athelas bold", fontSize: "85px" }}
            >
              Brand Freak
            </span>
          </div>
        </div>
        <div className="col-lg-4">
          <div
            className="brand-parent"
            onClick={(e) => {
              if (!tagname) {
                navigate("/sponor-register");
              }
            }}
          >
            <img src={sponsors} height="100%" width="100%" />
            <div className="brand-opacity-child"></div>
            <span
              className="brand-child-button"
              onClick={(e) => {
                tagname = e.target.tagName;
                navigate("/");
              }}
            >
              <i
                className="fas fa-less-than mr-2"
                style={{ fontSize: "20px" }}
              ></i>{" "}
              Go Back
            </span>
            <span
              className="brand-child-text text-white  "
              style={{ fontFamily: "Athelas bold", fontSize: "85px" }}
            >
              Sponsors
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
