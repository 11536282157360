import Slider from "react-slick";
import { capitalize } from "../../../Utils/functions";

const OffersSlider = ({ offers, onPressItem }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: false,
    centerPadding: "35px",
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          centerPadding: "0",
          infinite: false,
          autoplay: false,
          dots: false,
          slidesToShow: 1,
          initialSlide: 1,
          slidesPerRow: 2,
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {offers?.map((v) => (
          <div
            onClick={() => onPressItem(v)}
            className="offer-slider-image-div"
          >
            {/* <div className="col-6"> */}
            <img
              src={v?.brand?.display_picture}
              alt=""
              className="offer-slider-logo col-4 px-1 pt-1"
            />
            {/* </div> */}
            <img className="offer-slider-image" src={v?.image[0]} alt="" />
            <h6
              className="offer-slider-image-name d-flex align-center justify-content-center text-center mx-1 raleway-font mt-2"
              // style={{ height: "50px" }}
            >
              {capitalize(v?.name)}
            </h6>
            <h6 className="d-flex align-center justify-content-end quicksand-font font-weight-bold">
              {`AED : ${
                v?.discounted_price !== 0
                  ? Math.round(v?.discounted_price)
                  : Math.round(v?.price)
              }`}
            </h6>
          </div>
        ))}
      </Slider>
      {offers && (
        <h4
          className=" d-flex justify-content-end proxima-font font-weight-bold"
          style={{ color: "#f4a950" }}
        >{`${offers?.length} Products Found`}</h4>
      )}
    </>
  );
};
export default OffersSlider;
