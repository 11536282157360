import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getGuessBrands,
  guessAndWin,
  readQuestions,
} from "../../api/slices/webSlice";
import { Question, SelectBrand } from "./components";

const GuessAndWin = () => {
  const { state } = useLocation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startGame, setStartGame] = useState(false);
  const [isBrandSeletced, setIsBrandSelected] = useState(false);
  const [currentQuestion, setQuestionIndex] = useState(0);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [selectedKey, setSelectedKey] = useState(null);
  const [brandPicture, setBrandPicture] = useState(
    state?.display_picture ? state?.display_picture : null
  );
  const [uid, setUid] = useState(null);
  const [selectedKeysArray, setSelectedKeyArray] = useState([]);
  const [isHover, setIsHover] = useState(false);
  const [prize, setPrize] = useState(state?.prize ? state?.prize : null);
  const [prizeImg, setPrizeImg] = useState(
    state?.prizeImg ? state?.prizeImg : null
  );

  const {
    guess_brands_loading,
    questions_loading,
    questions,
    guess_brands,
    guess_win_loading,
  } = useSelector((state) => state.WebSlice);

  useEffect(() => {
    if (state) {
      let apiData = {
        brand: state?.brand_uid,
        goBack: () => navigate("/"),
      };
      setUid(state?.brand_uid);
      dispatch(readQuestions(apiData));
      setIsBrandSelected(true);
    } else {
      dispatch(getGuessBrands());
    }
  }, [state]);
  return (
    <div className="container my-4 p-lg-5 p-1 guess-win">
      <>
        <div className="row">
          <div className="col-lg-4  ">
            <button
              className=""
              style={{
                background: "transparent",
                padding: "5px 15px",
                border: "0px",
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <span>
                {"<"} <span className="px-2">Back</span>
              </span>
            </button>
          </div>
          <div className="col-lg-4 d-flex flex-row justify-content-center">
            <h3 className="guess-win-heading ">
              GUESS&nbsp;
              <span className="guess-win-heading-green">& WIN</span>
            </h3>
          </div>
        </div>
        <div
          className="row pt-5"
          style={{ display: "flex", justifyContent: "center" }}
        >
          {isBrandSeletced && (
            <Question
              brandPicture={brandPicture}
              prize={prize}
              prizeImg={prizeImg}
              data={questions}
              questionsLoading={questions_loading}
              submisionLoading={guess_win_loading}
              currentQuestion={currentQuestion}
              selectedOptionIndex={selectedOptionIndex}
              onPressOption={(key, index) => {
                setSelectedKey(key);
                setSelectedOptionIndex(index);
              }}
              onPressSubmit={() => {
                if (questions?.length - 1 == currentQuestion) {
                  let newArray = [...selectedKeysArray, selectedKey];
                  const data = {
                    apiData: {
                      brand: uid,
                      choice: newArray,
                    },
                    goBack: () => {
                      navigate("/");
                      setStartGame(false);
                      setIsBrandSelected(false);
                    },
                  };
                  dispatch(guessAndWin(data));
                } else {
                  setSelectedKeyArray([...selectedKeysArray, selectedKey]);
                  setQuestionIndex(currentQuestion + 1);
                  setSelectedOptionIndex(null);
                  setSelectedKey(null);
                }
              }}
            />
          )}
        </div>
      </>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {!isBrandSeletced && (
          <SelectBrand
            onPressBrand={(data) => {
              setBrandPicture(data?.display_picture);
              setPrize(data?.prize);
              setPrizeImg(data?.prizeImg);
              let apiData = {
                brand: data?.brand_uid,
                goBack: () => navigate("/"),
              };
              setIsBrandSelected(true);
              setUid(data?.brand_uid);
              dispatch(readQuestions(apiData));
            }}
            loading={guess_brands_loading}
            brands={guess_brands}
          />
        )}
      </div>
    </div>
  );
};

export default GuessAndWin;
