import { useSelector } from "react-redux";
import Footer from "./footer/Footer";
import Navbar from "./navbar/Navbar";

const Layouts = ({ children }) => {
  const { displayNavbar, displaySidebar } = useSelector(
    (state) => state.globalSlice
  );
  return (
    <>
      {/* {displayNavbar ? <Navbar /> : null} */}
      {!displaySidebar && children}
      {/* {displaySidebar ? <Sidebar> <Header/> {children} </Sidebar> : null} */}
      {/* {displayNavbar ? <Footer /> : null} */}
      {/* <h1 className='text-center mt-5 text-danger'>This Is Footer Section</h1> */}
    </>
  );
};

export default Layouts;
