import { Icon } from "@iconify/react";
import locationIcon from "@iconify/icons-mdi/map-marker";

const LocationPin = ({ storeName }) => (
  <div className="pin">
    {/* <h6 className="store-name-marker">{storeName}</h6> */}
    <Icon icon={locationIcon} className="pin-icon" />
  </div>
);

export default LocationPin;
