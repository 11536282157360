import { globalSlice } from "../slices/globalSlice";
import { registrationReducer } from "../slices/registrationSlice";
import {WebSlice} from '../slices/webSlice'
import {AuthSlice} from '../slices/authSlice'
const reducer: any = {
    globalSlice,
    registrationReducer,
    WebSlice,
    AuthSlice
    // add other reducers here

}
export default reducer