import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  getRateBrands,
  rateAndWin,
  readBrands,
} from "../../../api/slices/webSlice";

import ReactStars from "react-rating-stars-component";
import { Loader } from "../../common";

export default function RateAndWin({ show, onHide, uid = null }) {
  const [error, setError] = useState(null);
  const { user } = useSelector((state) => state.AuthSlice);

  const [brand, setBrand] = useState(null);
  const [rating, setRating] = useState(null);
  const [review, setReview] = useState(null);

  const [prod, setProd] = useState(null);

  const dispatch = useDispatch();
  const { brands, loading, rate_brands_loading, rate_brands } = useSelector(
    (state) => state.WebSlice
  );
  useEffect(() => {
    if (show && !uid) {
      dispatch(getRateBrands());
      dispatch(readBrands());
    } else if (show && uid) {
      setBrand(uid);
    } else {
      setBrand(null);
      setReview(null);
      setError(null);
    }
  }, [show]);

  const clearAllGameData = () => {
    setBrand(null);
    setProd(null);
    setRating(null);
    setReview(null);
    onHide();
  };

  const onSubmit = async () => {
    if (user) {
      if (!brand) {
        setError({
          brand: { message: "Please select brand" },
        });
        return;
      }
      if (!prod) {
        setError({
          product: { message: "Please select product" },
        });
        return;
      }
      if (!rating) {
        setError({
          rating: { message: "Please rate this product" },
        });
        return;
      }
      let data = {
        apiData: {
          product: prod?.value,
          brand: uid ? brand : brand?.value,
          rating: rating,
          review: review,
        },
        clearState: () => {
          clearAllGameData();
        },
      };
      dispatch(rateAndWin(data));
    } else {
      toast.info("Please login to play this game");
    }
  };
  let array = !uid ? rate_brands : brands;
  const filterProd = array?.filter((item) =>
    uid ? item?.uid == uid : item?.uid == brand?.value
  );
  const getPrizeObject = () => {
    if (uid) {
      const selectedBrand = brands?.filter((item) => item?.uid == uid);
      return {
        label: selectedBrand[0]?.name,
        value: selectedBrand[0]?.uid,
        gameName: selectedBrand[0]?.buy_prize?.name,
        gameImg: selectedBrand[0]?.buy_prize?.display_picture,
      };
    }
    return;
  };
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#000", color: "#fff" }}>
        <Modal.Title id="contained-modal-title-vcenter">
          Enroll yourself for Lucky Draw
        </Modal.Title>
        <button type="button" className="close" onClick={clearAllGameData}>
          <span aria-hidden="true" className="text-white  ">
            ×
          </span>
          <span className="sr-only"></span>
        </button>
      </Modal.Header>

      {rate_brands_loading ? (
        <Loader />
      ) : (
        <Modal.Body>
          {!uid && (
            <div className="row mx-1">
              <div className="col-lg-12">
                <label className="modal-label-buy">Choose Brand</label>
                <Select
                  required={true}
                  onChange={(val) => setBrand(val)}
                  options={rate_brands?.map((item, idx) => {
                    return {
                      label: item?.name,
                      value: item?.uid,
                      gameName: item?.buy_prize?.name,
                      gameImg: item?.buy_prize?.display_picture,
                      key: idx,
                    };
                  })}
                />
                {brand && brand?.gameName && brand?.gameImg && (
                  <div className="d-flex flex-row">
                    <div className="col-md-3"></div>
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                      <h6
                        className="mt-3 raleway-font"
                        style={{ color: "#f4a950" }}
                      >
                        {`Prize: ${brand?.gameName}`}
                      </h6>
                      <img
                        src={brand?.gameImg}
                        alt=""
                        height={100}
                        width={100}
                      />
                    </div>
                    <div className="col-md-3"></div>
                  </div>
                )}
                {error?.brand ? (
                  <p className="text-danger">{error?.brand?.message}</p>
                ) : null}
              </div>
            </div>
          )}
          {brand && (
            <div className="row mx-1">
              <div className="col-lg-12">
                <label className="modal-label-buy">Choose Product</label>
                <Select
                  required={true}
                  onChange={(val) => setProd(val)}
                  options={filterProd[0]?.product?.map((item, idx) => {
                    return {
                      label: item?.name,
                      value: item?.uid,
                      key: idx,
                    };
                  })}
                />
                {error?.product ? (
                  <p className="text-danger">{error?.product?.message}</p>
                ) : null}
              </div>
            </div>
          )}
          {Object.keys(getPrizeObject()?.length > 0) &&
            uid &&
            getPrizeObject()?.gameName &&
            getPrizeObject()?.gameImg && (
              <div className="d-flex flex-row">
                <div className="col-md-3"></div>
                <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                  <h6
                    className="mt-3 raleway-font"
                    style={{ color: "#f4a950" }}
                  >{`Prize: ${getPrizeObject()?.gameName}`}</h6>
                  <img
                    src={getPrizeObject()?.gameImg}
                    alt=""
                    height={100}
                    width={100}
                  />
                </div>
                <div className="col-md-3"></div>
              </div>
            )}
          <div className="row mt-2 mx-1">
            <div className="col-lg-12 text-center">
              <label className="modal-label-buy">
                Give this product a rating
              </label>

              <div className="d-flex justify-content-center flex-column align-items-center">
                <ReactStars
                  count={5}
                  onChange={(e) => setRating(e)}
                  size={24}
                  activeColor="#ffd700"
                />
                {error?.rating ? (
                  <p className="text-danger">{error?.rating?.message}</p>
                ) : null}
              </div>
            </div>
          </div>
          <div className="row mt-2 mx-1">
            <div className="col-lg-12 text-center">
              <label className="modal-label-buy">Write a Review</label>
              <br />
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="How much you like this product"
                className="w-100 p-3"
              />
            </div>
          </div>
          <div className="my-3">
            <div className="col-12">
              <div className="d-flex justify-content-center">
                {loading ? (
                  <div className="text-center">
                    <Puff color="#000" height={30} width={60} />
                  </div>
                ) : (
                  <button
                    className="home-brand-button px-3 py-2"
                    onClick={onSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}
