import React from "react";

const Entertainment = ({ games, onPressGuess, onPressBuy, onPressRate }) => {
  return (
    <div className="container ">
      <div className="row py-lg-5 py-3">
        {games?.map((item, idx) => (
          <div
            ke={idx}
            className="col-lg-4  mt-lg-0 mt-3 brand-entertainment-section"
          >
            <div
              className={
                item?.button === "Guess"
                  ? "card-prod p-3 "
                  : item?.button == "Rate"
                  ? "card-prod-linear p-3"
                  : "card-prod-linear2 p-3 "
              }
              style={{ backgroundColor: "#fff" }}
            >
              <div className="text-center">
                <img className="" src={item?.image} height="150px" />
              </div>

              <h2 className="mt-3 py-2 px-2">{item?.name}</h2>
              <p className="px-2">{item?.desc}</p>
              <div className="col-12 mx-auto d-flex justify-content-center">
                <button
                  onClick={() => {
                    if (item?.button == "Guess") {
                      onPressGuess();
                    } else if (item?.button === "Rate") {
                      onPressRate();
                    } else {
                      onPressBuy();
                    }
                  }}
                  className="ml-2 home-brand-button px-4 py-1 "
                  style={{ border: "0px", borderRadius: "0px" }}
                >
                  {item?.button}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Entertainment;
