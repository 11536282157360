import "../modal/css/locateStore.css";
import { Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import LocationPin from "../modal/helper/LocationPin";

export default function LocateStore({
  show,
  onHide,
  storeModalInfo,
  singleStoreModalInfo,
  setFocusOnLocation,
  selectedMarker,
}) {
  const location = {
    lat: singleStoreModalInfo
      ? singleStoreModalInfo?.store[0]?.location?.latitude
      : storeModalInfo?.store[0]?.location?.latitude,
    lng: singleStoreModalInfo
      ? singleStoreModalInfo?.store[0]?.location?.longitude
      : storeModalInfo?.store[0]?.location?.longitude,
  };
  return (
    <>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            Store Locator
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => {
              onHide();
              setFocusOnLocation(null);
            }}
          >
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="store-locator">
            <div className="store-names">
              <h5 className="static-store-name">Store Name</h5>
              {singleStoreModalInfo
                ? singleStoreModalInfo?.store.map((store) => {
                    return (
                      <div
                        className="locate-store-info"
                        onClick={() =>
                          setFocusOnLocation({
                            lat: store?.location?.latitude,
                            lng: store?.location?.longitude,
                          })
                        }
                      >
                        <h5>{store?.name}</h5>
                        <p>{store?.address}</p>
                      </div>
                    );
                  })
                : storeModalInfo?.store.map((store) => {
                    return (
                      <div
                        className="locate-store-info "
                        onClick={() =>
                          setFocusOnLocation({
                            lat: store?.location?.latitude,
                            lng: store?.location?.longitude,
                          })
                        }
                      >
                        <h5>{store?.name}</h5>
                        <p>{store?.address}</p>
                      </div>
                    );
                  })}
            </div>
            <div className="store-map-location">
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: "AIzaSyBZ0pReZFp3l70TVBfusA5PJXUqkyL6q5k",
                }}
                // defaultCenter={location}
                // defaultZoom={12}
                center={selectedMarker ? selectedMarker : location}
                zoom={selectedMarker ? 15 : 10}
              >
                {singleStoreModalInfo
                  ? singleStoreModalInfo?.store?.map((store) => {
                      return (
                        <LocationPin
                          lat={store?.location.latitude}
                          lng={store?.location.longitude}
                        />
                      );
                    })
                  : storeModalInfo?.store?.map((store) => {
                      return (
                        <LocationPin
                          lat={store?.location.latitude}
                          lng={store?.location.longitude}
                        />
                      );
                    })}
              </GoogleMapReact>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
