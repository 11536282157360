import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { saveUser, setToken, setUserRole } from "../../Utils/auth.util";

const initial_state = {
  user: null,
  loading: false,
  userRole: null,
  change_pass_loading: false,
  unverifiedUser: null,
  phoneChanged: false,
};

export const loginUser = createAsyncThunk(
  "Login/user",
  async (user, thunkAPI) => {
    try {
      const res =
        user?.mode == "Google"
          ? await apiServices.googleLogin(user)
          : user?.mode == "Facebook"
          ? await apiServices.facebookLogin(user)
          : await apiServices.login(user);
      if (res?.data?.response_code === 200) {
        console.log("res?.data?.response_data?", res?.data?.response_data);
        toast.success(res?.data?.response_message);
        setToken({
          key: res?.data?.response_data?.user?.role?.name,
          type: res?.data?.response_data?.token,
        });
        saveUser(res?.data?.response_data?.user);
        setUserRole(res?.data?.response_data?.user?.role);
        return res?.data?.response_data;
      } else if (res?.data?.response_code === 4004) {
        thunkAPI.dispatch(setUnverifiedUser(res?.data?.response_data?.user));
      } else {
        toast.error(res?.data?.response_message);
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const sendOtpMail = createAsyncThunk("sendOtpMail", async (data) => {
  try {
    const response = await apiServices.otpEmail(data);
    if (response?.data?.response_code == 200) {
      toast.info(response?.data?.response_message);
      return true;
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (e) {
    console.log("This is Error from send otp email", e);
  }
});
export const verifyBrandOtp = createAsyncThunk(
  "verifyBrandOtp",
  async (data) => {
    try {
      const response = await apiServices.verifyBrandOtp(data);
      if (response?.data?.response_code == 200) {
        toast.info(response?.data?.response_message);
        return true;
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from send otp email", e);
    }
  }
);
export const verifyOtpPhone = createAsyncThunk(
  "verifyOtpPhone",
  async (data) => {
    try {
      const response = await apiServices.verifyOtpPhone(data);
      if (response?.data?.response_code == 200) {
        toast.info(response?.data?.response_message);
        return true;
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from send otp email", e);
    }
  }
);
export const verifyPhoneNumber = createAsyncThunk(
  "verifyPhoneNumber",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.verifyPhoneNumber(data);
      if (response?.response_code == 200) {
        toast.info(response?.response_message);
        thunkAPI.dispatch(setUser(response?.response_data?.user));
        saveUser(response?.response_data?.user);
        thunkAPI.dispatch(setPhoneChanged(false));
        return true;
      } else {
        toast.error(response?.response_message);
      }
    } catch (e) {
      console.log("This is Error from send otp email", e);
    }
  }
);
export const resendBrandOtp = createAsyncThunk(
  "resendBrandOtp",
  async (data) => {
    try {
      const response = await apiServices.resendBrandOtp(data);
      if (response?.data?.response_code == 200) {
        toast.success(response?.data?.response_message);
        return true;
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from send otp email", e);
    }
  }
);
export const resendPhoneOtp = createAsyncThunk(
  "resendPhoneOtp",
  async (data) => {
    try {
      const response = await apiServices.resendPhoneOtp(data);
      if (response?.data?.response_code == 200) {
        toast.success(response?.data?.response_message);
        return true;
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from send otp email", e);
    }
  }
);
export const resetPassword = createAsyncThunk("resetPassword", async (data) => {
  try {
    const response = await apiServices.resetPassword(data);
    if (response?.data?.response_code == 200) {
      toast.success(response?.data?.response_message);
      return true;
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (e) {
    console.log("This is Error from send otp email", e);
  }
});

export const updateUser = createAsyncThunk(
  "update/user",
  async (user, thunkAPI) => {
    try {
      const res = await apiServices.updateProfile(user);
      console.log("res", res);
      if (res?.response_code === 200) {
        toast.success(res?.response_message);
        thunkAPI.dispatch(setUser(res?.response_data?.user));
        saveUser(res?.response_data?.user);
        // if (res?.response_data?.user?.phone_changed) {
        //   console.log("phone_changed phone_changed if working ");
        //   thunkAPI.dispatch(setPhoneChanged(true));
        // }
      } else if (res?.response_code === 201) {
        toast.success(res?.response_message);
        thunkAPI.dispatch(setUser(res?.response_data?.user));
        saveUser(res?.response_data?.user);
        // if (res?.response_data?.user?.phone_changed) {
        //   console.log("phone_changed phone_changed if working ");
        // }
        thunkAPI.dispatch(setPhoneChanged(true));
      } else {
        toast.info(res?.response_message);
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const removeDp = createAsyncThunk("removeDp", async (data, thunkAPI) => {
  try {
    const response = await apiServices.removeDp(data);

    if (response?.response_code == 200) {
      toast.success(response?.response_message);
      thunkAPI.dispatch(setUser(response?.response_data?.user));
      return true;
    } else {
      toast.error(response?.response_message);
    }
  } catch (e) {
    toast.error("Something went Wrong");
    console.log("This is Error from Suspend User", e);
  }
});

export const changeUserPassword = createAsyncThunk(
  "changeUserPassword",
  async (user) => {
    try {
      const res = await apiServices.changePassword(user);
      console.log("res", res);
      if (res?.data?.response_code === 200) {
        toast.success(res?.data?.response_message);
        return true;
      } else {
        toast.error(res?.data?.response_message);
        return false;
      }
    } catch (e) {
      console.log(e);
    }
  }
);

export const uploadImage = createAsyncThunk("imageUpload", async (data) => {
  try {
    var bodyFormData = new FormData();
    bodyFormData.append("file", data?.file);
    const response = await apiServices.uploadImage(bodyFormData);
    if (response?.data?.response_code == 200) {
      data?.getUrl(response?.data?.response_data?.url);
    }
  } catch (e) {
    console.log("This is Error from Image Upload", e);
    toast.error("Something went Wrong");
  }
});

export const autoExpire = createAsyncThunk(
  "autoExpire",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.autoExpire();
      if (response?.data?.response_code == 200) {
        console.log("auto expire working");
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get analytics", e);
    }
  }
);

export const authSlice = createSlice({
  name: "Auth Slice",
  initialState: initial_state,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUnverifiedUser: (state, action) => {
      state.unverifiedUser = action.payload;
    },
    setPhoneChanged: (state, action) => {
      state.phoneChanged = action.payload;
    },
  },
  extraReducers: {
    [loginUser.pending]: (state) => {
      state.loading = true;
    },
    [loginUser.fulfilled]: (state, action) => {
      if (action?.payload) {
        state.user = action?.payload?.user;
        state.userRole = action?.payload?.userRole;
      }
      state.loading = false;
    },
    [loginUser.rejected]: (state) => {
      state.loading = false;
    },
    [changeUserPassword.pending]: (state) => {
      state.change_pass_loading = true;
    },
    [changeUserPassword.fulfilled]: (state) => {
      state.change_pass_loading = false;
    },
    [changeUserPassword.rejected]: (state) => {
      state.change_pass_loading = false;
    },
  },
});
export const { setUser, setUnverifiedUser, setPhoneChanged } =
  authSlice.actions;
export const AuthSlice = authSlice.reducer;
