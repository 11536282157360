import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  readProductType,
  registerBrand,
  saveBrandRegistrationStage1,
  saveBrandRegistrationStage2,
  saveBrandRegistrationStage3,
} from "../api/slices/registrationSlice";
import * as yup from "yup";
import xmark from "../statics/xmark-solid.svg";
import ImagePickerModal from "../components/layouts/modal/ImagePickerModal";
import logo from "../statics/comingsoon/logo.png";
import Select from "react-select";
import { getAllDaysInMonth, getLabelFromId } from "../Utils/auth.util";
import { Puff } from "react-loader-spinner";
import BrandRegistrationStep4 from "./BrandRegistrationStep4";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import PreviewImageModal from "../components/layouts/modal/PreviewImageModal";
import BrandRegistrationStep3 from "./BrandRegistrationStep3";
import BrandRegistrationStep2 from "./BrandRegistrationStep2";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import { toggleNavbar } from "../api/slices/globalSlice";
import { resendBrandOtp, verifyBrandOtp } from "../api/slices/authSlice";

export default function BrandFreakRegistration(props) {
  const [imageModal, setImageModal] = useState(false);
  const [displayimageModal, setDisplayImageModal] = useState(false);
  const options = useMemo(() => countryList().getData(), []);
  const [country, setCountry] = useState(null);
  const [error, setErrors] = useState(null);
  const { loading, registeringBrand } = useSelector(
    (state) => state.registrationReducer
  );

  const [type, setType] = useState(false);
  const [typeConfirm, setTypeConfirm] = useState(false);
  const [phone, setPhone] = useState(null);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [otp, setOtp] = useState(null);
  const [step, setStep] = useState("Brand Profile");
  const [seconds, setSeconds] = useState(0);

  let tagName = null;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readProductType());
    dispatch(toggleNavbar(false));

    return () => {
      dispatch(toggleNavbar(true));
    };
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const schema = yup
    .object({
      name: yup.string().required("Please enter your brand name"),
      email_address: yup.string().required("Please enter your brand email"),
      password: yup
        .string()
        .required("Please Enter your password")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirm_password: yup
        .string()
        .required("Please Enter your password")
        .oneOf([yup.ref("password")], "Your passwords do not match.")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    })
    .required();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (!phone) {
      setErrors({
        // ...errors,
        phone: { message: "please enter your phone" },
      });
    }
    if (!country) {
      setErrors({
        ...errors,
        country: { message: "please enter your country" },
      });
    }
    if (phone && country) {
      setErrors(null);
      let apiData = {
        ...data,
        // display_picture: file,
        phone_number: "+" + phone,
        country: country?.label,
      };
      dispatch(saveBrandRegistrationStage1(apiData));
      let res = await dispatch(registerBrand(apiData));
      if (res?.payload) {
        setStep("otp");
        setSeconds(59);
      }
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid user-type-back p-5">
        <div className="container brand-reg-registration ">
          <div className="row  p-4">
            <div className="col-lg-6 ">
              <img
                src={logo}
                width={"auto"}
                height={"50px"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <div className="">
                <span
                  className="brand-child-button"
                  onClick={() => {
                    navigate("/user-types");
                  }}
                  style={{ fontFamily: "Quick sand bold" }}
                >
                  <i
                    className="fas fa-less-than mr-2"
                    style={{ fontSize: "16px" }}
                  ></i>{" "}
                  Go Back
                </span>
              </div>
            </div>
          </div>
          <div className="row m-5 user-profile">
            <div className="col-lg-6">
              <h6
                className="text-white"
                style={{ fontFamily: "Quick sand reg" }}
              >
                Sign Up{" "}
              </h6>
              <h2 className="text-white" style={{ fontFamily: "Athelas Reg" }}>
                Brand{" "}
              </h2>
              <p
                className="text-white"
                style={{ fontFamily: "Quick sand reg" }}
              >
                Register Yourself as Brand{" "}
              </p>
              <span>
                <hr className="register-hr" />
              </span>

              <div
                className="mt-5  text-white registration-steppar"
                style={{ borderLeft: "1px solid #fff" }}
              >
                <p
                  style={{
                    borderLeft:
                      step === "Brand Profile" ? "4px solid #F4A950" : "none",
                    marginLeft: step === "Brand Profile" ? "-2px" : "0px",
                    fontFamily: "Avantgrade Demi",
                  }}
                  className={
                    step === "Brand Profile"
                      ? "steppar-item-active pl-3"
                      : "steppar-item pl-3"
                  }
                  onClick={() => {
                    setStep("Brand Profile");
                  }}
                >
                  Brand Profile
                </p>
              </div>
            </div>
            <div className="col-lg-6 p-5 mb-5 d-flex justfify-content-end brand-form">
              {step === "Brand Profile" ? (
                <div className="row w-100">
                  <div className="col-12">
                    <h1 style={{ fontFamily: "Avantgrade Book" }}>
                      Brand Profile
                    </h1>
                    <hr className="reg-form-hr" />
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Brand Name *
                          </span>
                          <input
                            {...register("name")}
                            type="text"
                            name="name"
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input mt-2 w-100"
                            placeholder="BRAND NAME"
                          />
                          {errors.name && (
                            <p className="error-message">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Email Address *
                          </span>
                          <input
                            {...register("email_address")}
                            type="email"
                            name="email_address"
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input mt-2 w-100"
                            placeholder="EMAIL ADDRESS"
                          />
                          {errors.email_address && (
                            <p className="error-message">
                              {errors?.email_address?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Password *
                          </span>
                          <input
                            {...register("password")}
                            type={type ? "text" : "password"}
                            name="password"
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100"
                            placeholder="PASSWORD"
                          />
                          <span>
                            {type ? (
                              <i
                                className="password-icon fa fa-eye"
                                onClick={() => setType(!type)}
                              />
                            ) : (
                              <i
                                className="password-icon fa fa-eye-slash"
                                onClick={() => setType(!type)}
                              />
                            )}
                          </span>

                          {errors.password && (
                            <p className="error-message">
                              {errors?.password?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Confirm Password *
                          </span>
                          <input
                            {...register("confirm_password")}
                            type={typeConfirm ? "text" : "password"}
                            name="confirm_password"
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100"
                            placeholder="PASSWORD"
                          />
                          <span>
                            {typeConfirm ? (
                              <i
                                className="password-icon fa fa-eye"
                                onClick={() => setTypeConfirm(!typeConfirm)}
                              />
                            ) : (
                              <i
                                className="password-icon fa fa-eye-slash"
                                onClick={() => setTypeConfirm(!typeConfirm)}
                              />
                            )}
                          </span>

                          {errors.confirm_password && (
                            <p className="error-message">
                              {errors?.confirm_password?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-lg-6 mt-3">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Country *
                          </span>

                          <Select
                            name="country"
                            className="mt-2"
                            styles={{ height: "100px" }}
                            onChange={(e) => setCountry(e)}
                            options={options}
                          />
                          {error?.country && (
                            <p className="error-message">
                              {error?.country?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 mt-3">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Phone Number *
                          </span>
                          <PhoneInput
                            className="mt-1 "
                            name="phone"
                            country={"ae"}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                          />
                          {error?.phone && (
                            <p className="error-message">
                              {error?.phone?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          {loading ? (
                            <div className="d-flex justify-content-center p-2">
                              <Puff color="#000" height={30} width={60} />
                            </div>
                          ) : (
                            <button
                              type="submit"
                              style={{ fontFamily: "Avantgrade Demi" }}
                              className="home-brand-button"
                            >
                              Sign Up
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : step === "otp" ? (
                <div className="row w-100">
                  <div className="col-12">
                    <p>
                      We Have Sent you an OTP on your Email Address. Please
                      Enter OTP to Verify your Email Address
                    </p>
                    <input
                      type="text"
                      name="name"
                      style={{ border: "2px solid #7E8084" }}
                      onChange={(e) => setOtp(e?.target?.value)}
                      className="budgut-input mt-2 w-100"
                    />
                  </div>

                  <div className="col-2 d-flex justify-content-center"></div>

                  <div className="col-4 d-flex justify-content-center mt-3">
                    <button
                      style={{
                        fontFamily: "Avantgrade Demi",
                        opacity: seconds > 0 ? "0.5" : "1",
                      }}
                      className="home-brand-button d-flex flex-row"
                      disabled={seconds > 0}
                      onClick={async () => {
                        let apiData = {
                          uid: registeringBrand?.uid,
                        };
                        let res = await dispatch(resendBrandOtp(apiData));
                        if (res?.payload) {
                          setSeconds(59);
                        }
                      }}
                    >
                      Resend
                      {seconds > 0 && (
                        <span className="pl-2">
                          {`00:${seconds < 10 ? "0" : ""}${seconds}`}
                        </span>
                      )}
                    </button>
                  </div>

                  <div className="col-3 d-flex justify-content-center mt-3 ml-4">
                    <button
                      style={{ fontFamily: "Avantgrade Demi" }}
                      className="home-brand-button"
                      onClick={async () => {
                        if (!otp) {
                          toast.error("Enter OTP");
                        } else {
                          let apiData = {
                            uid: registeringBrand?.uid,
                            token: otp,
                          };

                          let res = await dispatch(verifyBrandOtp(apiData));
                          if (res?.payload) {
                            window.open(
                              "https://dashboard-brandemirates.web.app/",
                              "_blank"
                            );
                          }
                        }
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ImagePickerModal
        show={imageModal}
        onHide={() => setImageModal(false)}
        file={file}
        setFile={setFile}
        setFileName={setFileName}
        {...props}
      />
      <PreviewImageModal
        show={displayimageModal}
        onHide={() => setDisplayImageModal(!displayimageModal)}
        image={file}
      />
    </>
  );
}
