import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import ProductCard from "../../components/reuseableComponents/productCard/ProductCard";
import game1 from "../../statics/5-games-1.jpg";
import game2 from "../../statics/5-games-2.png";
import game3 from "../../statics/5-games-3.png";
import ClientTestimonials from "../../components/layouts/sliders/TestamonialSlider";
import ClientSlider from "../../components/layouts/clientSlider/ClientSlider";
import BrandOfferSlider from "../../components/layouts/sliders/brandOfferSlider/BrandOfferSlider";
import section from "../../statics/Subtraction 10.png";
import MainSlider from "../../components/layouts/sliders/mainSlider/MainSlider";
import { useDispatch, useSelector } from "react-redux";
import placeholder from "../../statics/image_placeholder.png";
import brandSlotAvailable from "../../statics/placeholder.png";
import {
  filterBrand,
  getAnalytics,
  getGuessBrands,
  getOfferUnderBudget,
  opinionMatters,
  readAllOffers,
  readBrandOffers,
  readBrands,
  readFeatureedBrands,
  readPartners,
  readTrendingProducts,
  readProductTypes,
  readStoreCities,
  readBrandOutlets,
  readActivities,
  readNotifications,
  viewedNotifications,
} from "../../api/slices/webSlice";
import { useNavigate, useLocation } from "react-router-dom";
import BuyAndWin from "../../components/layouts/modal/BuyAndWin";
import RateAndWin from "../../components/layouts/modal/RateAndWin";
import { MenWomen, OffersSlider, SingleCountView } from "./components";
import { getCountsArray } from "../../config";
import { toast } from "react-toastify";
import { Loader } from "../../components/common";
import GenderBrandSlider from "../../components/common/Slider";
import GuessAndWinImg from "../../statics/quiz.png";
import buyandwinimg from "../../statics/comingsoon/buy&win-bo.png";
import rateAndWin from "../../statics/rateAndWin.png";
import Select from "react-select";
import GoogleMapReact from "google-map-react";
import LocationPin from "../../components/layouts/modal/helper/LocationPin";
import TrendingSlider from "./components/TrendingSlider";
import { capitalize } from "../../Utils/functions";

const Home = () => {
  const { t } = useTranslation("common");
  const selectBrand = useRef(null);
  const [show, setShow] = useState(false);
  const [showRate, setShowRate] = useState(false);
  const [mens, setMens] = useState(false);
  const [womens, setWomen] = useState(false);
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const [budgetBrand, setBudgetBrand] = useState(null);
  const [city, setCity] = useState(null);
  const [brandOutlet, setBrandOutlet] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readBrands());
    dispatch(readFeatureedBrands());
    dispatch(readBrandOffers());
    dispatch(readTrendingProducts());
    dispatch(readPartners());
    dispatch(getAnalytics());
    dispatch(opinionMatters());
    dispatch(readAllOffers());
    dispatch(readProductTypes());
    dispatch(readStoreCities());
    dispatch(readNotifications());
    // dispatch(viewedNotifications());
  }, []);

  const {
    analytics,
    brands,
    featuredBrands,
    brandOffers,
    trendingProducts,
    opinions,
    opinions_loading,
    sponsors,
    mens_brands,
    womens_brands,
    offers_under_budget,
    budget_loading,
    cities_loading,
    read_cities,
    read_outlets,
    stores_loading,
    read_notifications,
    viewed_notifications,
    featured_brands_loading,
  } = useSelector((state) => state.WebSlice);
  const { user } = useSelector((state) => state.AuthSlice);

  useEffect(() => {
    if (brands) {
      dispatch(filterBrand({ brands: brands, filter: "male" }));
      dispatch(filterBrand({ brands: brands, filter: "female" }));
    }
  }, [brands]);

  const TakeMeToSelectBrand = () => {
    selectBrand?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const budgetBrands = brands?.map((v) => ({
    label: v?.name,
    value: v?.name,
    id: v?.uid,
  }));
  budgetBrands?.push({
    label: "All Brands",
    value: "All Brands",
    id: null,
  });
  const cities = read_cities?.map((v) => ({
    label: capitalize(v),
    value: v,
  }));
  const brandStores = brands?.map((v) => ({
    label: v?.name,
    value: v?.name,
    id: v?.uid,
  }));

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 38,
      minHeight: 38,
    }),
  };
  const budgetStyles = {
    control: (base, { isSelected }) => ({
      ...base,
      height: 38,
      minHeight: 38,
      color: isSelected ? "red" : "green",

      // width: 200,
      backgroundColor: "transparent",
      border: "2px solid white",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color: "#fff" };
    },
  };
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Helmet>
        <title>{t("home.maintitle")}</title>
      </Helmet>

      {/* Slider */}
      <MainSlider />

      {/* Slider */}

      {/* Men Women Section */}
      <div className="container-fluid p-0">
        <MenWomen
          isMen={mens}
          isWomen={womens}
          onPressMen={() => {
            if (womens) {
              setWomen(false);
              // TakeMeToSelectBrand();
            }
            setMens(!mens);
          }}
          onPressWomen={() => {
            if (mens) {
              setMens(false);
              // TakeMeToSelectBrand();
            }
            setWomen(!womens);
          }}
        />
        {(mens || womens) && (
          <div ref={selectBrand} className="row mt-3 mx-lg-0 mx-1">
            <div className="col-12 text-center">
              <span className="head-featured ">
                SELECT A<span className="head-brand-green"> BRAND</span>
              </span>
            </div>
            <GenderBrandSlider
              goToBrandProfile={(data) =>
                navigate("/brand-details", { state: data })
              }
              brands={mens ? mens_brands : womens_brands}
            />
          </div>
        )}
      </div>

      {/* Men Women Section */}

      {/* Featured Brands */}
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 text-center">
            <span className="head-featured ">
              FEATURED
              <span className="head-brand-green"> BRANDS</span>
            </span>
          </div>
        </div>
        {featured_brands_loading ? (
          <Loader />
        ) : (
          <div
            className="row m-lg-3 "
            data-aos="zoom-out"
            // style={{ height: "100%" }}
          >
            <div className="col-lg-4 col-4">
              <div className="row featured-brand-1 ">
                <div className="col-lg-12 main-cont">
                  {featuredBrands[0]?.status?.name == "Live" ? (
                    <div className="hover-div">
                      <div
                        disabled={!featuredBrands[0]}
                        onClick={() =>
                          navigate("/brand-details", {
                            state: featuredBrands[0]?.brand,
                          })
                        }
                        className="feature"
                      >
                        <img
                          src={
                            featuredBrands[0]?.status?.name == "Live" &&
                            featuredBrands[0]?.display_picture
                              ? featuredBrands[0]?.display_picture
                              : placeholder
                          }
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>

                      <div className="display-all">
                        {featuredBrands[0]?.status?.name == "Live" &&
                          featuredBrands[0]?.brand?.display_picture && (
                            <img src={section} className="section-card" />
                          )}
                        <div className="">
                          {featuredBrands[0]?.status?.name == "Live" &&
                            featuredBrands[0]?.brand?.display_picture && (
                              <img
                                src={
                                  featuredBrands[0]?.status?.name == "Live" &&
                                  featuredBrands[0]?.brand?.display_picture
                                    ? featuredBrands[0]?.brand?.display_picture
                                    : null
                                }
                                className="section-logo"
                              />
                            )}
                        </div>
                        <div className="hover-text ">
                          <div className="hover-brand-text proxima-font">
                            <h3>
                              {featuredBrands[0]?.status?.name == "Live" &&
                              featuredBrands[0]?.slogan
                                ? featuredBrands[0]?.slogan?.toUpperCase()
                                : null}
                            </h3>
                          </div>

                          <div className="hover-brand-games-text">
                            <ul className="featured-brand-games-list raleway-font">
                              {featuredBrands[0]?.brand?.is_rate &&
                                featuredBrands[0]?.status?.name == "Live" && (
                                  <img src={rateAndWin} alt="rateAndWin" />
                                )}
                              {featuredBrands[0]?.brand?.is_guess &&
                                featuredBrands[0]?.status?.name == "Live" && (
                                  <img
                                    src={GuessAndWinImg}
                                    alt="GuessAndWinImg"
                                  />
                                )}
                              {featuredBrands[0]?.brand?.is_buy &&
                                featuredBrands[0]?.status?.name == "Live" && (
                                  <img src={buyandwinimg} alt="buyandwinimg" />
                                )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="hover-div">
                      <div disabled={!featuredBrands[0]} className="feature">
                        <img
                          src={brandSlotAvailable}
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  <div className="overlay"></div>
                </div>
              </div>
              <div className="row mt-lg-3 mt-1 featured-brand-2">
                <div className="col-lg-12 main-cont">
                  {featuredBrands[1]?.status?.name == "Live" ? (
                    <div className="hover-div">
                      <div
                        disabled={!featuredBrands[1]}
                        onClick={() =>
                          navigate("/brand-details", {
                            state: featuredBrands[1]?.brand,
                          })
                        }
                        className="feature"
                      >
                        <img
                          src={
                            featuredBrands[1]?.display_picture
                              ? featuredBrands[1]?.display_picture
                              : placeholder
                          }
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>

                      <div className="display-all">
                        {featuredBrands[1]?.brand?.display_picture && (
                          <img src={section} className="section-card" />
                        )}
                        <div className="">
                          {featuredBrands[1]?.brand?.display_picture && (
                            <img
                              src={
                                featuredBrands[1]?.brand?.display_picture
                                  ? featuredBrands[1]?.brand?.display_picture
                                  : null
                              }
                              className="section-logo"
                            />
                          )}
                        </div>
                        <div className="hover-text ">
                          <div className="hover-brand-text proxima-font">
                            <h3>
                              {featuredBrands[1]?.slogan
                                ? featuredBrands[1]?.slogan?.toUpperCase()
                                : null}
                            </h3>

                            <span className="hoddie-trifle "></span>
                          </div>
                          <div className="hover-brand-games-text">
                            <ul className="featured-brand-games-list raleway-font">
                              {featuredBrands[1]?.brand?.is_rate && (
                                <img src={rateAndWin} alt="rateAndWin" />
                              )}
                              {featuredBrands[1]?.brand?.is_guess && (
                                <img
                                  src={GuessAndWinImg}
                                  alt="GuessAndWinImg"
                                />
                              )}
                              {featuredBrands[1]?.brand?.is_buy && (
                                <img src={buyandwinimg} alt="buyandwinimg" />
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="hover-div">
                      <div disabled={!featuredBrands[1]} className="feature">
                        <img
                          src={brandSlotAvailable}
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  <div className="overlay"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-8 pr-lg-0 pr-4">
              <div className="row featured-brand-3 pr-lg-0 ">
                <div className="col-lg-12 main-cont">
                  {featuredBrands[2]?.status?.name == "Live" ? (
                    <div className="hover-div">
                      <div
                        disabled={!featuredBrands[2]}
                        onClick={() =>
                          navigate("/brand-details", {
                            state: featuredBrands[2]?.brand,
                          })
                        }
                        className="feature"
                      >
                        <img
                          src={
                            featuredBrands[2]?.display_picture
                              ? featuredBrands[2]?.display_picture
                              : placeholder
                          }
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>

                      <div className="display-all">
                        {featuredBrands[2]?.brand?.display_picture && (
                          <img src={section} className="section-card" />
                        )}
                        <div className="">
                          {featuredBrands[2]?.brand?.display_picture && (
                            <img
                              src={
                                featuredBrands[2]?.brand?.display_picture
                                  ? featuredBrands[2]?.brand?.display_picture
                                  : null
                              }
                              className="section-logo"
                            />
                          )}
                        </div>
                        <div className="hover-text ">
                          <div className="hover-brand-text proxima-font">
                            <strong>
                              <h3>
                                {featuredBrands[2]?.slogan
                                  ? featuredBrands[2]?.slogan?.toUpperCase()
                                  : null}
                              </h3>
                            </strong>
                            <span className="hoddie-trifle "></span>
                          </div>
                          <div className="hover-brand-games-text">
                            <ul className="featured-brand-games-list raleway-font">
                              {featuredBrands[2]?.brand?.is_rate && (
                                <img src={rateAndWin} alt="rateAndWin" />
                              )}
                              {featuredBrands[2]?.brand?.is_guess && (
                                <img
                                  src={GuessAndWinImg}
                                  alt="GuessAndWinImg"
                                />
                              )}
                              {featuredBrands[2]?.brand?.is_buy && (
                                <img src={buyandwinimg} alt="buyandwinimg" />
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="hover-div">
                      <div disabled={!featuredBrands[2]} className="feature">
                        <img
                          src={brandSlotAvailable}
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  <div className="overlay"></div>
                </div>
              </div>
              <div className="row mt-lg-3 mt-1 featured-brand-4 pr-lg-0 pr-3">
                <div
                  className="col-lg-6 col-6  main-cont"
                  // style={{ height: "300px" }}
                >
                  {featuredBrands[3]?.status?.name == "Live" ? (
                    <div className="hover-div">
                      <div
                        disabled={!featuredBrands[3]}
                        onClick={() =>
                          navigate("/brand-details", {
                            state: featuredBrands[3]?.brand,
                          })
                        }
                        className="feature"
                      >
                        <img
                          src={
                            featuredBrands[3]?.display_picture
                              ? featuredBrands[3]?.display_picture
                              : placeholder
                          }
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>

                      <div className="display-all">
                        {featuredBrands[3]?.brand?.display_picture && (
                          <img src={section} className="section-card" />
                        )}
                        <div className="">
                          {featuredBrands[3]?.brand?.display_picture && (
                            <img
                              src={
                                featuredBrands[3]?.brand?.display_picture
                                  ? featuredBrands[3]?.brand?.display_picture
                                  : null
                              }
                              className="section-logo"
                            />
                          )}
                        </div>
                        <div className="hover-text ">
                          <div className="hover-brand-text proxima-font">
                            <h3>
                              {featuredBrands[3]?.slogan
                                ? featuredBrands[3]?.slogan?.toUpperCase()
                                : null}
                            </h3>
                          </div>
                          <div className="hover-brand-games-text">
                            <ul className="featured-brand-games-list raleway-font">
                              {featuredBrands[3]?.brand?.is_rate && (
                                <img src={rateAndWin} alt="rateAndWin" />
                              )}
                              {featuredBrands[3]?.brand?.is_guess && (
                                <img
                                  src={GuessAndWinImg}
                                  alt="GuessAndWinImg"
                                />
                              )}
                              {featuredBrands[3]?.brand?.is_buy && (
                                <img src={buyandwinimg} alt="buyandwinimg" />
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="hover-div">
                      <div disabled={!featuredBrands[3]} className="feature">
                        <img
                          src={brandSlotAvailable}
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  <div className="overlay"></div>
                </div>
                <div
                  className="col-lg-6 col-6  main-cont"
                  // style={{ height: "300px" }}
                >
                  {featuredBrands[4]?.status?.name == "Live" ? (
                    <div className="hover-div">
                      <div
                        disabled={!featuredBrands[4]}
                        onClick={() =>
                          navigate("/brand-details", {
                            state: featuredBrands[4]?.brand,
                          })
                        }
                        className="feature"
                      >
                        <img
                          src={
                            featuredBrands[4]?.display_picture
                              ? featuredBrands[4]?.display_picture
                              : placeholder
                          }
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>

                      <div className="display-all">
                        {featuredBrands[4]?.brand?.display_picture && (
                          <img
                            src={
                              featuredBrands[4]?.brand?.display_picture
                                ? section
                                : null
                            }
                            className="section-card"
                          />
                        )}
                        <div className="">
                          {featuredBrands[4]?.brand?.display_picture && (
                            <div className="">
                              <img
                                src={
                                  featuredBrands[4]?.brand?.display_picture
                                    ? featuredBrands[4]?.brand?.display_picture
                                    : null
                                }
                                className="section-logo"
                              />
                            </div>
                          )}
                        </div>
                        <div className="hover-text ">
                          <div className="hover-brand-text proxima-font">
                            <h3>
                              {featuredBrands[4]?.slogan
                                ? featuredBrands[4]?.slogan?.toUpperCase()
                                : null}
                            </h3>
                            <span className="hoddie-trifle "></span>
                          </div>
                          <div className="hover-brand-games-text">
                            <ul className="featured-brand-games-list raleway-font">
                              {featuredBrands[4]?.brand?.is_rate && (
                                <img src={rateAndWin} alt="rateAndWin" />
                              )}
                              {featuredBrands[4]?.brand?.is_guess && (
                                <img src={GuessAndWinImg} alt="buyandwinimg" />
                              )}
                              {featuredBrands[4]?.brand?.is_buy && (
                                <img src={buyandwinimg} alt="GuessAndWinImg" />
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="hover-div">
                      <div disabled={!featuredBrands[4]} className="feature">
                        <img
                          src={brandSlotAvailable}
                          alt="No Image"
                          height="100%"
                          width="100%"
                        />
                      </div>
                    </div>
                  )}

                  <div className="overlay"></div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="row  mt-3">
          <div className="col-12 text-center ">
            <button
              onClick={() => {
                navigate("/brand-offer");
                window.scrollTo(0, 0);
              }}
              className="home-brand-button"
            >
              VIEW ALL BRANDS
            </button>
          </div>
        </div>
      </div>

      {/* Featured Brands */}

      {/* BRAND OFFERS */}
      <div
        className="container-fluid mt-4"
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container ">
          <div className="row py-2 d-flex align-items-center justify-content-center">
            <div
              className="col-lg-12  d-flex flex-column align-items-center justify-content-center"
              data-aos="zoom-in"
            >
              <span className="head-featured-brand ">
                HOT
                <span className="head-brand-green-offers"> DEALS</span>
              </span>
              {/* <p className="brand-offer-text proxima-font">
                Different Offers From Different Brands
              </p> */}
            </div>
            <div className="col-lg-12">
              <BrandOfferSlider
                onPressView={(data) =>
                  navigate("/brand-details", {
                    state: {
                      ...data?.brand,
                      selectedProduct: data?.product,
                    },
                  })
                }
                data={brandOffers}
              />
            </div>
          </div>
        </div>
      </div>
      {/* BRAND OFFERS */}

      {/* TRENDING PRODUCTS */}
      <div className="container-fluid bg-light">
        <div className="row mt-5" data-aos="zoom-in">
          <div className="col-12 text-center">
            <span className="head-featured ">
              TRENDING
              <span className="head-brand-green"> PRODUCTS</span>
            </span>
          </div>
        </div>

        <div className="row d-flex justify-content-center">
          <div className="col-lg-11 col-11 ">
            <TrendingSlider
              goToDetail={(data) =>
                navigate("/brand-details", {
                  state: {
                    ...data?.brand,
                    selectedProduct: data,
                  },
                })
              }
              list={trendingProducts}
            />
          </div>
        </div>
      </div>

      {/* TRENDING PRODUCTS */}

      {/* HOW YOU CAN WIN */}
      <div className="container">
        <div className="row mt-3  " data-aos="zoom-in">
          <div className="col-12 text-center">
            <span className="head-featured ">
              HOW
              <span className="head-brand-green"> YOU </span>
              <span className="head-featured ">CAN WIN</span>
            </span>
          </div>
        </div>
        <div className="row no-gutters mt-3" data-aos="fade-up">
          <div className="col-lg-4">
            <div className="row no-gutters">
              <div className="col-12">
                <img
                  className="game-1-img"
                  src={game1}
                  // height="200px"
                  // width="100%"
                />
              </div>
            </div>
            <div
              className="row no-gutters"
              style={{ backgroundColor: "#5B6E85" }}
            >
              <div
                className="col-12 p-lg-4 p-2 game-card-details"
                // style={{ height: "325px" }}
              >
                <div>
                  <h6 className="text-white game-heading  p-2">Buy and Win</h6>
                  <p
                    className="text-white mt-lg-5 mt-1 px-2 game-para "
                    // style={{ height: "90px", paddingBottom: "30px" }}
                  >
                    Files successfully emitted, waiting for typecheck results...
                  </p>
                  <div className="d-flex justify-content-center mt-auto">
                    <button
                      className="home-brand-button "
                      onClick={() => {
                        if (user) {
                          setShow(true);
                        } else {
                          toast.error("Please Login to continue");
                        }
                      }}
                    >
                      ENROLL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-lg-0 mt-3">
            <div className="row no-gutters">
              <div className="col-12">
                <img
                  className="game-1-img"
                  src={game2}
                  // height="200px" width="100%"
                />
              </div>
            </div>
            <div
              className="row no-gutters"
              style={{ backgroundColor: "#E9B373" }}
            >
              <div
                className="col-12 p-lg-4 p-2 game-card-details"
                // style={{ height: "325px" }}
              >
                <div>
                  <h6 className="text-white game-heading p-2">
                    Rate Your Favourite Brand And Win
                  </h6>
                  <p
                    className="text-white mt-lg-2  p-1 game-para"
                    // style={{ height: "90px", paddingBottom: "30px" }}
                  >
                    Rate Your Favourite Brand And Win Files successfully
                    emitted, waiting for typecheck results...
                  </p>
                  <div className="d-flex justify-content-center mt-auto">
                    <button
                      className="home-brand-button outline-none border-none shadow-none"
                      onClick={() => {
                        if (user) {
                          setShowRate(true);
                        } else {
                          toast.error("Please Login to continue");
                        }
                      }}
                    >
                      ENROLL
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 mt-lg-0 mt-3">
            <div
              className="row no-gutters"
              style={{ backgroundColor: "#ADCBC9" }}
            >
              <div className="col-12" style={{ height: "200px" }}>
                <div className="d-flex justify-content-center align-items-center mt-5">
                  <img src={game3} height="130px" width="50%" />
                </div>
              </div>
            </div>
            <div
              className="row no-gutters"
              style={{ backgroundColor: "#434951" }}
            >
              <div
                className="col-12 p-4 game-card-details"
                // style={{ height: "325px" }}
              >
                <div>
                  <h5 className="text-white game-heading p-2">Guess and Win</h5>
                  <p
                    className="text-white mt-lg-5 p-1 game-para"
                    // style={{ height: "90px", paddingBottom: "30px" }}
                  >
                    Guess and Win Files successfully emitted, waiting for
                    typecheck results...
                  </p>
                  <div className="d-flex justify-content-center mt-auto">
                    <button
                      className="home-brand-button outline-none"
                      onClick={() => {
                        if (user) {
                          navigate("/guess");
                          goToTop();
                        } else {
                          toast.error("Please Login to continue");
                        }
                      }}
                    >
                      START
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* HOW YOU CAN WIN */}

      {/* OFFER UNDER BUDGUT */}
      <div className="container mb-2">
        <div className="row mt-2" data-aos="zoom-in">
          <div className="col-12 text-center">
            <span className="head-featured ">
              OFFERS UNDER
              <span className="head-brand-green"> BUDGET</span>
            </span>
          </div>
        </div>
      </div>

      <div className="container-fluid back-cont pb-5 pt-5">
        <div className="row ">
          <div className="col-lg-12 text-center">
            <div className=" mb-2">
              <span className="head-featured-white ">
                TELL US
                <span className="head-brand-green"> YOUR </span>
                BUDGET WE'LL GIVE <span className="head-brand-green">
                  {" "}
                  YOU
                </span>{" "}
                BEST OFFERS
              </span>
            </div>
          </div>
        </div>
        <div className="mt-lg-3 mx-lg-5">
          <div className="row d-flex flex-row justify-content-center align-items-center">
            <div className="col-md-3 col-sm-3  media-text-white p-2 d-flex flex-row justify-content-center align-items-center">
              <span className="text-white max-head pr-2">MIN</span>
              <input
                type="number"
                className="budgut-input-offer"
                placeholder="ENTER AMOUNT IN AED"
                value={min}
                onChange={(e) => setMin(e?.target?.value)}
              />
            </div>
            <div className="col-md-3 col-sm-3 media-text-white p-2 d-flex flex-row justify-content-center align-items-center">
              <span className="text-white max-head pr-2">MAX</span>
              <input
                type="number"
                className="budgut-input-offer"
                placeholder="ENTER AMOUNT IN AED"
                value={max}
                onChange={(e) => setMax(e?.target?.value)}
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-8">
              <Select
                placeholder="Select Brand"
                className="Select-value"
                options={budgetBrands}
                styles={budgetStyles}
                isClearable={true}
                onChange={(e) => setBudgetBrand(e?.id)}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    neutral50: "#fff", // Placeholder color
                  },
                })}
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-6 mb-1 find-offers-btn">
              {budget_loading ? (
                <Loader color="white" />
              ) : (
                <button
                  className="home-brand-button "
                  onClick={() => {
                    if (!min) {
                      toast.error("Enter Min Value");
                    } else if (!max) {
                      toast.error("Enter Max Value");
                    } else {
                      let data = {
                        min: parseInt(min),
                        max: parseInt(max),
                        brand: budgetBrand,
                      };
                      dispatch(getOfferUnderBudget(data));
                    }
                  }}
                  style={{ padding: "8px 16px" }}
                >
                  FIND OFFERS
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-4 col-sm-6 col-12 offer-slider">
              <OffersSlider
                onPressItem={(item) => {
                  navigate("/brand-details", {
                    state: {
                      ...item?.brand,
                      selectedProduct: item,
                    },
                  });
                }}
                offers={offers_under_budget}
              />
            </div>
          </div>
        </div>
      </div>
      {/* OFFER UNDER BUDGUT */}

      {/* BRAND OUTLETS */}
      <div className="container-fluid" data-aos="zoom-in">
        <div className="row p-2">
          <div className="col-12 text-center">
            <span className="head-featured  ">
              FIND OUTLETS OF YOUR
              <span className="head-brand-green"> FAVOURITE BRAND</span>
            </span>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row mx-5 my-4">
          <div className="col-2"></div>
          <div className="col-lg-3 mb-lg-0 mb-4">
            <Select
              placeholder="Select City"
              className="text-dark"
              options={cities}
              styles={customStyles}
              isClearable={true}
              onChange={(e) => setCity(e?.value)}
            />
          </div>
          <div className="col-lg-3 mb-lg-0 mb-4">
            <Select
              placeholder="Choose Brand"
              className="text-dark"
              options={brandStores}
              styles={customStyles}
              isClearable={true}
              onChange={(e) => setBrandOutlet(e?.id)}
            />
          </div>
          <div className="col-lg-2 col-10 d-flex justify-content-end">
            <button
              className="home-brand-button-oultet"
              onClick={() => {
                if (!city) {
                  toast?.error("Select City to find an outlet please");
                } else if (!brandOutlet) {
                  toast?.error("Select Brand to find an outlet please");
                } else {
                  let data = {
                    brand: brandOutlet,
                    city: city,
                  };
                  dispatch(readBrandOutlets(data));
                  setSelectedMarker(null);
                }
                // let data = {
                //   brand: brandOutlet,
                //   city: city,
                // };
                // dispatch(readBrandOutlets(data));
                // setSelectedMarker(null);
              }}
            >
              FIND STORE
            </button>
          </div>
        </div>
      </div>
      {stores_loading ? (
        <Loader height={100} width={100} />
      ) : (
        read_outlets?.length > 0 && (
          <div
            className="container-fluid p-lg-4 p-1 brand-stores-mobile-view"
            style={{ backgroundColor: "#F4F5FA" }}
          >
            <div className="row ml-lg-4 ml-1">
              <div className="col-lg-4 col-md-4 col-sm-4 col-12 mt-4 mt-lg-0 map-stores-info">
                {read_outlets?.map((v, i) => (
                  <>
                    <div
                      onClick={() =>
                        setSelectedMarker({
                          lat: v?.location?.latitude,
                          lng: v?.location?.longitude,
                        })
                      }
                      className="row  outlet-details"
                    >
                      <div className="col-12 quicksand-font">
                        <h4 className=" raleway-font font-weight-bold raleway-font">
                          {v?.name}
                        </h4>
                        <span style={{ fontWeight: 300 }}>{v?.address}</span>
                        <br />

                        <span style={{ fontWeight: 300 }}>
                          {v?.phone_number}
                        </span>
                        <br />

                        <span
                          style={{ fontWeight: 300 }}
                        >{`${v?.city} ${v?.country}`}</span>
                      </div>
                    </div>
                  </>
                ))}
              </div>
              <div className="col-lg-8 col-sm-8 col-11 text-center">
                <div
                  className="brand-store-map"
                  // style={{ width: "100%", height: "100%" }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyBZ0pReZFp3l70TVBfusA5PJXUqkyL6q5k",
                    }}
                    center={
                      selectedMarker
                        ? selectedMarker
                        : {
                            lat: read_outlets[0]?.location?.latitude,
                            lng: read_outlets[0]?.location?.longitude,
                          }
                    }
                    zoom={selectedMarker ? 18 : 10}
                  >
                    {read_outlets?.map((v, i) => (
                      <LocationPin
                        lat={v?.location?.latitude}
                        lng={v?.location?.longitude}
                        storeName={v?.name}
                      />
                    ))}
                  </GoogleMapReact>
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {/* BRAND OUTLETS */}
      {/* TESTANOMIAL */}
      {opinions_loading ? (
        <Loader height={100} width={100} />
      ) : (
        opinions?.length > 0 && (
          <div className="container-fluid back-testamonial mt-2">
            <div className="row mx-lg-5 " data-aos="zoom-in">
              <div className="col-12 text-center focus-in-contract-bck">
                <span className="head-featured ">
                  OPINION
                  <span className="head-brand-green"> MATTERS</span>
                </span>
              </div>
            </div>

            <div className="row">
              <ClientTestimonials
                data={opinions}
                onPressView={(data) =>
                  navigate("/brand-details", {
                    state: {
                      ...data?.brand,
                      selectedProduct: data?.product,
                    },
                  })
                }
              />
            </div>
          </div>
        )
      )}

      {/* TESTANOMIAL */}

      {/* BE Info  */}
      {analytics && (
        <div className="container mt-2" data-aos="flip-up">
          <div className="row p-4 ">
            {getCountsArray(analytics)?.map((v, i) => (
              <SingleCountView
                key={i}
                name={v?.text}
                count={v?.count}
                url={v?.img}
              />
            ))}
          </div>
        </div>
      )}
      {/* BE Info */}

      {/* Clients */}
      <div className="container mt-2 mb-2" data-aos="fade-up">
        <div className="row">
          <div className="col-12 text-center">
            <span className="head-featured ">
              OUR
              <span className="head-brand-green"> PARTNERS</span>
            </span>
          </div>
        </div>
        {sponsors?.length > 0 && (
          <div className="row">
            <ClientSlider data={sponsors} />
          </div>
        )}
      </div>
      {/* Clients */}

      {/* Footer */}

      {/* Footer */}

      <BuyAndWin show={show} onHide={() => setShow(false)} />
      <RateAndWin show={showRate} onHide={() => setShowRate(false)} />
    </>
  );
};

export default Home;
