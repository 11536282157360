import React, { useEffect } from "react";
import { favouriteBrand, readActivities } from "../api/slices/webSlice";
import logo from "../statics/logo-4.png";
import logo2 from "../statics/logo-2.png";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../components/common";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const YourActivityPage = () => {
  const { user } = useSelector((state) => state.AuthSlice);
  const {
    favourite_loading,
    clicks_loading,
    all_offers,
    product_types,
    read_activities,
    activity_loading,
  } = useSelector((state) => state.WebSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(readActivities());
  }, []);
  return (
    <div className="container py-4 ">
      <div className="d-flex justify-content-center raleway-font">
        <span className="head-featured-brand ">
          YOUR
          <span className="head-brand-green-offers"> ACTIVITIES</span>
        </span>
      </div>
      {activity_loading ? (
        <div style={{ height: "100vh" }}>
          <Loader />
        </div>
      ) : (
        <div className="row p-4 border m-4">
          <div className="col-lg-12">
            <h3 className="raleway-font gradient-color-text">
              MY FAVOURITE BRANDS
            </h3>
            <hr />
            <div className="col-lg-12 d-flex flex-row justify-content-center  p-3">
              {read_activities?.BRANDS?.length > 0 ? (
                read_activities?.BRANDS?.map((item, key) => (
                  <div className="headshot headshot-3 d-flex flex-row align-items-center justify-content-center">
                    <img
                      className="mx-4 favourite-brand-image "
                      src={item?.display_picture}
                      alt=""
                      onClick={() => {
                        navigate("/brand-details", { state: item });
                      }}
                    />
                  </div>
                ))
              ) : (
                <div className="d-flex jutify-content-center m-5">
                  <h2 className="text-secondary proxima-font">
                    You do not have any favourite brands.
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12">
            <h3 className="raleway-font gradient-color-text">
              MY PARTICIPATIONS
            </h3>
            <hr />

            <div className="d-flex justify-content-center">
              {read_activities?.GAMES?.length > 0 ? (
                <table id="customers">
                  <tr>
                    <th>S.No</th>
                    <th className="pl-2">Brand</th>
                    <th>Name</th>
                    <th>Prize Name</th>
                    <th>Prize Picture</th>
                    <th>Lucky Draw</th>
                  </tr>
                  {read_activities?.GAMES?.map((item, key) => (
                    <tr>
                      <td>
                        <span className="game-played-activity-text">
                          {key + 1}.
                        </span>
                      </td>
                      <td>
                        <div className="headshot-games headshot-1 d-flex align-items-center justify-content-center">
                          <img
                            className=""
                            src={item?.BRAND?.display_picture}
                            alt=""
                            height={30}
                            width={50}
                          />
                        </div>
                      </td>
                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.GAME?.name}
                        </span>
                      </td>

                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.PRIZE?.name}
                        </span>
                      </td>
                      <td>
                        <img
                          className=""
                          src={item?.PRIZE?.display_picture}
                          alt=""
                          height={50}
                          style={{ width: "auto" }}
                        />
                      </td>
                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.LUCKY_DRAW}
                        </span>
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <div className="d-flex jutify-content-center m-5">
                  <h2 className="text-secondary proxima-font">
                    You haven't played any games yet.
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <h3 className="raleway-font gradient-color-text">
              Prizes You Have Won
            </h3>
            <hr />
            <div className="d-flex justify-content-center">
              {read_activities?.PRIZES?.length > 0 ? (
                <table id="customers">
                  <tr>
                    <th>S.No</th>
                    <th className="pl-2">Brand</th>
                    <th>Game Name</th>
                    <th>Prize Name</th>
                    <th>Prize Won</th>
                    <th>Lucky Draw</th>
                  </tr>
                  {read_activities?.PRIZES?.map((item, key) => (
                    <tr>
                      <td>
                        <span className="game-played-activity-text">
                          {key + 1}.
                        </span>
                      </td>
                      <td>
                        <div className="headshot-games headshot-1 d-flex align-items-center justify-content-center">
                          <img
                            className=""
                            src={item?.BRAND?.display_picture}
                            alt=""
                            height={30}
                            width={50}
                          />
                        </div>
                      </td>
                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.GAME?.name}
                        </span>
                      </td>

                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.PRIZE?.name}
                        </span>
                      </td>
                      <td>
                        <img
                          className=""
                          src={item?.PRIZE?.display_picture}
                          alt=""
                          height={50}
                          style={{ width: "auto" }}
                        />
                      </td>
                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.LUCKY_DRAW}
                          {/* {moment
                            .unix(item?.BRAND?.lucky_draw)
                            .format("DD-MM-YYYY")} */}
                        </span>
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <div className="d-flex jutify-content-center m-5">
                  <h2 className="text-secondary proxima-font">
                    You haven't won any prize yet.
                  </h2>
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-12 mt-4">
            <h3 className="raleway-font gradient-color-text">
              Rating & Reviews
            </h3>
            <hr />
            <div className="d-flex justify-content-center">
              {read_activities?.PRODUCTS?.length > 0 ? (
                <table id="customers">
                  <tr>
                    <th>S.No</th>
                    <th className="pl-2">Brand</th>
                    <th>Product Name</th>
                    <th>Product Picture</th>
                    <th>Rating</th>
                    <th className="d-flex justify-content-center">Review</th>
                  </tr>
                  {read_activities?.PRODUCTS?.map((item, key) => (
                    <tr>
                      <td>
                        <span className="game-played-activity-text">
                          {key + 1}.
                        </span>
                      </td>
                      <td>
                        <div className="headshot-games headshot-1 d-flex align-items-center justify-content-center">
                          <img
                            className=""
                            src={item?.PRODUCT?.brand}
                            alt=""
                            height={30}
                            width={50}
                          />
                        </div>
                      </td>
                      <td>
                        <span className="game-played-activity-text quicksand-font">
                          {item?.PRODUCT?.name}
                        </span>
                      </td>
                      <td>
                        <img
                          className=""
                          src={item?.PRODUCT?.image[0]}
                          alt=""
                          height={50}
                          style={{ width: "auto" }}
                        />
                      </td>
                      <td className="text-center">
                        <ReactStars
                          color="gray"
                          count={5}
                          size={24}
                          value={item?.RATING?.value}
                          activeColor="#ffd700"
                          edit={false}
                          // onChange={(count) => {
                          //   onRateProduct(count, item?.uid);
                          // }}
                        />
                      </td>

                      <td className="text-center">
                        <span className=" quicksand-font ">
                          {item?.RATING?.review
                            ? item?.RATING?.review
                            : "No review"}
                        </span>
                      </td>
                    </tr>
                  ))}
                </table>
              ) : (
                <div className="d-flex jutify-content-center m-5">
                  <h2 className="text-secondary proxima-font">
                    You are yet to rate or review a product.
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default YourActivityPage;
