import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toggleNavbar } from "../../api/slices/globalSlice";
import loginImage from "../../statics/7-login-banner.jpg";
import back from "../../statics/back.png";
import * as yup from "yup";
import { loginUser } from "../../api/slices/authSlice";
import { Puff } from "react-loader-spinner";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { facebookAppId, googleClientId } from "../../config";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Loader } from "../../components/common";
import { getFcmTokenFromLS } from "../../Utils/auth.util";
import { getFcmToken } from "../../services/notification-service";
import ForgetPassword from "../../components/layouts/modal/ForgetPassword";
import Otp from "../../components/layouts/modal/Otp";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, unverifiedUser } = useSelector((state) => state.AuthSlice);

  const [showForgetPasswordModal, setShowForgetPasswordModal] = useState(false);
  const [forgetPage, setForgetPage] = useState(0);
  const [otpEmail, setOtpEmail] = useState(null);
  const [otp, setOtp] = useState(null);
  const [pwType, setPwType] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    dispatch(toggleNavbar(false));
    getFcmToken();

    return () => {
      dispatch(toggleNavbar(true));
    };
  }, []);

  useEffect(() => {
    if (unverifiedUser?.email_address_is_verified == false) {
      setShowOtpModal(true);
    }
  }, [unverifiedUser]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const { register, handleSubmit } = useForm({});
  const onSubmit = async (data) => {
    getFcmTokenFromLS().then((token) => {
      let newData = { ...data, fcm_token: token, mode: "Normal" };
      dispatch(loginUser(newData)).then((v) => {
        if (v?.payload) {
          navigate("/");
        }
      });
    });
  };

  return (
    <>
      <div className="container-fluid back-login my-0">
        <div className="opactity-login-image"></div>
        <div className="container">
          <div className="row pt-5 pl-lg-5 login-row">
            <div className="col-lg-5 col-12">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img src={back} width="100px" />
              </div>
              <div className="heading-welcome mt-4">
                <h2 className="text-white">Welcome Back!</h2>
              </div>
              <div className="form-login mt-4">
                <form
                  id="contact_us_form"
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label
                          className="text-white max-head"
                          style={{ fontSize: "19px" }}
                          htmlFor="email"
                          id="email-label"
                        >
                          Email
                        </label>
                        <br />
                        <input
                          {...register("email_address")}
                          className="budgut-input"
                          type="email"
                          name="email_address"
                          style={{ width: "80%", color: "#fff" }}
                          id="email"
                          placeholder="Email"
                          required="required"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-group">
                        <label
                          className="text-white max-head"
                          style={{ fontSize: "19px" }}
                          htmlFor="password"
                          id="password-label"
                        >
                          Password
                        </label>
                        <br />

                        <input
                          {...register("password")}
                          type={pwType ? "text" : "password"}
                          name="password"
                          style={{ width: "80%", color: "#fff" }}
                          className="budgut-input"
                          id="password"
                          placeholder="Password"
                          required="required"
                        />
                        <span>
                          {pwType ? (
                            <i
                              className="password-icon-login fa fa-eye"
                              onClick={() => setPwType(!pwType)}
                            />
                          ) : (
                            <i
                              className="password-icon-login fa fa-eye-slash"
                              onClick={() => setPwType(!pwType)}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row m-0 p-0 " style={{ width: "82%" }}>
                    <div className="col-lg-12 text-right ">
                      <a
                        className="text-white cursor-pointer"
                        style={{ textDecoration: "underline" }}
                        onClick={() => setShowForgetPasswordModal(true)}
                      >
                        forgot your password?
                      </a>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-lg-12 ">
                      {loading ? (
                        <div
                          className="d-flex justify-content-center "
                          style={{ width: "80%" }}
                        >
                          <Puff color="#fff" height={30} width={60} />
                        </div>
                      ) : (
                        <button
                          type="submit"
                          className="contact-button "
                          style={{ width: "80%", cursor: "pointer" }}
                        >
                          Login
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row" style={{ width: "90%" }}>
                    <div className="col-12 text-center">
                      <div className="my-3 mx-0 px-0">
                        <span className="text-white">OR</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-12 col-10">
                      <FacebookLogin
                        appId={facebookAppId}
                        callback={async (data) => {
                          let apiData = {
                            oauth_code: data?.accessToken,
                            mode: "Facebook",
                            // fcm_token: fcm_token,
                          };

                          const res = await dispatch(loginUser(apiData));
                          if (res?.payload) {
                            navigate("/");
                          }
                        }}
                        scope="public_profile,user_friends,user_actions.books"
                        render={(renderProps) => (
                          <button
                            onClick={renderProps.onClick}
                            type="button"
                            className="google-button  py-2 px-2"
                          >
                            <i
                              className="fa fa-facebook "
                              style={{ color: "#fff" }}
                            />
                            <span className="mx-2 text-white">Facebook</span>
                          </button>
                        )}
                      />
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 col-10">
                      <GoogleLogin
                        clientId={googleClientId}
                        accessType="offline"
                        render={(renderProps) => (
                          <button
                            type="button"
                            className="google-button  py-2 px-2 "
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                          >
                            <i
                              className="fa fa-google"
                              style={{ color: "#fff" }}
                            />
                            <span className="text-white mx-2">Google</span>
                          </button>
                        )}
                        buttonText="Login"
                        onSuccess={async (data) => {
                          let apiData = {
                            oauth_code: data?.accessToken,
                            mode: "Google",
                            is_access_token: true,
                            // fcm_token: fcm_token,
                          };
                          let response = await dispatch(loginUser(apiData));
                          if (response.payload) {
                            navigate("/");
                          }
                        }}
                        onFailure={(data) => {}}
                        cookiePolicy={"single_host_origin"}
                        prompt="select_account"
                      />
                      {/* <GoogleLogout
                      clientId={googleClientId}
                      buttonText="Logout"
                      onLogoutSuccess={(data) => {
                        console.log("logoutData", data);
                      }}
                    ></GoogleLogout> */}
                    </div>
                  </div>
                  <div className="row mt-3" style={{ width: "80%" }}>
                    <div className="col-12 text-center">
                      <span className="text-white ">
                        Don't have an account?
                      </span>
                    </div>
                  </div>
                  <div className="row mt-2 mb-5" style={{ width: "80%" }}>
                    <Link to={"/brand-freak-register"} className="col-lg-12">
                      <div className="col-lg-12 col-12 d-flex justify-content-center">
                        <button
                          type="button"
                          className="create-account-button w-100 py-2 px-4 mx-3 cursor-pointer"
                        >
                          Create Account
                        </button>
                      </div>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-7"></div>
          </div>
        </div>
      </div>
      <ForgetPassword
        show={showForgetPasswordModal}
        onHide={() => setShowForgetPasswordModal(false)}
        page={forgetPage}
        setPage={(page) => setForgetPage(page)}
        otpEmail={otpEmail}
        otp={otp}
        handleEmail={(letter) => setOtpEmail(letter)}
        handleOtp={(letter) => setOtp(letter)}
      />
      <Otp
        show={showOtpModal}
        onHide={() => setShowOtpModal(false)}
        seconds={seconds}
        setSeconds={(second) => setSeconds(second)}
        unverifiedUser={unverifiedUser}
        otp={otp}
        handleOtp={(letter) => setOtp(letter)}
      />
    </>
  );
}
