import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { notificationKey } from "../config";
import { saveFcmTokenInLS } from "../Utils/auth.util";

const firebaseConfig = {
  apiKey: "AIzaSyBZ0pReZFp3l70TVBfusA5PJXUqkyL6q5k",
  authDomain: "brand-emirates.firebaseapp.com",
  projectId: "brand-emirates",
  storageBucket: "brand-emirates.appspot.com",
  messagingSenderId: "700027813317",
  appId: "1:700027813317:web:9aa1877adeaf3c0f46dfac",
  measurementId: "G-K1JMBDNPTN",
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDsJyf0tyoAV43DXm-d1n78OyChetWQ7KM",
//   authDomain: "admin-brand-emirates.firebaseapp.com",
//   projectId: "admin-brand-emirates",
//   storageBucket: "admin-brand-emirates.appspot.com",
//   messagingSenderId: "441542929663",
//   appId: "1:441542929663:web:5bc945fa6b77f372a330ca",
//   measurementId: "G-D2H8TMQ63V",
// };

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getFcmToken = () => {
  return getToken(messaging, { vapidKey: notificationKey })
    .then((currentToken) => {
      if (currentToken) {
        console.log("current token for client: ", currentToken);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        saveFcmTokenInLS(currentToken);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
