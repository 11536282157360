import localStore from "./localstore.util";
import { updateHeaders } from "../services/HttpProvider";
import { setUser } from "../api/slices/authSlice";

export const getToken = () => localStore.get_data("tokenBE");
export const setToken = (token) => localStore.store_data("tokenBE", token);
export const setUserRole = (token) =>
  localStore.store_data("userRoleBE", token);

export const getUser = () => localStore.get_data("userBE");
export const saveUser = (user) => localStore.store_data("userBE", user);
export const saveFcmTokenInLS = (token) =>
  localStore.store_data("FcmToken", token);
export const getFcmTokenFromLS = () => localStore.get_data("FcmToken");

export const logout = (dispatch) => {
  localStorage.clear();
  dispatch(setUser(null));
  dispatch(setUserRole(null));
  return true;
};

class Auth {
  constructor() {
    this.user = {};
  }

  async setUserFromLocal() {
    const user = await getToken();
    this.user = user ? user : {};
  }

  set setUser(user) {
    this.user = user;
  }

  get getUser() {
    return this.user;
  }

  async logout() {
    await logout();
    this.user = {};
  }
}

export const authClass = new Auth();

export function getAllDaysInMonth(year, month) {
  const date = new Date(year, month, 1);

  const dates = [];

  while (date.getMonth() === month) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
}

export const getArrayOfYears = function (date1, date2) {
  var arrayOfYears = [];

  // Get the years
  date1 = parseInt(date1.substring(4), 10);
  date2 = parseInt(date2.substring(4), 10);

  // Order the years
  if (date2 > date1) {
    var tmp = date2;
    date2 = date1;
    date1 = tmp;
  }

  // Loop them
  var dif = date1 - date2;
  for (var i = 0; i <= dif; i++) {
    arrayOfYears[arrayOfYears.length] = date1 - i;
  }

  return arrayOfYears;
};

export const getLabelFromId = (list, id) => {
  let li = list.filter((item) => item?.name == id);
  return li[0]?.name;
};
