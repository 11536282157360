import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../statics/comingsoon/logo.png";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Puff } from "react-loader-spinner";
import xmark from "../statics/xmark-solid.svg";

import * as yup from "yup";
import {
  registerBrandFreak,
  saveBrandFreakRegistrationInfo,
} from "../api/slices/registrationSlice";
import { getAllDaysInMonth, getArrayOfYears } from "../Utils/auth.util";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  DayPicker,
  DropdownDate,
  MonthPicker,
  YearPicker,
} from "react-dropdown-date";
import { toast } from "react-toastify";
import ImagePickerModal from "../components/layouts/modal/ImagePickerModal";
import PreviewImageModal from "../components/layouts/modal/PreviewImageModal";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import Select from "react-select";
import { toggleNavbar } from "../api/slices/globalSlice";
import {
  resendBrandOtp,
  resendPhoneOtp,
  verifyBrandOtp,
  verifyOtpPhone,
} from "../api/slices/authSlice";
export default function BrandRegistration(props) {
  const [imageModal, setImageModal] = useState(false);
  const [displayimageModal, setDisplayImageModal] = useState(false);
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const [country, setCountry] = useState(null);
  const [phone, setPhone] = useState(null);

  const [error, setErrors] = useState(null);
  let tagName = null;
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);

  const [day, setDay] = useState(null);
  const [type, setType] = useState(false);
  const [typeConfirm, setTypeConfirm] = useState(false);
  const [otp, setOtp] = useState(null);
  const [seconds, setSeconds] = useState(0);
  const [step, setStep] = useState("User Profile");
  const [sendOtpVia, setSendOtpVia] = useState("email");

  const { brandFreakInfoOne, loading, registeringBrand } = useSelector(
    (state) => state.registrationReducer
  );
  const schema = yup
    .object({
      name: yup.string().required("Please Enter your name"),
      email_address: yup.string().required("Please Enter your email"),

      password: yup
        .string()
        .required("Please Enter your password")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirm_password: yup
        .string()
        .required("Please Enter your password")
        .oneOf([yup.ref("password")], "Your passwords do not match.")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    })
    .required();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    dispatch(toggleNavbar(false));

    return () => {
      dispatch(toggleNavbar(true));
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();

  const onSubmit = (data) => {
    if (!phone) {
      setErrors({
        phone: { message: "please enter your phone" },
      });
    }
    if (!country) {
      setErrors({
        ...errors,
        country: { message: "please enter your country" },
      });
    }
    if (phone && country) {
      dispatch(
        saveBrandFreakRegistrationInfo({
          phone_number: "+" + phone,
          country: country?.label,
          ...data,
        })
      );
      setStep("Basic Information");
    }
  };
  const onSubmit1 = async (data) => {
    let mon = Number(month);
    let m = mon + 1;
    let apiData = {
      ...brandFreakInfoOne,
      date_of_birth: year + "-" + m + "-" + day,

      gender: JSON.parse(data?.gender),
      channel: sendOtpVia,
    };
    let res = await dispatch(registerBrandFreak(apiData));
    if (res?.payload) {
      setStep("otp");
      setSeconds(59);
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="container-fluid user-type-back p-5">
        <div className="container brand-reg-registration ">
          <div className="row  p-4">
            <div className="col-lg-6 ">
              <img
                src={logo}
                width={"auto"}
                height={"50px"}
                onClick={() => {
                  navigate("/");
                }}
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <div className="">
                <span
                  className="brand-child-button"
                  onClick={() => {
                    navigate("/user-types");
                  }}
                  style={{ fontFamily: "Quick sand bold" }}
                >
                  <i
                    className="fas fa-less-than mr-2"
                    style={{ fontSize: "16px" }}
                  ></i>{" "}
                  Go Back
                </span>
              </div>
            </div>
          </div>
          <div className="row m-5 user-profile">
            <div className="col-lg-6">
              <h6
                className="text-white"
                style={{ fontFamily: "Quick sand reg" }}
              >
                Sign Up{" "}
              </h6>
              <h2 className="text-white" style={{ fontFamily: "Athelas Reg" }}>
                Brand Freak
              </h2>
              <p
                className="text-white"
                style={{ fontFamily: "Quick sand reg" }}
              >
                Register Yourself as Brand Freak{" "}
              </p>
              <span>
                <hr className="register-hr" />
              </span>

              <div
                className="mt-5  text-white registration-steppar"
                style={{ borderLeft: "1px solid #fff" }}
              >
                <p
                  style={{
                    borderLeft:
                      step === "User Profile" ? "4px solid #F4A950" : "none",
                    marginLeft: step === "User Profile" ? "-2px" : "0px",
                    fontFamily: "Avantgrade Demi",
                  }}
                  className={
                    step === "User Profile"
                      ? "steppar-item-active pl-3"
                      : "steppar-item pl-3"
                  }
                >
                  User Profile
                </p>
                <p
                  style={{
                    borderLeft:
                      step === "Basic Information"
                        ? "4px solid #F4A950"
                        : "none",
                    marginLeft: step === "Basic Information" ? "-2px" : "0px",
                    fontFamily: "Avantgrade Demi",
                  }}
                  className={
                    step === "Basic Information"
                      ? "steppar-item-active pl-3"
                      : "steppar-item pl-3"
                  }
                >
                  Basic Information & Other
                </p>
                <p
                  style={{
                    borderLeft: step === "otp" ? "4px solid #F4A950" : "none",
                    marginLeft: step === "otp" ? "-2px" : "0px",
                    fontFamily: "Avantgrade Demi",
                  }}
                  className={
                    step === "otp"
                      ? "steppar-item-active pl-3"
                      : "steppar-item pl-3"
                  }
                >
                  Verification
                </p>
              </div>
            </div>
            <div className="col-lg-6 p-5 mb-5 d-flex justfify-content-end brand-form">
              {step === "User Profile" ? (
                <div className="row w-100">
                  <div className="col-12">
                    <h1 style={{ fontFamily: "Avantgrade Book" }}>
                      User Profile
                    </h1>
                    <hr className="reg-form-hr" />
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Full Name *
                          </span>
                          <input
                            {...register("name")}
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100"
                            placeholder="YOUR NAME"
                          />

                          {errors.name && (
                            <p className="error-message">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Email Address *
                          </span>
                          <input
                            {...register("email_address")}
                            style={{ border: "2px solid #7E8084" }}
                            type="email"
                            className="budgut-input-reg mt-2 w-100"
                            placeholder="YOUR EMAIL"
                          />
                          {errors.email_address && (
                            <p className="error-message">
                              {errors?.email_address?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Password *
                          </span>
                          <input
                            {...register("password")}
                            type={type ? "text" : "password"}
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100 "
                            placeholder="YOUR PASSWORD"
                          />
                          <span>
                            {type ? (
                              <i
                                className="password-icon fa fa-eye"
                                onClick={() => setType(!type)}
                              />
                            ) : (
                              <i
                                className="password-icon fa fa-eye-slash"
                                onClick={() => setType(!type)}
                              />
                            )}
                          </span>
                          {errors.password && (
                            <p className="error-message">
                              {errors?.password?.message}.
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Confirm Password *
                          </span>
                          <input
                            {...register("confirm_password")}
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100"
                            type={typeConfirm ? "text" : "password"}
                            placeholder="YOUR PASSWORD"
                          />
                          <span>
                            {typeConfirm ? (
                              <i
                                className="password-icon fa fa-eye"
                                onClick={() => setTypeConfirm(!typeConfirm)}
                              />
                            ) : (
                              <i
                                className="password-icon fa fa-eye-slash"
                                onClick={() => setTypeConfirm(!typeConfirm)}
                              />
                            )}
                          </span>
                          {errors.confirm_password && (
                            <p className="error-message">
                              {errors?.confirm_password?.message}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-lg-6 mt-3">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Country *
                          </span>

                          <Select
                            name="country"
                            className="mt-2"
                            styles={{ height: "100px" }}
                            onChange={(e) => setCountry(e)}
                            options={options}
                            defaultValue={country}
                          />
                          {error?.country && (
                            <p className="error-message">
                              {error?.country?.message}
                            </p>
                          )}
                        </div>
                        <div className="col-lg-6 col-md-12 mt-3">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Phone Number *
                          </span>
                          <PhoneInput
                            className="mt-1 "
                            name="phone"
                            country={"ae"}
                            value={phone}
                            onChange={(phone) => setPhone(phone)}
                          />
                          {error?.phone && (
                            <p className="error-message">
                              {error?.phone?.message}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-12 d-flex justify-content-end">
                          <button type="submit" className="home-brand-button">
                            NEXT
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : step === "Basic Information" ? (
                <div className="row w-100">
                  <div className="col-12">
                    <h1 style={{ fontFamily: "Avantgrade Book" }}>
                      Basic Information
                    </h1>
                    <hr className="reg-form-hr" />
                    <form onSubmit={handleSubmit1(onSubmit1)}>
                      <div className="row mt-3">
                        <div className="col-lg-4">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Gender *
                          </span>
                          <select
                            {...register1("gender", { required: true })}
                            style={{ border: "2px solid #7E8084" }}
                            className="budgut-input-reg mt-2 w-100"
                            placeholder="ENTER AMOUNT IN AED"
                            required
                          >
                            <option value="" disabled selected>
                              Gender
                            </option>
                            <option
                              value={JSON.stringify({ id: "1", name: "male" })}
                            >
                              Male
                            </option>
                            <option
                              value={JSON.stringify({
                                id: "2",
                                name: "female",
                              })}
                            >
                              Female
                            </option>
                            <option
                              value={JSON.stringify({ id: "3", name: "other" })}
                            >
                              Other
                            </option>
                          </select>
                        </div>
                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-12">
                              <span
                                className=" max-head"
                                style={{
                                  fontSize: "19px",
                                  fontFamily: "Avantgrade Book",
                                }}
                              >
                                Date Of Birth (optional)
                              </span>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-4">
                              <YearPicker
                                defaultValue={"Year"}
                                reverse // default is ASCENDING
                                value={year} // mandatory
                                onChange={(year) => {
                                  setYear(year);
                                }}
                                id={"year"}
                                name={"year"}
                                classes="date-of-birth-input mt-2 w-100"
                              />
                            </div>
                            <div className="col-lg-4">
                              <MonthPicker
                                defaultValue={"Month"}
                                // to get months as numbers
                                short // default is full name
                                caps // default is Titlecase
                                endYearGiven // mandatory if end={} is given in YearPicker
                                year={year} // mandatory
                                value={month} // mandatory
                                onChange={(month) => {
                                  setMonth(month);
                                }}
                                id={"month"}
                                name={"month"}
                                classes="date-of-birth-input mt-2 w-100"
                              />
                            </div>
                            <div className="col-lg-4">
                              <DayPicker
                                defaultValue={"Day"}
                                year={year} // mandatory
                                month={month} // mandatory
                                endYearGiven // mandatory if end={} is given in YearPicker
                                value={day} // mandatory
                                onChange={(day) => {
                                  setDay(day);
                                }}
                                id={"day"}
                                name={"day"}
                                classes="date-of-birth-input mt-2 w-100"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12 mt-3">
                          <span
                            className=" max-head"
                            style={{
                              fontSize: "19px",
                              fontFamily: "Avantgrade Book",
                            }}
                          >
                            Verify OTP via :
                          </span>

                          <label class="container">
                            <input
                              type="radio"
                              name="radio"
                              // checked="checked"
                              defaultChecked="checked"
                              onChange={() => setSendOtpVia("email")}
                            />
                            <span class="checkmark"></span>
                            Email
                          </label>
                          <label class="container">
                            <input
                              type="radio"
                              // checked="checked"
                              name="radio"
                              onChange={() => setSendOtpVia("phone")}
                            />
                            <span class="checkmark"></span>
                            Phone Number
                          </label>
                        </div>
                      </div>

                      <div className="row mt-5">
                        <div className="col-lg-6 d-flex justify-content-start back-button-freak">
                          <button
                            className="home-brand-button"
                            type="button"
                            onClick={() => {
                              setStep("User Profile");
                            }}
                            style={{
                              color: "#8D8F92",
                              backgroundColor: "#fff",
                            }}
                          >
                            BACK
                          </button>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-end">
                          {loading ? (
                            <div className="text-center">
                              <Puff color="#000" height={30} width={60} />
                            </div>
                          ) : (
                            <button
                              className="home-brand-button"
                              type="submit"
                              style={{ fontFamily: "Avantgrade Demi" }}
                            >
                              Sign Up
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : step === "otp" ? (
                <div className="row w-100">
                  <div className="col-12">
                    <p>
                      {sendOtpVia == "email"
                        ? "We Have Sent you an OTP on your Email Address. Please Enter OTP to Verify your Email Address"
                        : "We Have Sent you an OTP on your Phone Number. Please Enter OTP to Verify your Phone Number"}
                    </p>
                    <input
                      type="text"
                      name="name"
                      style={{ border: "2px solid #7E8084" }}
                      onChange={(e) => setOtp(e?.target?.value)}
                      className="budgut-input mt-2 w-100"
                    />
                  </div>

                  <div className="col-2 d-flex justify-content-center"></div>

                  <div className="col-4 d-flex justify-content-center mt-3">
                    <button
                      style={{
                        fontFamily: "Avantgrade Demi",
                        opacity: seconds > 0 ? "0.5" : "1",
                      }}
                      className="home-brand-button d-flex flex-row"
                      disabled={seconds > 0}
                      onClick={async () => {
                        let apiData = {
                          uid: registeringBrand?.uid,
                        };
                        let res =
                          sendOtpVia == "email"
                            ? await dispatch(resendBrandOtp(apiData))
                            : await dispatch(resendPhoneOtp(apiData));
                        if (res?.payload) {
                          setSeconds(59);
                        }
                      }}
                    >
                      Resend
                      {seconds > 0 && (
                        <span className="pl-2">
                          {`00:${seconds < 10 ? "0" : ""}${seconds}`}
                        </span>
                      )}
                    </button>
                  </div>

                  <div className="col-3 d-flex justify-content-center mt-3 ml-4">
                    <button
                      style={{ fontFamily: "Avantgrade Demi" }}
                      className="home-brand-button"
                      onClick={async () => {
                        if (!otp) {
                          toast.error("Enter OTP");
                        } else {
                          let apiData = {
                            uid: registeringBrand?.uid,
                            token: otp,
                          };

                          let res =
                            sendOtpVia == "email"
                              ? await dispatch(verifyBrandOtp(apiData))
                              : await dispatch(verifyOtpPhone(apiData));
                          if (res?.payload) {
                            navigate("/login");
                            // window.open(
                            //   "https://dashboard-brandemirates.web.app/",
                            //   "_blank"
                            //   // "http://brandemirates.com/#/brand-register",
                            //   // "_blank"
                            // );
                          }
                        }
                      }}
                    >
                      Verify
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ImagePickerModal
        show={imageModal}
        onHide={() => setImageModal(false)}
        file={file}
        setFile={setFile}
        setFileName={setFileName}
        {...props}
      />
      <PreviewImageModal
        show={displayimageModal}
        onHide={() => setDisplayImageModal(!displayimageModal)}
        image={file}
      />
    </>
  );
}
