import Slider from "react-slick";

const GenderBrandSlider = ({ brands, goToBrandProfile }) => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
    rows: 1,
    slidesPerRow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          speed: 3000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          infinite: true,
          autoplay: true,
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          autoplaySpeed: 5000,
          lazyLoad: "progressive",
          transitionDuration: 3000,
          centerPadding: "0",
          infinite: false,
          autoplay: false,
          dots: false,
          slidesToShow: 1,
          initialSlide: 1,
          slidesPerRow: 3,
        },
      },
    ],
  };
  return (
    <div className="col-11 mx-lg-5 mb-lg-4 mx-3 gender-brand-slider-div">
      {brands?.length > 0 ? (
        <Slider {...settings}>
          {brands?.map((v, i) => (
            <div
              onClick={() => goToBrandProfile(v)}
              key={i}
              className="gender-brand-slider "
            >
              <img
                className="gender-brand-slider-img"
                src={v?.display_picture}
                alt=""
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center quicksand-font pt-3"
          style={{ fontSize: "40px" }}
        >
          <b>No Brand Found!</b>
        </div>
      )}
    </div>
  );
};

export default GenderBrandSlider;
