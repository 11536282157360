import React, { useState } from "react";
import { Modal } from "react-bootstrap";
export default function PreviewImageModal({ show, onHide, image }) {
  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Preview Image
          </Modal.Title>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <img src={image} width="100%" />
        </Modal.Body>
      </Modal>
    </>
  );
}
