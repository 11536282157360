import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import GoogleMapReact from "google-map-react";
import LocationPin from "./helper/LocationPin";

export default function StoreInformationModal({ show, onHide, data, website }) {
  return (
    <Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ background: "#000" }}>
        <Modal.Title id="contained-modal-title-vcenter" className="text-white">
          Store
        </Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true" className="text-white">
            ×
          </span>
          <span className="sr-only"></span>
        </button>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div style={{ width: "100%", height: 250 }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBZ0pReZFp3l70TVBfusA5PJXUqkyL6q5k",
            }}
            defaultCenter={{
              lat: data?.location?.latitude,
              lng: data?.location?.longitude,
            }}
            defaultZoom={12}
          >
            <LocationPin
              lat={data?.location.latitude}
              lng={data?.location.longitude}
            />
          </GoogleMapReact>
        </div>
        <div className="">
          <h4 className="mt-3 py-2 px-2 mx-3">{"Store Information"}</h4>
          <div className="row mx-0 mb-4 px-2">
            <div className="col-lg-5">
              <div style={{ color: "#F4A950" }}>Store Name</div>
              <span>{data?.name}</span>

              <div className="mt-3" style={{ color: "#F4A950" }}>
                Website
              </div>
              <span>{website}</span>
              <div className="mt-3" style={{ color: "#F4A950" }}>
                Product Type
              </div>
              <div className="row">
                {data?.offered_product_types?.map((it, index) => (
                  <div className=" col-5 ml-3 prod-type-tabs mb-1">
                    <div className="">{it?.name}</div>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5">
              <div style={{ color: "#F4A950" }}>Social Media</div>
              <span>
                <i
                  style={{ fontSize: "19px" }}
                  className="mr-2 fa fa-facebook-f"
                />
                <i
                  style={{ fontSize: "19px" }}
                  className="mr-2 fa fa-instagram"
                />
                <i
                  style={{ fontSize: "19px" }}
                  className="mr-2 fa fa-pinterest"
                />
                <i
                  style={{ fontSize: "19px" }}
                  className="mr-2 fa fa-linkedin-in"
                ></i>
                <i style={{ fontSize: "19px" }} className=" fa fa-youtube" />
              </span>
              <div className="mt-3" style={{ color: "#F4A950" }}>
                Phone Number
              </div>
              <div>{data?.phone_number}</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
