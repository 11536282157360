import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";
import apiServices from "../../services/requestHandler";
import { saveUser } from "../../Utils/auth.util";
import { setUser } from "./authSlice";

const initial_state = {
  brands: [],
  mens_brands: null,
  womens_brands: null,
  loading: false,
  featuredBrands: [],
  brandOffers: [],
  trendingProducts: [],
  offers_under_budget: null,
  sponsors: null,
  contact_loading: false,
  analytics: null,
  rate_brands: null,
  buy_brands: null,
  guess_brands: null,
  rate_brands_loading: false,
  buy_brands_loading: false,
  guess_brands_loading: false,
  questions_loading: false,
  questions: null,
  guess_win_loading: false,
  favourite_loading: false,
  clicks_loading: false,
  opinions: null,
  opinions_loading: false,
  budget_loading: false,
  all_offers: null,
  product_types: null,
  read_cities: null,
  cities_loading: false,
  read_outlets: null,
  stores_loading: false,
  read_activities: null,
  activity_loading: false,
  read_notifications: null,
  notification_loading: false,
  viewed_notifications: null,
  send_email_loading: false,
  featured_brands_loading: false,
};

export const readBrands = createAsyncThunk(
  "readBrands",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readBrands(
        `?timestamp=${moment(new Date())
          .add(moment(new Date()).utcOffset(), "minutes")
          .utc()
          .unix()}`
      );
      if (response?.data?.response_code == 200) {
        const allBrands = response?.data?.response_data?.brands;
        thunkAPI.dispatch(
          setBrands(allBrands?.filter((v) => v?.product?.length > 0))
        );
      } else {
        toast.error(response?.data?.response_message);
        thunkAPI.dispatch(setBrands([]));
      }
    } catch (e) {
      console.log("This is Error from read Brands", e);
    }
  }
);
export const readFeatureedBrands = createAsyncThunk(
  "featured/brands/read",
  async () => {
    try {
      const res = await apiServices.readFeaturedBrands();
      if (res?.data?.response_code === 200) {
        return res?.data?.response_data?.featured_brands;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
export const readActivities = createAsyncThunk(
  "readActivities",
  async (data, thunkAPI) => {
    try {
      const res = await apiServices.yourActivity();
      if (res?.data?.response_code === 200) {
        setActivities(res?.data?.response_data);
        thunkAPI.dispatch(setActivities(res?.data?.response_data));
        // return res?.data?.response_data;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
export const readNotifications = createAsyncThunk(
  "readNotifications",
  async (data, thunkAPI) => {
    try {
      const res = await apiServices.readNotifications();
      if (res?.data?.response_code === 200) {
        setNotifications(res?.data?.response_data);
        thunkAPI.dispatch(setNotifications(res?.data?.response_data));
        // return res?.data?.response_data;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
export const readBrandOffers = createAsyncThunk(
  "offers/brands/read",
  async () => {
    try {
      const res = await apiServices.readBrandOffers();
      if (res?.data?.response_code === 200) {
        return res?.data?.response_data?.featured_brand_offers;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
export const readTrendingProducts = createAsyncThunk(
  "products/Trending/read",
  async () => {
    try {
      const res = await apiServices.readTrendingProducts();
      if (res?.data?.response_code === 200) {
        return res?.data?.response_data?.trending;
      } else {
        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
// export const readOffersUnderBudgut = createAsyncThunk(
//   "offers/budgut/read",
//   async (data) => {
//     try {
//       const res = await apiServices.readOfferUnderBudgut(data);
//       if (res?.data?.response_code === 200) {
//         return res?.data?.response_data;
//       } else {
//         return [];
//       }
//     } catch (e) {
//       console.log(e, "error");
//     }
//   }
// );
export const readPartners = createAsyncThunk(
  "partner/read",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readPartners(data);
      if (response?.data?.response_code === 200) {
        thunkAPI.dispatch(
          setSponsors(
            response?.data?.response_data?.sponsors?.filter(
              (v) =>
                v?.hasOwnProperty("display_picture") &&
                v?.display_picture !== ""
            )
          )
        );
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);
export const buyAndWin = createAsyncThunk("buyAndWin/create", async (data) => {
  try {
    const res = await apiServices.buyAndWin(data?.apiData);
    if (res?.data?.response_code === 200) {
      data?.closeModal();
      toast.success(res?.data?.response_message);
    } else {
      data?.closeModal();
      toast.error(res?.data?.response_message);
    }
  } catch (e) {
    console.log(e, "error");
  }
});
export const rateAndWin = createAsyncThunk(
  "rateAndWin/create",
  async (data) => {
    try {
      const res = await apiServices.rateAndWin(data?.apiData);
      if (res?.data?.response_code === 200) {
        data?.clearState();
        toast.success(res?.data?.response_message);
        return res?.data?.response_data;
      } else {
        data?.clearState();
        toast.error(res?.data?.response_message);

        return [];
      }
    } catch (e) {
      console.log(e, "error");
    }
  }
);

export const sendMessage = createAsyncThunk("SendMessage", async (data) => {
  try {
    const response = await apiServices.sendMessage(data);
    if (response?.data?.response_code == 200) {
      toast.success(response?.data?.response_message);
      return true;
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (e) {
    console.log("This is Error from send Message", e);
  }
});

export const stayInTouch = createAsyncThunk("stayInTouch", async (data) => {
  try {
    const response = await apiServices.stayInTouch(data);
    if (response?.data?.response_code == 200) {
      toast.success(response?.data?.response_message);
      return true;
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (e) {
    console.log("This is Error from send Message", e);
  }
});

export const updateProductsClick = createAsyncThunk(
  "updateProductsClicks",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.updateClicks({ uid: data?.uid });
      if (response?.response_code == 200) {
        window
          .open(response?.response_data?.product?.product_url, "_blank")
          ?.focus();
      } else {
        toast.error(response?.response_message);
      }
    } catch (e) {
      console.log("This is Error from Update Clicks API", e);
    }
  }
);
export const getAnalytics = createAsyncThunk(
  "getAnalytics",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getAnalytics();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setAnalytics(response?.data?.response_data));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get analytics", e);
    }
  }
);
export const getRateBrands = createAsyncThunk(
  "getRateBrands",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getRateBrands();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setRateBrands(response?.data?.response_data?.brands));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get Rate Brands", e);
    }
  }
);
export const getBuyBrands = createAsyncThunk(
  "getBuyBrands",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getBuyBrands();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setBuyBrands(response?.data?.response_data?.brands));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get buy Brands", e);
    }
  }
);

export const getGuessBrands = createAsyncThunk(
  "getGuessBrands",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getGuessBrands();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(
          setGuessBrands(response?.data?.response_data?.brands)
        );
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get guess Brands", e);
    }
  }
);
export const readQuestions = createAsyncThunk(
  "readQuestions",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getBrandQuestions(data);
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(
          setQuestions(response?.data?.response_data?.game?.question_answer)
        );
      } else {
        data?.goBack();
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is error from read questionsss", e);
    }
  }
);
export const guessAndWin = createAsyncThunk("GuessAndWin", async (data) => {
  try {
    const response = await apiServices.guessAndWin(data?.apiData);
    if (response?.data?.response_code == 200) {
      toast.success(response?.data?.response_message);
      data?.goBack();
    } else {
      toast.error(response?.data?.response_message);
    }
  } catch (e) {
    console.log("This is Error from Guess and Win", e);
  }
});
export const favouriteBrand = createAsyncThunk(
  "FavouriteBrands",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.favouriteBrands(data);
      if (response?.response_code == 200) {
        thunkAPI.dispatch(setUser(response?.response_data?.user));
        const isSaved = await saveUser(response?.response_data?.user);
        if (isSaved) {
          toast.success(response?.response_message);
        }
      } else {
        toast.error(response?.response_message);
      }
    } catch (e) {
      console.log("This is Error from fav brands");
    }
  }
);
export const viewedNotifications = createAsyncThunk(
  "viewedNotifications",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.viewedNotification(data);
      console.log("viewedNotifications", response);
      if (response?.response_code == 200) {
        // thunkAPI.dispatch(setViewedNotifications(response?.response_data));
        thunkAPI.dispatch(setNotifications(response?.response_data));
        return response?.response_data;
      } else {
        toast.error(response?.response_message);
      }
    } catch (e) {
      console.log("This is Error from fav brands");
    }
  }
);
export const opinionMatters = createAsyncThunk(
  "OpinionMatters",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.opinionMatters(data);
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setOpinions(response?.data?.response_data?.opinions));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from Opinions Matters", e);
    }
  }
);
export const getOfferUnderBudget = createAsyncThunk(
  "getOfferUnderBudget",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.getOfferUnderBudget(
        `?min=${data?.min}&max=${data?.max}&brand=${data?.brand}`
      );
      console.log("getOfferUnderBudget", response);
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(
          setOffersInBudget(response?.data?.response_data?.products)
        );
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from Opinions Matters", e);
    }
  }
);
export const readAllOffers = createAsyncThunk(
  "readAllOffers",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readAllOffers();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setAllOffers(response?.data?.response_data?.offers));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get buy Brands", e);
    }
  }
);
export const readProductTypes = createAsyncThunk(
  "readProductTypes",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readProductTypes();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(setProductTypes(response?.data?.response_data));
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get buy Brands", e);
    }
  }
);
export const readStoreCities = createAsyncThunk(
  "readStoreCities",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readBrandCities();
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(
          setBrandStores(response?.data?.response_data?.cities)
        );
      } else {
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get", e);
    }
  }
);
export const readBrandOutlets = createAsyncThunk(
  "readBrandOutlets",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.readOutlets(
        `?brand=${data?.brand}&city=${data?.city}`
      );
      if (response?.data?.response_code == 200) {
        thunkAPI.dispatch(
          setBrandOutlets(response?.data?.response_data?.stores)
        );
      } else {
        thunkAPI.dispatch(setBrandOutlets(null));
        toast.error(response?.data?.response_message);
      }
    } catch (e) {
      console.log("This is Error from get", e);
    }
  }
);

export const rateProduct = createAsyncThunk(
  "rateProduct",
  async (data, thunkAPI) => {
    try {
      const response = await apiServices.rateProduct(data);
      if (response?.response_code == 200) {
        toast.success(response?.response_message);
        // thunkAPI.dispatch(setUser(response?.response_data?.user));
        return true;
      } else {
        toast.error(response?.response_message);
      }
    } catch (e) {
      toast.error("Something went Wrong");
      console.log("This is Error from rateProduct", e);
    }
  }
);

const webSlice = createSlice({
  name: "Website Slice",
  initialState: initial_state,
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setActivities: (state, action) => {
      state.read_activities = action.payload;
    },
    setNotifications: (state, action) => {
      state.read_notifications = action.payload;
    },
    setViewedNotifications: (state, action) => {
      state.viewed_notifications = action.payload;
    },
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    },
    setSponsors: (state, action) => {
      state.sponsors = action.payload;
    },
    setRateBrands: (state, action) => {
      state.rate_brands = action.payload;
    },
    setBuyBrands: (state, action) => {
      state.buy_brands = action.payload;
    },

    setAllOffers: (state, action) => {
      state.all_offers = action.payload;
    },

    setProductTypes: (state, action) => {
      state.product_types = action.payload;
    },
    setBrandStores: (state, action) => {
      state.read_cities = action.payload;
    },
    setBrandOutlets: (state, action) => {
      state.read_outlets = action.payload;
    },

    setGuessBrands: (state, action) => {
      state.guess_brands = action.payload;
    },
    setQuestions: (state, action) => {
      state.questions = action.payload;
    },
    setOpinions: (state, action) => {
      state.opinions = action.payload;
    },
    setOffersInBudget: (state, action) => {
      state.offers_under_budget = action.payload;
    },
    filterBrand: (state, action) => {
      const allBrands = action.payload.brands;
      const filter = action.payload.filter;
      const filteredBrands = allBrands?.filter(
        (v) => v?.gender?.name?.toLowerCase() == filter.toLowerCase()
      );
      filter.toLowerCase() == "male"
        ? (state.mens_brands = filteredBrands)
        : (state.womens_brands = filteredBrands);
    },
  },
  extraReducers: {
    [readBrands.pending]: (state) => {
      state.loading = true;
    },
    [readBrands.fulfilled]: (state) => {
      state.loading = false;
    },
    [readBrands.rejected]: (state) => {
      state.loading = false;
    },
    [readActivities.pending]: (state) => {
      state.activity_loading = true;
    },
    [readActivities.fulfilled]: (state) => {
      state.activity_loading = false;
    },
    [readActivities.rejected]: (state) => {
      state.activity_loading = false;
    },
    [readNotifications.pending]: (state) => {
      state.notification_loading = true;
    },
    [readNotifications.fulfilled]: (state) => {
      state.notification_loading = false;
    },
    [readNotifications.rejected]: (state) => {
      state.notification_loading = false;
    },
    [readFeatureedBrands.pending]: (state) => {
      state.featured_brands_loading = true;
    },
    [readFeatureedBrands.fulfilled]: (state, action) => {
      state.featured_brands_loading = false;
      state.featuredBrands = action.payload;
    },
    [readFeatureedBrands.rejected]: (state) => {
      state.featured_brands_loading = false;
    },
    [readBrandOffers.pending]: (state) => {
      state.loading = true;
    },
    [readBrandOffers.fulfilled]: (state, action) => {
      state.loading = false;
      state.brandOffers = action.payload;
    },
    [readBrandOffers.pending]: (state) => {
      state.loading = false;
    },
    [readTrendingProducts.pending]: (state) => {
      state.loading = true;
    },
    [readTrendingProducts.fulfilled]: (state, action) => {
      state.loading = false;
      state.trendingProducts = action.payload;
    },
    [readTrendingProducts.pending]: (state) => {
      state.loading = false;
    },

    [readPartners.pending]: (state) => {
      state.loading = true;
    },
    [readPartners.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [readPartners.fulfilled]: (state) => {
      state.loading = false;
    },
    [buyAndWin.pending]: (state) => {
      state.loading = true;
    },
    [buyAndWin.fulfilled]: (state) => {
      state.loading = false;
      // state.partner = action.payload;
    },
    [buyAndWin.pending]: (state) => {
      state.loading = false;
    },
    [sendMessage.pending]: (state) => {
      state.contact_loading = true;
    },
    [sendMessage.fulfilled]: (state) => {
      state.contact_loading = false;
    },
    [sendMessage.rejected]: (state) => {
      state.contact_loading = false;
    },
    [rateAndWin.pending]: (state) => {
      state.loading = true;
    },
    [rateAndWin.fulfilled]: (state) => {
      state.loading = false;
      // state.partner = action.payload;
    },
    [rateAndWin.pending]: (state) => {
      state.loading = false;
    },
    [getRateBrands.pending]: (state) => {
      state.rate_brands_loading = true;
    },
    [getRateBrands.fulfilled]: (state) => {
      state.rate_brands_loading = false;
    },
    [getRateBrands.rejected]: (state) => {
      state.rate_brands_loading = false;
    },
    [getBuyBrands.pending]: (state) => {
      state.buy_brands_loading = true;
    },
    [getBuyBrands.fulfilled]: (state) => {
      state.buy_brands_loading = false;
    },
    [getBuyBrands.rejected]: (state) => {
      state.buy_brands_loading = false;
    },
    [getGuessBrands.pending]: (state) => {
      state.guess_brands_loading = true;
    },
    [getGuessBrands.fulfilled]: (state) => {
      state.guess_brands_loading = false;
    },
    [getGuessBrands.rejected]: (state) => {
      state.guess_brands_loading = false;
    },
    [readQuestions.pending]: (state) => {
      state.questions_loading = true;
    },
    [readQuestions.fulfilled]: (state) => {
      state.questions_loading = false;
    },
    [readQuestions.rejected]: (state) => {
      state.questions_loading = false;
    },
    [guessAndWin.pending]: (state) => {
      state.guess_win_loading = true;
    },
    [guessAndWin.fulfilled]: (state) => {
      state.guess_win_loading = false;
    },
    [guessAndWin.rejected]: (state) => {
      state.guess_win_loading = false;
    },
    [favouriteBrand.pending]: (state) => {
      state.favourite_loading = true;
    },
    [favouriteBrand.fulfilled]: (state) => {
      state.favourite_loading = false;
    },
    [favouriteBrand.rejected]: (state) => {
      state.favourite_loading = false;
    },
    [updateProductsClick.pending]: (state) => {
      state.clicks_loading = true;
    },
    [updateProductsClick.fulfilled]: (state) => {
      state.clicks_loading = false;
    },
    [updateProductsClick.rejected]: (state) => {
      state.clicks_loading = false;
    },
    [opinionMatters.pending]: (state) => {
      state.opinions_loading = true;
    },
    [opinionMatters.fulfilled]: (state) => {
      state.opinions_loading = false;
    },
    [opinionMatters.rejected]: (state) => {
      state.opinions_loading = false;
    },
    [readBrandOutlets.pending]: (state) => {
      state.stores_loading = true;
    },
    [readBrandOutlets.fulfilled]: (state) => {
      state.stores_loading = false;
    },
    [readBrandOutlets.rejected]: (state) => {
      state.stores_loading = false;
    },
    [getOfferUnderBudget.pending]: (state) => {
      state.budget_loading = true;
    },
    [getOfferUnderBudget.fulfilled]: (state) => {
      state.budget_loading = false;
    },
    [getOfferUnderBudget.rejected]: (state) => {
      state.budget_loading = false;
    },
    [readStoreCities.pending]: (state) => {
      state.cities_loading = true;
    },
    [readStoreCities.fulfilled]: (state) => {
      state.cities_loading = false;
    },
    [readStoreCities.rejected]: (state) => {
      state.cities_loading = false;
    },
    [stayInTouch.pending]: (state) => {
      state.send_email_loading = true;
    },
    [stayInTouch.fulfilled]: (state) => {
      state.send_email_loading = false;
    },
    [stayInTouch.rejected]: (state) => {
      state.send_email_loading = false;
    },
  },
});
export const {
  setBrands,
  setAnalytics,
  setSponsors,
  setRateBrands,
  setBuyBrands,
  setGuessBrands,
  setQuestions,
  setOpinions,
  filterBrand,
  setOffersInBudget,
  setAllOffers,
  setProductTypes,
  setBrandStores,
  setBrandOutlets,
  setActivities,
  setNotifications,
  setViewedNotifications,
} = webSlice.actions;
export const WebSlice = webSlice.reducer;
