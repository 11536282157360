import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { FileUploader } from "react-drag-drop-files";
import { Puff } from "react-loader-spinner";
import { toast } from "react-toastify";
import { BASEURL } from "../../../services/HttpProvider";
import apiServices from "../../../services/requestHandler";
import { getToken } from "../../../Utils/auth.util";
const fileTypes = ["JPG", "PNG", "GIF"];
export default function ImagePickerModal(props) {
  const [loading, setloading] = useState(false);

  const uploadFile = async (file) => {
    let formdata = new FormData();
    // const authToken = JSON.parse(localStorage.getItem("ewvmtoken"));
    if (file === undefined || file === null) {
      toast.error("Please Insert A File");
      setloading(false);

      return;
    } else {
      const authToken = await getToken();

      setloading(true);
      props.setFileName(file);

      formdata.append("file", file);
      let res = await axios.post(`${BASEURL}users/upload/image`, formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authToken?.type}`,

          // "x-session-type": authToken.type,
        },
      });
      if (res?.data?.response_code === 200) {
        props.setFile(res?.data?.response_data?.url);
        toast.success(res?.data?.response_message);
        setloading(false);
        props?.onHide();
      } else {
        setloading(false);
        toast.error(res?.data?.response_message);
      }
    }
  };

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Select Image
          </Modal.Title>
          <button type="button" className="close" onClick={props?.onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="d-flex justify-content-center p-2">
              <Puff color="#000" height={30} width={60} />
            </div>
          ) : (
            <FileUploader
              handleChange={uploadFile}
              name="file"
              types={fileTypes}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
