import React, { useEffect, useState } from "react";
import "../modal/css/locateStore.css";
import { Modal, Form } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { HiLockClosed } from "react-icons/hi";
import {
  sendOtpMail,
  changeUserPassword,
  resetPassword,
} from "../../../api/slices/authSlice";
import { logout } from "../../../Utils/auth.util";
import { Loader } from "../../common";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

export default function ChangePassword({
  show,
  onHide,
  user,
  loading,
  page,
  setPage,
  handleEmail,
  otpEmail,
  otp,
  handleOtp,
}) {
  const dispatch = useDispatch();

  const schema = yup
    .object({
      newPassword: yup
        .string()
        .required("Please Enter your password")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
      confirmPassword: yup
        .string()
        .required("Please Enter your password")
        .oneOf([yup.ref("newPassword")], "Your passwords do not match.")
        .matches(
          "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$",
          "Must Contain Minimum 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    resetField,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    if (data.newPassword === data.confirmPassword) {
      const apiData = {
        new_password: data?.newPassword,
        email_address: otpEmail,
        token: otp,
      };
      const res = await dispatch(resetPassword(apiData));

      if (res?.payload) {
        onHide();
        setPage(0);
        handleConfirm();
        handleEmail(null);
        handleNew();
      }
    } else {
      toast.error("Password Do Not Match");
    }
  };

  const handleConfirm = () => resetField("confirmPassword");
  const handleNew = () => resetField("newPassword");

  const sendOtpForgetPw = async () => {
    // if (!otpEmail) {
    //   toast.error("Enter Email");
    // } else {
    //   let data = { email_address: otpEmail };
    //   let res = await dispatch(sendOtpMail(data));
    //   if (res?.payload) {
    //     setPage(1);
    //   }
    // }

    let data = { email_address: otpEmail };
    let res = await dispatch(sendOtpMail(data));
    if (res?.payload) {
      setPage(1);
    }
  };

  return (
    <>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ background: "#000", color: "#fff" }}>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-white"
          >
            Forget Password
          </Modal.Title>
          <button
            type="button"
            className="close"
            onClick={() => {
              onHide();
              setPage(0);
              handleConfirm();
              handleNew();
              handleEmail(null);
              handleOtp(null);
            }}
          >
            <span aria-hidden="true" className="text-white  ">
              ×
            </span>
            <span className="sr-only"></span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {page === 0 ? (
            <div className="row p-4 d-flex justify-content-center align-items-center">
              <div className="col-lg-12 col-12">
                <h6 className=" d-flex justify-content-center align-items-center">
                  We Will Send You an One Time Password.
                </h6>
                <h6 className=" d-flex justify-content-center align-items-center">
                  Please Enter Your Email as registered with Brand Emirates.
                </h6>
                <form
                  className="d-flex align-items-center flex-column"
                  action=""
                  onSubmit={() => sendOtpForgetPw()}
                >
                  <input
                    className="d-flex justify-content-center input-login-modal mt-3"
                    type="email"
                    onChange={(e) => handleEmail(e?.target?.value)}
                    placeholder="someone@gmail.com"
                    required
                  />
                  {loading ? (
                    <Loader />
                  ) : (
                    <input
                      type="submit"
                      value="Send"
                      className="btn btn-secondary text-white mt-2"
                    />
                  )}
                </form>
              </div>

              {/* <div className="col-lg-12 d-flex justify-content-center align-items-center">
                {loading ? (
                  <Loader />
                ) : (
                  <button
                    className="btn btn-secondary text-white mt-2"
                    onClick={async () => {
                      if (!otpEmail) {
                        toast.error("Enter Email");
                      } else {
                        let data = { email_address: otpEmail };
                        let res = await dispatch(sendOtpMail(data));
                        if (res?.payload) {
                          setPage(1);
                        }
                      }
                    }}
                  >
                    Send
                  </button>
                )}
              </div> */}
            </div>
          ) : page === 1 ? (
            <div class="container height-100 d-flex justify-content-center align-items-center">
              <div class="position-relative">
                <div class="card-forget p-2 text-center">
                  <h6>
                    Please enter the one time password <br /> to verify your
                    account
                  </h6>
                  <div
                    id="otp"
                    class="inputs d-flex flex-row justify-content-center mt-2"
                  >
                    {" "}
                    <input
                      class="m-2 text-center form-control rounded"
                      type="text"
                      id="first"
                      onChange={(e) => handleOtp(e?.target?.value)}
                    />{" "}
                  </div>
                  <div class="mt-4">
                    {" "}
                    <button
                      class="btn btn-danger px-4 validate"
                      onClick={() => {
                        if (!otp) {
                          toast.error("Enter OTP Please");
                        } else {
                          setPage(2);
                        }
                      }}
                    >
                      Next
                    </button>{" "}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row p-4 d-flex justify-content-center align-items-center">
                <div className="col-lg-8 col-12">
                  <span className="label-name-login d-flex align-items-center">
                    Enter Your New Password
                  </span>
                  <input
                    className="input-login-modal"
                    type="password"
                    {...register("newPassword")}
                  />
                  {errors?.newPassword?.message ? (
                    <div className="text-error">
                      {errors?.newPassword?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-8 col-12 mt-3">
                  <span className="label-name-login">
                    Confirm Your New Password
                  </span>
                  <input
                    className="input-login-modal"
                    type="password"
                    {...register("confirmPassword")}
                  />
                  {errors?.confirmPassword?.message ? (
                    <div className="text-error">
                      {errors?.confirmPassword?.message}
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="col-lg-12 d-flex justify-content-center align-items-center">
                  {loading ? (
                    <Loader />
                  ) : (
                    <input
                      type="submit"
                      label="Save"
                      className="btn btn-secondary text-white mt-4"
                      style={{ width: "50%", textAlign: "center" }}
                    />
                  )}
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
