import React from "react";
import { Puff } from "react-loader-spinner";

const Loader = ({ height, width, color }) => {
  return (
    <div className="d-flex justify-content-center p-2">
      <Puff
        color={color ? color : "#000"}
        height={height ? height : 30}
        width={width ? width : 60}
      />
    </div>
  );
};
export default Loader;
