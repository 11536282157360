import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readBrands } from "../api/slices/webSlice";
import { Loader } from "../components/common";

const BrandOffers = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filteredBrand, setFilteredBrand] = useState([]);
  const { t } = useTranslation("common");
  const { brands, loading } = useSelector((state) => state.WebSlice);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(readBrands());
  }, []);

  useEffect(() => {
    if (location?.state) {
      let brand = brands?.filter(
        (item) => item?.gender?.name === location?.state
      );
      setFilteredBrand(brand);
    } else {
      setFilteredBrand(brands);
    }
  }, [location, brands]);

  return (
    <>
      <Helmet>
        <title>{t("brandoffer.maintitle")}</title>
      </Helmet>
      <div
        className="container-fluid p-lg-4 brand-offer-details-page"
        style={{ backgroundColor: "#F4F5FA" }}
      >
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 text-center our-brands-heading">
              <span>
                OUR
                <span> BRANDS</span>
              </span>
            </div>
          </div>
          <div
            className={`row ${
              loading && "d-flex justify-content-center"
            } mt-4 mx-lg-5 ml-2`}
          >
            {loading ? (
              <Loader width={100} height={100} />
            ) : (
              filteredBrand?.map((item, idx) => (
                <div
                  key={idx}
                  className="col-lg-2 col-5 mb-2  brand-offers-div "
                >
                  {/* <div
                    className="back-color"
                    onClick={() => {
                      navigate("/brand-details", { state: item });
                    }}
                  > */}
                  <img
                    className="brand-offers-image"
                    src={item?.display_picture}
                    onClick={() => {
                      navigate("/brand-details", { state: item });
                    }}
                  />
                  <div className="shadow"></div>
                  {/* </div> */}
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BrandOffers;
