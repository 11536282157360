import count1 from "../statics/count_1.png";
import count2 from "../statics/count_2.png";
import count3 from "../statics/count_3.png";
import count4 from "../statics/count_4.png";
import game1 from "../statics/5-games-3.png";
import game2 from "../statics/rate&win.png";
import game3 from "../statics/buy_and_win.png";
export const getCountsArray = (analytics) => {
  if (analytics) {
    return [
      {
        id: 1,
        count: analytics.registered_brands,
        img: count1,
        text: "Registered Brands",
      },
      {
        id: 2,
        count: analytics.offers,
        img: count2,
        text: "Offers",
      },
      {
        id: 3,
        count: analytics.brands_freaks,
        img: count3,
        text: "Brand Freaks",
      },
      {
        id: 4,
        count: analytics.winners,
        img: count4,
        text: "Prize Winners",
      },
    ];
  }
};

export const gameData = [
  {
    image: game1,
    name: "Guess The Correct Answer",
    desc: "Guess the correct answer and enroll yourself for exicting prizes",
    button: "Guess",
  },
  {
    image: game2,
    name: "Rate And Win",
    desc: "Rate your favourite products and enroll yourself for exicting prizes",
    button: "Rate",
  },
  {
    image: game3,
    name: "Buy And Win",
    desc: "Buy any product and enroll yourself to win exciting prizes from your fav brand.",
    button: "Enroll",
  },
];

export const sortElements = [
  {
    id: 1,
    value: "LATEST",
  },
  {
    id: 2,
    value: "ALPHABETICALLY, A-Z",
  },
  {
    id: 3,
    value: "ALPHABETICALLY, Z-A",
  },
  {
    id: 4,
    value: "PRICE, HIGHEST TO LOWEST",
  },
  {
    id: 5,
    value: "PRICE, LOWEST TO HIGHEST",
  },
];
export const genderArray = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
  {
    id: 3,
    name: "Others",
  },
];
export const sizeArray = [
  {
    id: 1,
    name: "S",
  },
  {
    id: 2,
    name: "M",
  },
  {
    id: 3,
    name: "L",
  },
  {
    id: 4,
    name: "XL",
  },
  {
    id: 5,
    name: "XXL",
  },
];
export const productArray = [
  {
    id: 1,
    name: "Shoe",
  },
  {
    id: 2,
    name: "Shirt",
  },
  {
    id: 3,
    name: "Jacket",
  },
  {
    id: 4,
    name: "Pant",
  },
  {
    id: 5,
    name: "Beauty",
  },
  {
    id: 6,
    name: "Watches",
  },
  {
    id: 7,
    name: "Jewellary",
  },
];

export const googleClientId =
  "700027813317-8os3289q0ek29liue9tq03kao0q4skob.apps.googleusercontent.com";

export const facebookAppId = "309097054509796";

export const passwordRegex =
  "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+-]).{8,50}$";

export const notificationKey =
  "BEAKOXnrMFCPOU8guVgEulYs20LvMnfx5_cVooV86X0KZHWdSjmFM89URKj3X2cB4uGfnP_ltW9v67ZQe4NYQac";

export const inquiryTypeArray = [
  { value: "Sales", Label: "Sales" },
  { value: "Suggestions", Label: "Suggestions" },
  { value: "Complain", Label: "Complain" },
  { value: "Other", Label: "Other" },
];
